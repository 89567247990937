import React  from 'react';
import Header from '../DefaultInterface/Header/ui';
import './ui.css'
import { addElementListMenu, performJqueryHome, program } from './ui_fc';
import { ResetTokenLoadPage } from '../DefaultInterface/Header/ui_fc';
export default class Home extends React.Component {
    componentWillMount()
    {
        ResetTokenLoadPage();
    }
    componentDidMount()
    {
       program(0);
       addElementListMenu();
       performJqueryHome();
    }
    render() {
        return (
          <div className="h-100">
                <Header action="home"/>
                <div className="div-content">
                    <div className="div-content-menu f-l">
                        <div id="wrapper">
                            <div id="sidebar-wrapper">
                                <div className="div-menu-title">
                                </div>
                                <div className="div-menu-content">
                                </div>
                            </div>
                            <div id="page-content-wrapper">
                                <div className="menu-toggle t-c"><i className="i-arrow"></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="div-content-function f-l">
                    </div>
                </div>
                <div className="div-notification w-100 h-100">
                    <div>
                        <div className="w-100 h-18 color-w">
                            <div className="h-100">
                                <i className="i-notification"></i><span className="sp-title-notification c-w"/>
                            </div>
                        </div>
                        <div className="w-100 div-notification-content t-c" />
                        <div className="w-100 div-footer-notification" />
                    </div>
                </div>
                <div className="div-loading" style={{display:"none"}}>
                    <div className="spinner" />
                </div>
                {/*<Footer />*/}
          </div>
        )
    }
}