import $ from 'jquery'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { formatDateTimeName } from '../../Function/ui';

 function performShare()
 {
   $(".container").css("overflow","auto");
   $('.div-down-pdf').click(function () {
      CreatePDFfromHTML();
   });
 }
 function CreatePDFfromHTML() {
   var HTML_Width = $(".container").width();
   var HTML_Height = $(".container").height();
   var top_left_margin = 15;
   var PDF_Width = HTML_Width + (top_left_margin * 2);
   var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
   var canvas_image_width = HTML_Width;
   var canvas_image_height = HTML_Height;
   var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

   html2canvas($(".container")[0]).then(function (canvas) {
       var imgData = canvas.toDataURL("image/jpeg", 1.0);
       var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
       pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
       for (var i = 1; i <= totalPDFPages; i++) { 
           pdf.addPage("1300", "1500");
           pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin*4),canvas_image_width,canvas_image_height);
       }
       var name = "BA"+formatDateTimeName(new Date());
       pdf.save(name+".pdf");
       //$(".html-content").hide();
   });
}
 export {performShare};