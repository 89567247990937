import $ from 'jquery';
import './ui.css';
import { SendData, formatDate, formatDate2, safeRead, formatDateTime, calculateAge, SetChecked, getManagementAgency, ToggleSelectFilter3, SendDataAwait, checkErrorStatus, ShowBirthday } from '../../../Function/ui';
import { _height_window } from '../../../Variable/defaultvar';
import { addElementDetailPatientRecords, performJqueryXNCLS, getDataPatientID, getDataPatientResourceID, performPatientRecords, setValElementDetailPatientRecords, convertVN } from '../DetailPatientRecords/ui/ui_fc';
import { addELementPagination, turnoffSetIntervalUploadFileXML } from '../../UploadFileXML/ui_fc';
import config from '../../../../config';
import Cookies from 'universal-cookie';
import { notification } from '../../Notification/ui_fc';
import { logout } from '../../DefaultInterface/Header/ui_fc';
var _cookies = new Cookies();
//-------Variable---------//
var _dt_managementAgency,
    _count_BenhAn_Today = 0,
    _count_BenhAn_Month = 0,
    _count_pageBA =
    {
        Done: 0,
        Watting: 0
    },
    _pg_index =
    {
        first: 1,
        last: 5
    },
    _searchMA = null,
    _countFilter = 0,
    resetStatus,
    _data_BenhAn,
    _filterBenhAn =
    {
        Status: config.status.status,
        CMND: null,
        HoTen: null,
        MaBHYT: null,
        TuNgay: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 0, 0, 0),
        //TuNgay       : new Date(),
        DenNgay: new Date(),
        LoaiKCB: null,
        HospitalId: null,
        PageNumber: 1,
        RowsPerPage: 25,
    },
    _filterMA = {};
_filterMA.ID = null;
_filterMA.Find = null;
_filterMA.RowsPerPage = 10;
_filterMA.PageNumber = 1;
//-------Variable---------//
var performUploadXML = function () {
    $(".div-content").unbind().on("click", function () {
        if (!$(".div-ft-ex-content").hasClass("h")) $(".div-ft-ex-content").addClass("h")
    })
    $(".div-filter-content").unbind().on("click", function (e) {
        e.stopPropagation();
    })
    $(".ip-name:text:visible:first").focus();
    $(".div-filter-find").unbind().on("click", function () {
        if (getKeyFilter() == "") {
            resetFilter();
            return;
        }
        resetPagination();
        filterUploadXML(true)
    });
    $(".div-filter-reset").unbind().on("click", function () {
        resetFilter();
    });
    $(".div-filter-extend").unbind().on("click", function () {
        var child = $(this).children();
        var down = "fa-chevron-down";
        var up = "fa-chevron-up"
        if (child.hasClass(down)) child.removeClass(down).addClass(up);
        else child.removeClass(up).addClass(down);
        $(".div-ft-ex-content").toggleClass("h")
    })
    $(".div-tb-pt-rs").scroll(function () {
        $(".div-s-h-at").addClass("h");
    });
    $(".div-gridview").css("margin-top", "0px")
    $(".ip-cmnd").unbind().on("keyup", function (e) {
        resetPagination();
        _filterBenhAn.CMND = null;
        _filterBenhAn.MaBHYT = null;
        /*if(isNumber($(this).val())) 
        {
            if(getKeyFilter() == "")
            {
                resetFilter();
                return;
            }
            filterUploadXML(true)
        }
        else if(e.keyCode == 13) 
        {
            if(getKeyFilter() == "")
            {
                resetFilter();
                return;
            }
            filterUploadXML(true);
        }*/
        if (e.keyCode == 13) {
            resetPagination();
            filterUploadXML(true);
        }
    });
    $(".a-pagination").unbind().on("click", clickPagination);
    $(".ip-name").unbind().on("keyup", function (e) {
        if (e.keyCode == 13) {
            if (getKeyFilter() == "") {
                resetFilter();
                return;
            }
            resetPagination();
            filterUploadXML(true);
        }
    })
    $(".ip-formdate , .ip-todate , .sl-htkcb").change(function () {
        if (getKeyFilter() == "") {
            resetFilter();
            return;
        }
        resetPagination();
        filterUploadXML(true);
    });
    $(".sp-ba-today").text(safeRead(_count_BenhAn_Today, "Total") == "" ? 0 : safeRead(_count_BenhAn_Today, "Total"));
    $(".sp-ba-month").text(safeRead(_count_BenhAn_Month[0], "Total") == "" ? 0 : safeRead(_count_BenhAn_Month[0], "Total"));
    $(".ip-status-ba").unbind().on("change", function () {
        var text = "Đã thực hiện";
        _filterBenhAn.Status = config.status.status;
        if (!$(this).is(':checked')) {
            text = "Chờ thực hiện";
            _filterBenhAn.Status = [3];
        }
        $(".sp-status-ba").text(text);
        resetPagination();
        //resetFilterUploadXML();
        filterUploadXML(true);
    })
    $(".div-tb-pt-rs").height($(".div-content-function").height() - ($(".div-ul-xml-title").height() + $(".div-filter-find").height() + $(".c-header-tb").height() + $(".div-pagination").height() + 37))
    $(".div-lst-ma").css("max-height", _height_window / 3)
    $(".div-s-h-m-a").width($(".ip-name").width() + 3);
    $(".ip-cskcb").height($(".ip-name").height() + 3);
    //$(".sp-filter-key-content").text(getKeyFilter());
    $(".div-content").unbind('mouseup').mouseup(function (e) {
        ToggleSelectFilter3("ip-cskcb , .div-s-h-m-a , .ip-search-m-a , .div-lst-ma , .sp-cskcb", "div-s-h-m-a", e);
    });
    turnoffSetIntervalUploadFileXML();
    reloadDataXML();
    clearIntervalUploadXML();
    _countFilter = _count_pageBA.Done;
    if (_filterBenhAn.Status.includes(3)) _countFilter = _count_pageBA.Watting;
    /*actionPagination(Math.round(_countFilter/25));
    checkStatusPagination(Math.round(_countFilter/25));
    $(".sp-filter-key-count").text(_count_pageBA.Done);
    if(_filterBenhAn.PageNumber > 1) $(".div-pg-per").removeClass("div-dis");
    /*resetStatus = setInterval(function(){
        reloadDataXML()
      },10000)*/
}
function performJqueryHomeAfterLoading() {
    if (_filterBenhAn.HospitalId == null || _filterBenhAn.HospitalId == "")
        $(".sp-cskcb").text(_dt_managementAgency[0].HospitalName).attr("name", _dt_managementAgency[0].HospitalId);
    else {
        try {
            $(".sp-cskcb").text(_dt_managementAgency[0].HospitalName).attr("name", _dt_managementAgency[0].HospitalId);
        } catch (ex) { }
    }
    $(".div-lst-ma").scroll(function () {
        var scrollHeight = $(".div-lst-ma").prop("scrollHeight");
        if ($(".div-lst-ma").scrollTop() == Math.round(scrollHeight - ($(".div-lst-ma").height()))) {
            fetchMoreDataMA();
            addElementManagementAgency(getManagementAgency(_filterMA), false);
            performSearchCSKCB();
        }
    });
    $(".ip-search-m-a").unbind().on("keyup", function (e) {
        /*if(e.keyCode == 13) 
        {
            _filterMA.Find = $(this).val();
            _filterMA.PageNumber = 1;
            $(".div-lst-ma").empty();
            addElementManagementAgency(getManagementAgency(_filterMA),true);
            performSearchCSKCB();
        }*/
        clearTimeout(_searchMA);
        // Make a new timeout set to go off in 1000ms (1 second)
        _searchMA = setTimeout(function () {
            _filterMA.Find = $(".ip-search-m-a").val();
            _filterMA.PageNumber = 1;
            $(".div-lst-ma").empty();
            addElementManagementAgency(getManagementAgency(_filterMA), true);
            performSearchCSKCB();
        }, 800);
    })
    $(".div-ba-detail").unbind().on("click", function () {
        setKeepStatus($(this).attr("name"));
        detailPatientRecords($(this).attr("name"))
    })
    $(".tr-active").bind("click", function () {
        setKeepStatus($(this).attr("name"));
    })
    $(".tr-active").bind("dblclick", function () {
        if (!$(this).hasClass("n-c")) {
            setKeepStatus($(this).attr("name"));
            detailPatientRecords($(this).attr("name"))
        }
    })
    performSearchCSKCB();
    getKeyFilter();
}
function performSearchCSKCB() {
    $(".div-lst-a-m-child").unbind().on("click", function () {
        var val = $(this).text();
        var name = $(this).attr("name");
        $(".sp-cskcb").attr("name", name).text(val);
        if (getKeyFilter() == "") {
            resetFilter();
            return;
        }
        resetPagination();
        filterUploadXML(true);
    })
}
function isNumber(n) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }
function resetFilter() {
    resetFilterGridView();
    resetFilterUploadXML();
    filterUploadXML(true);
}
function actionPagination(count) {
    addELementListPagination(count);
    $('.a-next-pg').unbind().on("click", function () {
        _pg_index.first += 5;
        _pg_index.last += 5;
        addELementListPagination(count);
        $('.a-prev-pg').removeClass("div-dis");
        checkPrevPg(count);
        $(".a-prev-first").removeClass("div-dis");
        checkPrevFirst(count);
        if (_pg_index.last >= count) {
            $('.a-next-pg , .a-next-last').addClass("div-dis").unbind();
            return;
        }
    })
    if (_pg_index.last > 5) checkPrevPg(count);
    checkNextLast(count);
    checkPrevFirst(count);
}
function checkPrevPg(count) {
    $('.a-prev-pg').unbind().on("click", function () {
        _pg_index.first -= 5;
        _pg_index.last -= 5;
        if (_pg_index.first < 1) {
            _pg_index.first = 1;
            _pg_index.last = 5;
        }
        addELementListPagination(count);
        if (_pg_index.first <= 1) {
            $('.a-prev-pg , .a-prev-first').addClass("div-dis").unbind();
        }
        if (_pg_index.last > 5 || count > 5) {
            $('.a-next-pg , .a-next-last').removeClass("div-dis");
            actionPagination(count);
        }
        localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
    })
}
function checkPrevFirst(count) {
    $(".a-prev-first").unbind().on("click", function () {
        if (count >= 5) {
            _pg_index.first = 1;
            _pg_index.last = 5;
            $('.a-next-pg , .a-next-last').removeClass("div-dis");
            checkNextLast();
        }
        else {
            _pg_index.first = 1;
            _pg_index.last = count;
        }
        addELementListPagination(count);
        actionPagination(count);
        $('.a-prev-pg , .a-prev-first').addClass("div-dis").unbind();
        localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
    })
}
function checkNextLast(count) {
    $(".a-next-last").unbind().on("click", function () {
        if (count > 5) {
            _pg_index.first = count - 4;
            _pg_index.last = count;
            $('.a-prev-pg , .a-prev-first').removeClass("div-dis");
            checkPrevPg(count);
            checkPrevFirst(count);
        }
        else {
            _pg_index.first = 1;
            _pg_index.last = count;
            $('.a-prev-pg').addClass("div-dis").unbind();
        }
        addELementListPagination(count);
        $('.a-next-pg , .a-next-last').addClass("div-dis").unbind();
        localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
    })
}
function clickPagination() {
    $(".a-pagination").removeClass("active");
    $(this).addClass("active");
    _filterBenhAn.PageNumber = Number($(this).attr("name"));
    filterUploadXML(false);
}
function checkStatusPagination(count) {
    if (_pg_index.first <= 1) $('.a-prev-pg , .a-prev-first').addClass("div-dis").unbind();
    if ((count <= 5) || (_pg_index.last >= count))
        $('.a-next-pg , .a-next-last').addClass("div-dis").unbind();
    else {
        $('.a-next-pg , .a-next-last').removeClass("div-dis");
        //performUploadXML();
    }

}
function setKeepStatus(id) {
    $(".tr-active").removeClass("div-active");
    $(".tr-active-" + id).addClass("div-active");
    localStorage.setItem("trActive", id);
}
function clearIntervalUploadXML() {
    clearInterval(resetStatus);
}
function reloadDataXML() {
    //$("#tb-data").empty();
    //resetFilterUploadXML();
    //getDataUploadXML();
    //gridViewDataUploadXML(_data_BenhAn);
    var clsDis = document.getElementsByClassName('div-dis-active');
    if (clsDis.length == 0) clearIntervalUploadXML();
    for (var i = 0; i < clsDis.length; i++) {
        undisDataXML(clsDis[i].getAttribute("name"))
    }
}
function addFilterBA() {
    var name;
    (_filterBenhAn.HoTen == "" || _filterBenhAn.HoTen == null) ? name = _filterBenhAn.MaBHYT : name = _filterBenhAn.HoTen;
    $(".ip-name").val(name);
    $(".ip-cmnd").val(_filterBenhAn.CMND);
    $(".ip-formdate").val(formatDate2(_filterBenhAn.TuNgay));
    $(".ip-todate").val(formatDate2(_filterBenhAn.DenNgay));
    $(".sl-htkcb").val(_filterBenhAn.LoaiKCB);
    $(".sp-cskcb").val(_filterBenhAn.HospitalId);
    $(".sp-pg").text(_filterBenhAn.PageNumber);
    $(".tr-active-" + localStorage.getItem("trActive")).addClass("div-active");
    if (_filterBenhAn.Status.includes(3)) {
        SetChecked(".ip-status-ba", "", false);
        $(".sp-status-ba").text("Chờ thực hiện");
    }
}
function filterUploadXML(params) {
    $('.div-loading').show();
    $("#tb-data").empty();
    var denNgay = $(".ip-todate").val() == "" ? "" : $(".ip-todate").val() + " 23:59:59";
    _filterBenhAn.CMND = null;
    _filterBenhAn.MaBHYT = null;
    if (isNumber($(".ip-name").val())) _filterBenhAn.CMND = $(".ip-cmnd").val();
    else _filterBenhAn.MaBHYT = $(".ip-cmnd").val()
    _filterBenhAn.HoTen = $(".ip-name").val();
    _filterBenhAn.TuNgay = $(".ip-formdate").val() + " 00:00:00";
    _filterBenhAn.LoaiKCB = $(".sl-htkcb").val();
    _filterBenhAn.HospitalId = $(".sp-cskcb").attr("name");
    _filterBenhAn.DenNgay = denNgay;
    getKeyFilter();
    try {
        $.ajax({
            url: config.host.fhir_api + "api/BenhAn",
            type: "get",
            data: _filterBenhAn,
            headers: {
                'Authorization': 'bearer ' + _cookies.get('access_Token'),
                "Accept": "application/json",
                "content-type": "application/json; charset=utf-8"
            },
            traditional: true,
            dataType: 'json',
            timeout: 5000,
            success: function (data) {
                if (params) {
                    if (_filterBenhAn.LoaiKCB == "" && _filterBenhAn.DenNgay == "") {
                        _countFilter = 0
                        var result = SendData("api/Report/EMRCountHospital/Summary", "get", { hospitalId: _filterBenhAn.HospitalId });
                        if (safeRead(result, "WaitingCount") != "" && _filterBenhAn.Status.includes(3)) _countFilter = result.WaitingCount
                        if (safeRead(result, "DoneCount") != "" && _filterBenhAn.Status.includes(3) == false) _countFilter = result.DoneCount;
                    }
                    else {
                        _countFilter = data.TotalCount;
                        getCountFilter(_countFilter);
                    }
                }
                gridViewDataUploadXML(data.Items);
                if (_countFilter == 0) {
                    actionPagination(0);
                    checkStatusPagination(0);
                }
                else {
                    actionPagination(Math.round(_countFilter / 25));
                    checkStatusPagination(Math.round(_countFilter / 25));
                }
                $(".a-pagination").unbind().on("click", clickPagination);
                $(".tr-active-" + localStorage.getItem("trActive")).addClass("div-active");
                $(".sp-filter-key-count").text(_countFilter);
                if (_countFilter >= config.data.limit_MedicalRecord)
                    $(".sp-alert-filter").removeClass("h");
                else if (_countFilter < config.data.limit_MedicalRecord && $(".sp-alert-filter").hasClass("h") == false)
                    $(".sp-alert-filter").addClass("h");
                $(".div-ba-detail").unbind().on("click", function () {
                    setKeepStatus($(this).attr("name"));
                    detailPatientRecords($(this).attr("name"))
                })
            }.bind(this),
            complete: function () {
                $('.div-loading').hide();
            },
            timeout: 5000,
            error: function (request, error) {
                if (request.status == "401")
                    notification.notifactionF2("Vui lòng đăng nhập lại .", logout)
            }
        });
    }
    catch (ex) { }
}
function getCountFilter(count) {
    $('.div-loading').show();
    $("#tb-data").empty();
    addELementListPagination(Math.round(count / 25));
    //performUploadXML();
    actionPagination(Math.round(count / 25));
    checkStatusPagination(Math.round(count / 25));
    $(".a-pagination").unbind().on("click", clickPagination);
    $(".sp-filter-key-count").text(count);
    localStorage.setItem("countFilter", JSON.stringify(count));
}
function getKeyFilter() {
    var text = "";
    var arr = ["sp-cskcb", "ip-name", "sl-htkcb", "ip-cmnd", "ip-formdate", "ip-todate"];
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] == "sp-cskcb" && $("." + arr[i]).text() != "" && $("." + arr[i]).text() != undefined)
            text = text + "[" + $("." + arr[i]).text() + "]";
        else if ($("." + arr[i]).val() != "" && $("." + arr[i]).val() != undefined) {
            var x;
            if (arr[i] == "ip-formdate")
                x = "[Từ " + $("." + arr[i]).val() + "]";
            else if (arr[i] == "ip-todate")
                x = "[Đến " + $("." + arr[i]).val() + "]";
            else x = "[" + $("." + arr[i]).val() + "]";
            text = text + x;
        }
    }
    $(".div-filter-key").show();
    $(".sp-filter-key-content").text(text);
    /*if(text !="")
    {
        $(".div-filter-key").show();
        $(".sp-filter-key-content").text(text);
    }
    else
    {
        $(".div-filter-key").hide();
    }*/
    return text;
}
function checkFilterBA() {
    if ("filterBA" in localStorage)
        _filterBenhAn = JSON.parse(localStorage.getItem("filterBA"));
    if ("pgIndex" in localStorage)
        _pg_index = JSON.parse(localStorage.getItem("pgIndex"));
    if ("countFilter" in localStorage)
        _countFilter = JSON.parse(localStorage.getItem("countFilter"));
    else _countFilter = 0;
    _filterMA.PageNumber = 1;
}
function undisDataXML(id) {
    try {
        var data = _data_BenhAn.filter(x => x._id == id)[0];
        if (safeRead(data.Status, "ID") == 4)
            addElementDisXML(id, data);
        else $(".div-dis-name" + data._id).text(data.Status.Name).css("background-color", data.Status.Color)
    }
    catch (ex) { }
}
function addElementDisXML(id, data) {
    $(".div-dis-st" + id).removeClass("div-dis div-dis-active").addClass("div-ba-detail");
    $(".div-dis-name" + id).text(data.Status.Name).css("background-color", data.Status.Color);
    $(".div-dis-name" + id).remove(".load-wrapp");
    $(".tr-active-" + id).removeClass("n-c");
}
function addELementListPagination(count) {
    var index = Number(count);
    $(".div-lst-pagination").empty();
    $(".div-pagination").show();
    if (count < 2) {
        $(".div-pagination").hide();
        return;
    }
    if (index > _pg_index.last) {
        index = _pg_index.last;
    }
    if (_pg_index.first == 0) {
        _pg_index.first = 1;
        index = 5;
    }
    for (var i = _pg_index.first; i <= index; i++) {
        var parent = $('<a class="a-pagination" href="#" name="' + i + '">' + i + '</a>');
        parent.appendTo($(".div-lst-pagination"));
    }
    $('.div-lst-pagination a[name=' + _filterBenhAn.PageNumber + ']').addClass("active");
    $(".a-pagination").unbind().on("click", clickPagination);
}
function resetFilterUploadXML() {
    _pg_index.first = 1;
    _pg_index.last = 5;
    _filterBenhAn.CMND = null;
    _filterBenhAn.HoTen = null;
    _filterBenhAn.MaBHYT = null;
    _filterBenhAn.TuNgay = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    _filterBenhAn.DenNgay = new Date();
    _filterBenhAn.LoaiKCB = null;
    _filterBenhAn.HospitalId = null;
    _filterBenhAn.Status = config.status.status;
    _filterBenhAn.PageNumber = 1;
    _filterBenhAn.RowsPerPage = 25;
    _countFilter = 0;
    $(".sp-pg").text(_filterBenhAn.PageNumber);
    localStorage.setItem("filterBA", JSON.stringify(_filterBenhAn));
    localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
    localStorage.setItem("countFilter", JSON.stringify(_countFilter));
    localStorage.setItem("trActive", null);
    $(".tr-active").removeClass("div-active");
}
function resetPagination() {
    _pg_index.first = 1;
    _pg_index.last = 5;
    _filterBenhAn.PageNumber = 1;
    _filterBenhAn.RowsPerPage = 25;
}
function fetchMoreData() {
    _filterBenhAn.PageNumber += 1;
}
function fetchMoreDataMA() {
    _filterMA.PageNumber += 1;
}
async function getDataUploadXML() {
    //_data_BenhAn = SendData("api/BenhAn","get",_filterBenhAn);
    $('.div-loading').show();
    try {
        var data = await SendDataAwait("api/BenhAn", "Get", _filterBenhAn);
        if (data == undefined) data = [];
        _data_BenhAn = safeRead(data, "Items");
        _countFilter = data.TotalCount;
        getCountFilter(_countFilter);
        actionPagination(Math.round(_countFilter / 25));
        checkStatusPagination(Math.round(_countFilter / 25));
        $(".a-pagination").unbind().on("click", clickPagination);
        $(".tr-active-" + localStorage.getItem("trActive")).addClass("div-active");
        $(".sp-filter-key-count").text(_countFilter);
        $(".div-ba-detail").unbind().on("click", function () {
            setKeepStatus($(this).attr("name"));
            detailPatientRecords($(this).attr("name"))
        })
        gridViewDataUploadXML(_data_BenhAn);
        addFilterBA();

        $('.div-loading').hide();
    }
    catch (e) {
        checkErrorStatus(e);
        $('.div-loading').hide();
    }
    $(".div-lst-ma").empty();
    addElementManagementAgency(_dt_managementAgency, true);
    performJqueryHomeAfterLoading();
}
function getDataERMCountHospital() {
    var year = new Date().getFullYear();
    var month = new Date().getMonth() + 1;
    _dt_managementAgency = getManagementAgency(_filterMA);
    if (_filterBenhAn.HospitalId == null || _filterBenhAn.HospitalId == "") _filterBenhAn.HospitalId = _dt_managementAgency[0].HospitalId;
    _count_BenhAn_Today = SendData("api/Report/EMRCountHospital/SummaryRecevedByDay/" + formatDate2(new Date()), "get", null);
    _count_BenhAn_Month = SendData("api/Report/EMRCountHospital/SummaryRecevedByMonth/" + month + "/" + month + "/" + year, "get", null);
}
function addElementUploadXML() {
    addElementUploadXMLTitle();
    addElemmentprView();
    addElementFilter();
    addElementGridView();
    addELementPagination();
}
function addElementManagementAgency(data, action) {
    for (var i = 0; i < data.length; i++) {
        var parent = $('<div class="text-ellipsis-n-w c-p div-lst-a-m-child w-100" name="' + data[i].HospitalId + '">' + data[i].HospitalName + '</div>')
        $(".div-lst-ma").append(parent);
    }
}
function addElementUploadXMLTitle() {
    var parent = $('<div class="w-199 div-ul-xml-title"/>');
    var child_1 = $('<div class="w-75 f-l"><p>Danh sách bệnh án</p><div class="div-filter-key"><span class="sp-filter-key">Tìm thấy </span><span class="sp-filter-key-count"></span><span> bệnh án theo khóa tìm kiếm : </span><span class="sp-filter-key-content"/></div><div><span class="sp-alert-filter h" style="color:#DA4242 ">[*Kết quả có thể nhiều hơn, chỉ hiển thị ' + config.data.limit_MedicalRecord + ' bệnh án để đảm bảo hiệu xuất.]</span></div></div>');
    var child_2 = $('<div class="w-25 f-l"><div class="w-100"><p>Bệnh án đã nhận</p></div><div class="w-100 f-l"><div class="f-l" style="width:85px"><span>Trong ngày : </span></div><div class="f-l"><span class="sp-ba-today">0</span></div></div><div class="w-100 f-l"><div class="f-l" style="width:85px"><span>Trong tháng : </span></div><div class="f-l"><span class="sp-ba-month">0</span></div></div><div class="f-l" style="padding:4px 0 0 0"><label class="switch"><input class="ip-status-ba" type="checkbox" checked/><span class="slider round"></span></label></div><div class="f-l" style="padding:4px 0 0 0"><span class="sp-status-ba">Đã thực hiện</span></div></div>');
    parent.append([child_1, child_2]).appendTo($(".div-content-function"));
}
function addElemmentprView() {
    var parent = $('<div />');
    var child_3 = $('<div class="div-gridview"/>');
    parent.append([child_3]).appendTo($(".div-content-function"));
}
function addElementFilter() {
    //active
    var parent = $('<div class="div-gettested-content"/>');
    var child_2 = $('<div class="div-filter-content" />');
    var child_2_child_0 = _dt_managementAgency.length > 1 ? $('<div class="w-15 f-l"><label>Cơ sở KCB</label><div class="ip-cskcb w-100 s-p"><span class="sp-cskcb text-ellipsis-n-w w-100"></span><i class="far fa-angle-down f-r" style="color:black;margin-top:-19px"></i></div><div class="div-s-h-m-a h"><div><input class="ip-search-m-a w-100" placeholder="Tìm kiếm"/></div><div class="div-lst-ma" /></div></div>') : null;
    var child_2_child_1 = $('<div class="' + (_dt_managementAgency.length > 1 ? "w-15" : "w-18") + ' f-l"><label>Bệnh nhân</label><input class="ip-name w-100 h-100" type="text" /></div>');
    var child_2_child_2 = $('<div class="' + (_dt_managementAgency.length > 1 ? "w-15" : "w-18") + ' f-l"><label>CMND | BHYT</label><input class="ip-cmnd w-100 h-100" type="text" /></div>');
    var child_2_child_3 = $('<div class="' + (_dt_managementAgency.length > 1 ? "w-15" : "w-18") + ' f-l"><label>Loại KCB</label><select class="sl-htkcb w-100 h-100"><option></option><option value="KhamBenh">Khám bệnh</option><option value="DieuTriNoiTru">Điều trị nội trú</option><option value="DieuTriNgoaiTru">Điều trị ngoại trú</option></select></div>');
    var child_2_child_4 = $('<div class="' + (_dt_managementAgency.length > 1 ? "w-30" : "w-36") + ' f-l"><label>Thời gian khám chữa bệnh</label><div class="w-100"><div class="w-50 f-l p-d-l-r-0-4"><input class="w-100 ip-formdate" type="date" /></div><div class="w-50 f-l p-d-l-r-0-4"><input class="w-100 ip-todate" type="date" /></div></div></div>');
    var child_2_child_5 = $('<div class="w-10 f-l div-filter-fs" style="padding:0"><div class="w-50 f-l t-c div-filter-find c-p"><i class="far fa-search"></i></div><div class="w-50 f-l t-c c-p div-filter-reset"><i class="far fa-undo"></i></div></div>')
    child_2.append([
        child_2_child_0,
        child_2_child_1,
        child_2_child_2,
        child_2_child_3,
        child_2_child_4,
        child_2_child_5
    ]);
    parent.append([child_2]).appendTo($(".div-gridview"))
}
function addElementGridView() {
    var parent = $('<div class="div-fhir-gridview"/>');
    var child = $('<table class="w-100" />');
    var child_child = $('<tr class="c-w c-header-tb"/>');
    var child_child_2 = $('<tr />');
    var child_child_child = $('<td />');
    var child_child_2_child = $('<td />');
    var child_child_child_child = $('<table class="w-100" />');
    var child_child_2_child_child = $('<div class="w-100 div-tb-pt-rs"/>');
    var child_child_child_child_child = $('<tr />');
    var child_child_2_child_child_child = $('<table id="tb-data" class="w-100 tb-dp-data"/>');
    var child_child_child_child_child_child_2 = $('<th class="w-15">Bệnh nhân</th>');
    var child_child_child_child_child_child_3 = $('<th class="w-29 t-c">Thời gian khám chữa bệnh</th>');
    var child_child_child_child_child_child_4 = $('<th class="w-15 t-c">Gửi bệnh án</th>');
    var child_child_child_child_child_child_5 = $('<th class="w-13 t-c">KCB</th>');
    var child_child_child_child_child_child_6 = $('<th class="w-12 t-c">Số ngày ĐT</th>');
    var child_child_child_child_child_child_7 = $('<th class="w-12 t-c">Kết quả ĐT</th>');
    var child_child_child_child_child_child_8 = $('<th class="w-4 "></th>');
    for (var i = 0; i < 15; i++) {
        var a = $('<tr class="w-100 " style="display:flex; height: 50px"></tr>');
        var a_1 = $('<td class="w-15 aaa"><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div></td>');
        var a_2 = $('<td class="w-29 aaa"><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div></td>');
        var a_3 = $('<td class="w-15 aaa"><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div></td>');
        var a_4 = $('<td class="w-13 aaa"><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div></td>');
        var a_5 = $('<td class="w-12 aaa"><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div></td>');
        var a_6 = $('<td class="w-12 aaa"><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div></td>');
        var a_7 = $('<td class="w-4 aaa"><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div><div class="skeletion skeletion-text"></div></td>');
        a.append([
            a_1,
            a_2,
            a_3,
            a_4,
            a_5,
            a_6,
            a_7
        ])
        child_child_2_child_child_child.append([a]);
    }
    child.append([child_child.append(child_child_child.append(child_child_child_child.append(child_child_child_child_child.append([
        child_child_child_child_child_child_2,
        child_child_child_child_child_child_3,
        child_child_child_child_child_child_4,
        child_child_child_child_child_child_5,
        child_child_child_child_child_child_6,
        child_child_child_child_child_child_7,
        child_child_child_child_child_child_8,
    ])))), child_child_2.append(child_child_2_child.append(child_child_2_child_child.append(child_child_2_child_child_child)))])
    parent.append(child).appendTo($(".div-gridview"));
}
function gridViewDataUploadXML(data) {
    try {
        for (var i = 0; i < data.length; i++) {
            let id = data[i]._id;
            var parent = $('<tr class="tr-active tr-active-' + id + ' c-p" name="' + id + '"/>')
            //var child_2 = $('<td class="w-15 text-ellipsis" title="'+data[i].HanhChinh.HoTen+"| "+convertVN("GioiTinh",data[i].HanhChinh.GioiTinh)+" | "+calculateAge(data[i].HanhChinh.NgaySinh)+'T">'+data[i].HanhChinh.HoTen+'| '+ convertVN("GioiTinh",data[i].HanhChinh.GioiTinh) +" | "+calculateAge(data[i].HanhChinh.NgaySinh)+'T</td>');
            var child_2 = $('<td class="w-15 text-ellipsis" title="' + data[i].HanhChinh.HoTen + "| " + convertVN("GioiTinh", data[i].HanhChinh.GioiTinh) + " | " + ShowBirthday(data[i].HanhChinh.NgaySinh) + '">' + data[i].HanhChinh.HoTen + "| " + convertVN("GioiTinh", data[i].HanhChinh.GioiTinh) + " | " + ShowBirthday(data[i].HanhChinh.NgaySinh) + '</td>');
            var child_3 = $('<td class="w-29 text-ellipsis t-c" title="' + formatDateTime(data[i].QuanLyNguoiBenh.NgayVaoVien) + " - " + formatDateTime(data[i].QuanLyNguoiBenh.NgayRaVien) + '">' + formatDateTime(data[i].QuanLyNguoiBenh.NgayVaoVien) + " - " + formatDateTime(data[i].QuanLyNguoiBenh.NgayRaVien) + '</td>');
            var child_5 = $('<td class="w-15 text-ellipsis t-c" title="' + formatDate(data[i].Files_DateReceived) + '">' + formatDate(data[i].Files_DateReceived) + '</td>');
            var child_6 = $('<td class="w-13 text-ellipsis t-c" title="' + convertVN("LoaiKCB", data[i].LoaiKCB) + '">' + convertVN("LoaiKCB", data[i].LoaiKCB) + '</td>');
            var child_7 = $('<td class="w-12 text-ellipsis t-c">' + data[i].QuanLyNguoiBenh.TongSoNgayDieuTri + '</td>');
            var child_8 = $('<td class="w-12 text-ellipsis t-c td-result f-w-600">' + convertVN("KetQuaDieuTri", data[i].TinhTrangRaVien.KetQuaDieuTri) + '</td>');
            var child_9 = $('<td class="w-4 text-ellipsis t-c c-p div-dis"><i class="far fa-chevron-right"></i></td>');
            //var child_loading   = $('<div class="load-wrapp w-100"><div class="bar"></div></div>');
            //Chờ thực hiện
            if (data[i].Status.ID == 3) {
                child_9 = $('<td class="w-4 text-ellipsis t-c c-p div-dis-active div-dis div-dis-st' + id + '" name="' + id + '"><i class="far fa-chevron-right"></i></td>')
                parent = $('<tr class="tr-active tr-active-' + id + ' c-p n-c" name="' + id + '"/>')
            }
            //child_7.append(child_loading);
            //Đã thực hiện
            else if (config.status.status.includes(data[i].Status.ID))
                child_9 = $('<td class="w-4 text-ellipsis div-ba-detail t-c c-p" name="' + id + '"><i class="far fa-chevron-right"></i></td>');
            parent.append([child_2, child_3, child_5, child_6, child_7, child_8, child_9]);
            $("#tb-data").append(parent);
        }
    } catch (ex) {
        $("#tb-data").empty();
    }
}
function highlight() {
    var dropArea = document.getElementById('drop-area');
    dropArea.classList.add('highlight')
}
function unhighlight() {
    var dropArea = document.getElementById('drop-area');
    dropArea.classList.remove('highlight')
}
function resetFilterGridView() {
    $(".ip-name , .ip-cmnd , .ip-bhyt , .sl-htkcb").val("");
    $(".ip-formdate").val(formatDate2(new Date()));
    $(".ip-todate").val(formatDate2(new Date()));
    $(".sp-cskcb").text(_dt_managementAgency[0].HospitalName).attr("name", _dt_managementAgency[0].HospitalId);
    //$(".div-filter-key").hide();
    //$(".ip-formdate").val(formatDate2(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
}
function detailPatientRecords(id, action) {

    $(".div-content-function").empty();
    getDataPatientID(id);
    getDataPatientResourceID();
    addElementDetailPatientRecords();
    setValElementDetailPatientRecords();
    clearIntervalUploadXML();
    //------Jquery style---------------
    performPatientRecords();
    performJqueryXNCLS("", "-");
    //------Jquery style---------------
    localStorage.setItem("filterBA", JSON.stringify(_filterBenhAn));
    localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
}
export {
    getDataERMCountHospital,
    performUploadXML,
    addElementUploadXML,
    getDataUploadXML,
    resetFilterUploadXML,
    addElemmentprView,
    highlight,
    unhighlight,
    clearIntervalUploadXML,
    checkFilterBA
}