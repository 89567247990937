import React, { Component } from 'react';
import './ui.css';
import { checkRoleManagementagency, performHeader } from './ui_fc';
import user from '../../../Images/user.png';
export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = 
        {
            managementagency : checkRoleManagementagency() 
        };
      }
    componentWillMount()
    {
    }
    componentDidMount() {
        performHeader();
    }
    render() {
        return (
            <div className="w-100 div-header">
                <div className="w-100">
                    <div className="w-25 h-100 f-l">
                        <div className="div-img-logo f-l h-100 c-p">
                        </div>
                        <div className="f-l c-w">
                            <span>FHIR</span>
                        </div>
                    </div>
                    <div className="w-75 h-100 f-l">
                        {
                            this.props.action == "home" ?
                                <div className="w-100 h-100">
                                    <div className="h-100 f-r div-info-user c-w t-c c-p">
                                        <div className="f-r">
                                                <i className="fas fa-caret-down" />
                                        </div>
                                        <div className="h-100 f-r div-info-user-name c-w">
                                            <div>
                                                <span className="s-name" />
                                            </div>
                                        </div>
                                        <div className="h-100 f-r div-info-user-img">
                                            <img className="w-100 h-100" src={user}/>
                                        </div>
                                    </div>
                                    <div className="div-menu-logout c-w h">
                                        <div>
                                            <span>Tài khoản của tôi</span>
                                        </div>
                                        {this.state.managementagency?
                                            <div className="div-acc-management c-p">
                                                <span>Quản lý tài khoản</span>
                                            </div>:null
                                        }
                                        <div className="div-change-p-w c-p">
                                            <span>Đổi mật khẩu</span>
                                        </div>
                                        <div className="div-logout c-p">
                                            <span>Đăng xuất</span>
                                        </div>
                                    </div>
                                </div>: this.props.action=="share"?null:
                                <div className="c-w t-c">
                                    <div className="f-r c-p div-login-lp">
                                        <span>Đăng nhập</span>
                                    </div>
                                    <div className="f-r c-p div-create-acc-lp">
                                        <span>Đăng ký</span>
                                    </div>
                                    <div className="f-r c-p div-home">
                                        <span>Trang chủ</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}