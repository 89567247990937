import $ from 'jquery' ;
import './ui.css';
import Exporting from 'highcharts/modules/exporting';
import { _cookies, _height_header, _height_window } from '../../../Variable/defaultvar';
import { compare, getManagementAgency, SendData, SetChecked } from '../../../Function/ui';
import { lstMonth } from '../NumberVisitByDisease/ui_var';
import { getYear, performReportNumberVisitByDiseaseDefault } from '../NumberVisitByDisease/ui_fc';
import { addElementNoData } from '../MedicalRecordsAndCosts/ui_fc';
import { _phamacy } from './ui_var';
var dt_managementAgency = getManagementAgency(_phamacy.filterMA)
var  Highcharts = require('highcharts/highstock');
Exporting(Highcharts);
function resetFilterD()
{
  _phamacy.filterD.TakeTop = 40;
  _phamacy.filterD.FromMonth = 1;
  _phamacy.filterD.ToMonth = _phamacy.cd.getMonth() + 1;
  _phamacy.filterD.FromYear = _phamacy.cd.getFullYear();
  _phamacy.filterD.ToYear = _phamacy.cd.getFullYear();
  _phamacy.filterD.MedicationID = null;
  _phamacy.filterD.HospitalId = null;
  _phamacy.filterMA.PageNumber=1;
}
function createChart(id,obj)
{
  if(obj!=null)
  {
    Highcharts.chart(id, {
      chart: {
        type: 'bar',
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: obj.name,
        //min:0,
        //max:_c_bar,
        /*scrollbar: {
          enabled: true,
          liveRedraw: false 
        },*/
        labels: 
        { 
          useHTML: true, 
          formatter: function() { 
            let val = this.value;
            return '<div class="div-lb-hover-tt c-p" style="max-width:150px; white-space: nowrap; overflow:hidden; text-overflow:ellipsis;" title="'+val+'">'+val+'</div>'; 
          },
        },
        events: {
          setExtremes: function (e) {
              $('#report').html('<b>Set extremes:</b> e.trigger: ' + e.trigger + ', e.triggerOp: '+e.triggerOp);
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
       
        visible: false
      },
      legend: {
        enabled:false,
        reversed: true,
        align: 'right',
        verticalAlign: 'top',
        font:"Segoe UI",
        itemStyle: {
          color: 'rgb(102, 102, 102)',
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: 
          {
            enabled: true,
            style:
            {
              fontFamily:"Seogoe UI",
              color:"black",
              textOutline: false,
              fontSize:_phamacy.f_size
            },
            align : 'left',
          },
          pointWidth:_phamacy.h_bar
        }
      },
      series: obj.value,
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    });
  }
  else
  {
    addElementNoData(id);
  }
}
function creatChartColumn(id,obj)
{
  try
  {
      Highcharts.chart(id, {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
              fontSize:14
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        }
    
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}',
            style:
            {
              fontSize:13
            }
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
      },
      series: [
        {
          name: "Tổng số lượng thuốc",
          colorByPoint: true,
          data: obj
        }
      ],
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    });
  }
  catch(ex)
  {
    addElementNoData(id);
  }
  
}
function addElementReportPharmacy()
{
    addElementReportPharmacyDefault();
    addElementReportPharmacyTitle();
    addElementReportPharmacyContent();
    addELementReportCSKCB(dt_managementAgency);
    //--------Phần giao diện biểu đồ-------------//
    addElementChartDG();
    var id = (($(".div-lb-hover-tt").first().text()).split("-"))[0];
    if(id!="") addElementChartTH(id);
    else addElementNoData("chartTH");
}
function performReportPharmacy()
{
  performReportNumberVisitByDiseaseDefault();
  performReportPharmacySelectDate();
  performReportPharmacyContent();
  SetChecked(".ip-cb-cskcb-",_phamacy.filterD.HospitalId,true);
}
function addThemeHightChart(color)
{
    Highcharts.theme = {
      colors: color,
    };
    // Apply the theme
    Highcharts.setOptions(Highcharts.theme);
   
}
function addElementReportPharmacyDefault()
{
    var parent = $('<div class="div-rp-pmcy"/>');
    parent.appendTo($(".div-content-function"));
}
function addElementReportPharmacyTitle()
{
  var year = getYear();
  var parent = $('<div class="div-rp-pmcy-title"/>');
  var child_1 = $('<div class="w-40 f-l"><p>Thống kê Dược</p><p>Thống kê danh sách số lượng thuốc</p></div>');
  var child_2 = $('<div class="w-60 f-l"></div>');
  var child_2_child_0 = dt_managementAgency.length > 1? $('<div class="w-50 f-l p-d-l-r-1"/>') : null;
  var child_2_child_1 = $('<div class="'+(dt_managementAgency.length > 1 ? "w-25" : "w-50")+' f-l p-d-l-r-1"/>');
  var child_2_child_2 = $('<div class="'+(dt_managementAgency.length > 1 ? "w-25" : "w-50")+' f-l p-d-l-r-1"/>');
  var child_2_child_0_child_1 = $('<div class="w-100"><span>Cơ sở KCB</span></div>');
  var child_2_child_0_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_1_child_1 = $('<div class="w-100"><span>Năm thống kê</span></div>');
  var child_2_child_1_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_2_child_1 = $('<div class="w-100"><span>Tháng thống kê</span></div>');
  var child_2_child_2_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_0_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-cskcb text-ellipsis-n-w">Tất cả</label>');
  var child_2_child_0_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-cskcb">');
  var child_2_child_1_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-year">'+year[year.length-1]+'</label>');
  var child_2_child_1_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-ntk">');
  var child_2_child_2_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-month dropdown-nvbd-list-ttk">Tất cả</label>');
  var child_2_child_2_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-ttk">');
  var child_2_child_0_child_2_child_2_child_1 = $('<div class="div-box-search"><input class="ip-box-search w-100" type="text" placeholder="Tìm kiếm"/></div>');
  var child_2_child_0_child_2_child_2_child_2 = $('<div class="div-lst-cskcb" />')
  for(var i = 0 ; i< year.length ; i++)
  {
    var a = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ntk ip-cb-ntk-'+year[i]+'" type="checkbox" name="dropdown-nvbd-group" value="'+year[i]+'"/></div><div class="w-94 f-l div-sp-ntk" name="'+year[i]+'"><span class="c-p">'+year[i]+'</span></div></lable>')
    child_2_child_1_child_2_child_2.append(a);
  }
  for(var i = 0 ; i< lstMonth.length ; i++)
  {
    var child_month = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ttk ip-cb-ttk-'+(i+1)+' div-check-month-ip div-check-month-ip-'+lstMonth[i].value+'" type="checkbox" name="dropdown-nvbd-group" disabled value="'+lstMonth[i].value+'"/></div><div class="w-94 f-l div-dis div-check-month-cb div-check-month-cb-'+lstMonth[i].value+'" name="'+lstMonth[i].value+'"><span >'+lstMonth[i].name+'</span></div></lable>');
    if(i < _phamacy.cd.getMonth()+1)
      child_month = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ttk ip-cb-ttk-'+(i+1)+' div-check-month-ip div-check-month-ip-'+lstMonth[i].value+'" type="checkbox" name="dropdown-nvbd-group" value="'+lstMonth[i].value+'" /></div><div class="w-94 f-l div-sp-ttk div-check-month-cb div-check-month-cb-'+lstMonth[i].value+'" name="'+lstMonth[i].value+'"><span class="c-p">'+lstMonth[i].name+'</span></div></lable>')
    child_2_child_2_child_2_child_2.append(child_month);
  }
  if(dt_managementAgency.length > 1)
  {
    child_2_child_0.append([
      child_2_child_0_child_1,
      child_2_child_0_child_2.append([
        child_2_child_0_child_2_child_1,
        child_2_child_0_child_2_child_2.append([
          child_2_child_0_child_2_child_2_child_1,
          child_2_child_0_child_2_child_2_child_2
        ])
      ])
    ])
  }
  parent.append([child_1,child_2.append([
    child_2_child_0,
    child_2_child_1.append([
      child_2_child_1_child_1,
      child_2_child_1_child_2.append([
        child_2_child_1_child_2_child_1,
        child_2_child_1_child_2_child_2
      ])
    ]),
    child_2_child_2.append([
      child_2_child_2_child_1,
      child_2_child_2_child_2.append([
        child_2_child_2_child_2_child_1,
        child_2_child_2_child_2_child_2
      ])
    ])
  ])]);
  parent.appendTo($(".div-rp-pmcy"));
}
function addElementReportPharmacyContent() {
  var parent          = $('<div class="div-rp-pmcy-content" style="height:'+(_height_window-$(".div-rp-pmcy-title").height()-$('.div-header').height())+'px"/>');
  var child_1         = $('<div class="w-50 f-l div-rp-pmcy-content-child"><div class="w-100" style="border-bottom:none"><span>Số lượng </span><span class="sp-name-th"></span><span> được sử dụng trong tháng</span></div><div class="w-100" id="chartTH" style="height:90%;padding:3.5% 1%"></div></div>');
  var child_2         = $('<div class="w-50 f-l div-rp-pmcy-content-child" style="padding:0 1%"/>');
  var child_2_child_1 = $('<div class="w-100 t-r"><span>Top lượng thuốc sử dụng</span></div>');
  var child_2_child_2 = $('<div class="w-100" style="height:'+_phamacy.hdiv+'px"></div>');
  var child_2_child_2_child_1 = $('<div class="w-100"></div>');
  var child_2_child_2_child_2 = $('<div class="w-100" id="chartDG" style="height:100%"></div>')
  parent.append([
    child_1,
    child_2.append([
      child_2_child_1,
      child_2_child_2.append([
        child_2_child_2_child_1,
        child_2_child_2_child_2
      ])
      ])
    ]);
  parent.appendTo($(".div-rp-pmcy"));
}
function addELementReportCSKCB(data)
{
  for(var i = 0 ; i< data.length ; i++)
  {
    var lb = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-cskcb ip-cb-cskcb-'+data[i].HospitalId+'" type="checkbox" name="dropdown-nvbd-group" value="'+data[i].HospitalId+'"/></div><div class="w-94 f-l div-sp-cskcb text-ellipsis-n-w" name="'+data[i].HospitalId+'" title="'+data[i].HospitalName+'"><span class="c-p">'+data[i].HospitalName+'</span></div></lable>')
    $(".div-lst-cskcb").append(lb);
  }
}
//Phần giao diện biểu đồ thể hiện thống kê bệnh theo 
function addElementChartDG()
{
  var data = handlingDataChartDG();
  addThemeHightChart(_phamacy.color1);
  createChart("chartDG",data);
}
//Phần giao diện biểu đồ thống kê dược
function addElementChartTH(id)
{
  var obj = handlingDataChartTH(id);
  addThemeHightChart(_phamacy.color2);
  if(obj.data.length>0) creatChartColumn("chartTH",obj.data);
  else addElementNoData("chartTH");
  $(".sp-name-th").text(obj.name);
}
function performReportPharmacyContent()
{
  $(".div-lb-hover-tt").unbind().on("click",function(){
    var idTH = ($(this).text()).split("-");
    $(".div-lb-hover-tt").removeClass("div-active");
    $(this).addClass("div-active");
    $(".sp-name-th").text("");
    $("#chartTH").empty();
    if(idTH!="") addElementChartTH(idTH[0]);
    else addElementNoData("chartTH");
  })
  $(".div-lb-hover-tt").each(function(index){
    $(this).prop("title",$(this).text());
  })
  //$("#chartDG , .highcharts-container").height($(".div-rp-pmcy-content").height()-60);
}
function performReportPharmacySelectDate()
{
  var CheckboxDropdown = function(el) 
  {
    var _this = this;
    this.isOpen = false;
    this.areAllChecked = false;
    this.$el = $(el);
    this.$label = this.$el.find('.dropdown-nvbd-label');
    this.$label.on('click', function(e) {
      e.preventDefault();
      _this.toggleOpen();
    });
   
  };
  CheckboxDropdown.prototype.toggleOpen = function(forceOpen) {
    var _this = this;
    if(!this.isOpen || forceOpen) {
       this.isOpen = true;
       this.$el.addClass('on');
      $(document).on('click', function(e) {
        if(!$(e.target).closest('[data-control]').length) {
         _this.toggleOpen();
        }
      });
    }
    else {
      this.isOpen = false;
      this.$el.removeClass('on');
      $(document).off('click');
    }
  };
  var checkboxesDropdowns = document.querySelectorAll('[data-control="checkbox-dropdown"]');
  for(var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
    new CheckboxDropdown(checkboxesDropdowns[i]);
  }
  var year = getYear();
  SetChecked(".ip-cb-ntk-",year[year.length-1],true);
  $(".div-sp-ntk").unbind().on("click",function(){
    var id = $(this).attr("name");
    if($(".ip-cb-ntk-"+id).is(":checked"))
    {
      $(".ip-cb-ntk-"+id).prop("checked",false);
      id = year[year.length-1];
      SetChecked(".ip-cb-ntk-",id,true);
    }
    else
    {
      SetChecked(".ip-cb-ntk","",false);
      $(".ip-cb-ntk-"+id).prop("checked",true);
    }
    $(".dropdown-nvbd-label-year").text(id);
    handlingDataByYear(id);
  })
  $(".ip-cb-ntk").unbind().on("change",function()
  {
    var id ;
    if($(this).is(":checked"))
    {
      id = $(this).val();
      SetChecked(".ip-cb-ntk","",false);
      $(this).prop("checked",true);
    }
    else
    {
      id = year[year.length-1];
      SetChecked(".ip-cb-ntk-",id,true);
    }
    $(".dropdown-nvbd-label-year").text(id);
    handlingDataByYear(id);
  })
  performJqueryTTK();
  performSearchCSKCB();
  $(".ip-box-search").unbind().on("keyup",function(e){
    if(e.keyCode == 13) 
    {
      _phamacy.filterMA.Find = $(this).val();
      _phamacy.filterMA.PageNumber = 1;
      $(".div-lst-cskcb").empty();
      addELementReportCSKCB(getManagementAgency(_phamacy.filterMA));
      performSearchCSKCB();
      performScrollCSKCB();
      try
      {
        var id = dt_managementAgency.filter(x=>x.HospitalName == $(".dropdown-nvbd-label-cskcb").text())[0].HospitalId;
        SetChecked(".ip-cb-cskcb-",id,true);
      }
      catch(ex){}
    }
  })
  $(".dropdown-nvbd-list-ntk").animate({ scrollTop: $(".dropdown-nvbd-list-ntk").height() }, 1000);
  performScrollCSKCB();
}
function performJqueryTTK()
{
  $(".div-sp-ttk").unbind().on("click",function(){
    var id = $(this).attr("name");
    if($(".ip-cb-ttk-"+id).is(":checked"))
    {
      $(".ip-cb-ttk-"+id).prop("checked",false);
      $(".dropdown-nvbd-label-month").text("Tất cả");
      handlingDataByMonth("full");
    }
    else
    {
      SetChecked(".ip-cb-ttk","",false);
      var name = lstMonth.filter(x=>x.value==id)[0].name;
      $(".ip-cb-ttk-"+id).prop("checked",true);
      $(".dropdown-nvbd-label-month").text(name);
      handlingDataByMonth(id);
    }
  })
  $(".ip-cb-ttk").unbind().on("change",function(){
    if($(this).is(":checked"))
    {
      var id = $(this).val();
      SetChecked(".ip-cb-ttk","",false);
      $(this).prop("checked",true);
      var name = lstMonth.filter(x=>x.value==id)[0].name;
      $(".dropdown-nvbd-label-month").text(name);
      handlingDataByMonth(id);
    }
    else
    {
      $(".dropdown-nvbd-label-month").text("Tất cả");
      $(this).prop("checked",false);
      handlingDataByMonth("full");
    }
  })
}
function performSearchCSKCB()
{
  $(".div-sp-cskcb").unbind().on("click",function(){
    var name ;
    var id = $(this).attr("name");
    if($(".ip-cb-cskcb-"+id).is(":checked"))
    {
      id= null;
      name ="Tất cả"
      SetChecked(".ip-cb-cskcb-",$(this).attr("name"),false)
    }
    else
    {
      SetChecked(".ip-cb-cskcb","",false);
      name = dt_managementAgency.filter(x=>x.HospitalId==id)[0].HospitalName;
      $(".ip-cb-cskcb-"+id).prop("checked",true);
    }
    $(".dropdown-nvbd-label-cskcb").text(name);
    SetChecked(".ip-cb-cskcb-",id,true);
    handlingDataCSKCB(id);
  })
  $(".ip-cb-cskcb").unbind().on("change",function(){
    var name ;
    if($(this).is(":checked"))
    {
      var id = $(this).val();
      name = dt_managementAgency.filter(x=>x.HospitalId==id)[0].HospitalName;
      SetChecked(".ip-cb-cskcb","",false);
      $(this).prop("checked",true);
      handlingDataCSKCB(id);
    }
    else
    {
      name = "Tất cả";
      $(this).prop("checked",false);
      handlingDataCSKCB(null);
    }
    $(".dropdown-nvbd-label-cskcb").text(name);
  })
  try
  {
    var id = dt_managementAgency.filter(x=>x.HospitalName == $(".dropdown-nvbd-label-cskcb").text())[0].HospitalId;
    SetChecked(".ip-cb-cskcb-",id,true);
  }
  catch(ex){}
}
function performScrollCSKCB()
{
  $(".div-lst-cskcb").scroll(function () {
    var scrollHeight = $(".div-lst-cskcb").prop("scrollHeight");
    if ($(".div-lst-cskcb").scrollTop() ==  Math.round(scrollHeight-($(".div-lst-cskcb").height()))) {
      fetchMoreDataMA();
      var data = getManagementAgency(_phamacy.filterMA);
      dt_managementAgency = dt_managementAgency.concat(data);
      addELementReportCSKCB(data);
      performSearchCSKCB();
    }
  });
}
function getDataChart(action)
{
  var data ={};
  switch(action)
  {
      //Diagnostic Count Hospital Lấy danh sách thống kê số lượng bệnh theo giới tính
      case 1 :
      {
        data =  SendData("api/Report/MedicationCountHospital/Summary","get",_phamacy.filterD);
        break;
      }
     //Lấy danh sách thống kê số lượng bệnh theo độ tuổi
     case 2 :
      {
        data =  SendData("api/Report/MedicationCountHospital/ByMonth","get",_phamacy.filterD);
        break;
      }
  }
  return data;
}
function handlingDataCSKCB(cskcb)
{
  _phamacy.filterD.HospitalId = cskcb;
  resetElement();
}
function handlingDataByMonth(month)
{
  if(month=="full")
  {
    _phamacy.filterD.FromMonth = 1;
    _phamacy.filterD.ToMonth   = 12;
  }
  else
  {
    _phamacy.filterD.FromMonth = month;
    _phamacy.filterD.ToMonth   = month;
  }
  resetElement();
}
function handlingDataByYear(year)
{
  var currentMonth = _phamacy.cd.getMonth()+1;
  _phamacy.filterD.FromYear = year;
  _phamacy.filterD.ToYear   = year;
  _phamacy.filterD.FromMonth = 1;
  _phamacy.filterD.ToMonth   = 12;
  if(year < _phamacy.cd.getFullYear())
  {
    $(".div-check-month-cb").removeClass("div-dis").addClass("div-sp-ttk");
    $(".div-check-month-ip").prop( "disabled", false );
    performJqueryTTK();
  }
  else
  {
    SetChecked(".ip-cb-ttk","",false);
    for(var i = 1 ; i<= 12;i++)
    {
      if(i>currentMonth)
      {
        $(".div-check-month-cb-"+i).addClass("div-dis").removeClass("div-sp-ttk").unbind("click");
        $(".div-check-month-ip-"+i).prop("checked", false).prop("disabled", true);
      }
    }
  }
  $(".dropdown-nvbd-label-month").text("Tất cả");
  resetElement();
}
function handlingDataChartDG()
{
  _phamacy.filterD.TakeTop = 40;
  var data = getDataChart(1);
  if(data == undefined || data.length == 0) return null;
  var dt = {};
  dt.name = [];
  dt.value = 
  [
    {
      "name" : "",
      data   : []
    }
  ];
  //var index = Math.ceil(_height_window / 40);
  try
  {
    var index = 26;
    for(var i=0;i<=index;i++)
    {
      var name = data[i].Medication.ID +"-"+ data[i].Medication.Name;
      dt.name.push(name);
      dt.value[0].data.push(data[i].Count);
    }
    return dt
  }
  catch(ex)
  {
    return dt
  }
}
function handlingDataChartTH(id)
{
  var dt = 
  {
    data : []
  };
  try
  {
    _phamacy.filterD.MedicationID = id;
    _phamacy.filterD.TakeTop = 40;
    var data = getDataChart(2);
    for(var i =0;i<data.length;i++)
    {
      var obj = {};
      obj.name="Tháng "+data[i].Month;
      obj.y = data[i].Count;
      dt.data.push(obj);
    }
    //dt.data = (dt.data).sort(compare);
    dt.name = data[0].Medication.Name;
    dt.data = dt.data.sort((a,b)=> Number(a.name.replace("Tháng ",'')) - Number(b.name.replace("Tháng ",'')))
    return dt
  }
  catch(ex){
    return dt
  }
}
function resetElement()
{
    $("#chartDG , #chartTH , .div-chartPtAge-content , .div-chartPtKCB-content").empty();
    $(".sp-name-th").text("");
    //Biểu đồ thống kê bệnh theo giới tính
    addElementChartDG();
    //Biểu đồ thống kê số lần khám theo tuổi
    var id = (($(".div-lb-hover-tt").first().text()).split("-"))[0];
    if(id!="") addElementChartTH(id);
    else addElementNoData("chartTH");
    //Biểu đồ thống kê số lần khám theo tuổi
    performReportPharmacyContent();
}
function fetchMoreDataMA()
{
  _phamacy.filterMA.PageNumber += 1;
}
export {addElementReportPharmacy , performReportPharmacy , resetFilterD }