import React from 'react';
import '../ui/ui.css';
import { addElementConfirmEmail, performJQConfirmEmail } from './ui_fc';
export default class ConfirmEmail extends React.Component {
    componentWillMount()
    {
    }
    componentDidMount()
    {
        addElementConfirmEmail();
        performJQConfirmEmail();
    }
    render() {
        return (
            <div className="div-content-login" >
            </div>
        )
    }
}