import { isNumber } from 'highcharts';
import $ from 'jquery';
import { safeRead } from '../../Function/ui';
import { 
    _cookies, 
    _height_window 
} from '../../Variable/defaultvar';

import { 
    addElementUploadXML , 
    checkFilterBA, 
    clearIntervalUploadXML, 
    getDataERMCountHospital, 
    getDataUploadXML, 
    performUploadXML, 
    resetFilterUploadXML 
} from '../PatientRecords/UploadXML/ui_fc';
import { 
    addElementReportNumberVisitByDisease, 
    performReportNumberVisitByDisease, 
    setFilterDCH 
} from '../Report/NumberVisitByDisease/ui_fc';
import {  
    addElementReportPharmacy, 
    performReportPharmacy, 
    resetFilterD  
} from '../Report/Pharmacy/ui_fc';
import {  
    addElementReportMedicalRecordsAndCosts, 
    performReportMedicalRecordsAndCosts, 
    resetFilterYMD  
} from '../Report/MedicalRecordsAndCosts/ui_fc';
import { 
    addElementUploadFileXML, 
    clearIntervalUploadFileXML, 
    getDataManagementAgencyUploadFile, 
    getDataUploadFileXML, 
    performUploadFileXML , 
    resetFilterUploadFileXML
} from '../UploadFileXML/ui_fc';
import { 
    lstBIApi//, 
    // lstDMMenu 
} from './ui_var';
import { 
    addElementHospitalPerformance, 
    performHospitalPerformance 
} from '../Report/HospitalPerformance/ui_fc';
import config from '../../../config';
function program(page) {
    $(".div-content-function").empty();
    clearIntervalDefault();
    /*window.onresize = resize;
    function resize() {
        performUploadFileXML();
        performUploadXML();
        performReport();
        performReportNumberVisitByDisease();
    }*/
    $(document).keyup(function(e){
        if(e.which==122){
            e.preventDefault();//kill anything that browser may have assigned to it by default
            performUploadFileXML();
            performUploadXML();
            performReportPharmacy();
            performReportNumberVisitByDisease();
            return false;
        }
     });
    switch(page)
    {
        // Trang quản lý file upload
        case 0 :
        {
            getDataManagementAgencyUploadFile();
            resetFilterUploadFileXML();
            resetFilterUploadXML();
            getDataUploadFileXML();
            addElementUploadFileXML();
            performUploadFileXML();
            break;
        }
        // Trang bệnh án
        case 1 :
        {
            checkFilterBA();
            getDataERMCountHospital();
            getDataUploadXML();
            addElementUploadXML();
            performUploadXML();
            break;
        }
        // Trang thống kê dược
        case 2 :
        {
            resetFilterD();
            resetFilterUploadXML();
            addElementReportPharmacy();
            performReportPharmacy();
            break;
        }
        // Trang thống kê bệnh nhân
        case 3 :
        {
            setFilterDCH();
            resetFilterUploadXML();
            addElementReportNumberVisitByDisease();
            performReportNumberVisitByDisease();
            break;
        }
         // Trang thống số chi phí KCB
        case 4 :
        {
            resetFilterYMD();
            resetFilterUploadXML();
            addElementReportMedicalRecordsAndCosts();
            performReportMedicalRecordsAndCosts();
            break;
        }
        // Trang thống kê hiệu suất bệnh viện
        case 5 :
        {
            addElementHospitalPerformance();
            performHospitalPerformance(lstBIApi[0].api);
            break;
        }
        // Trang Quản lý bệnh viện
        case 6 :
        {
            addElementHospitalPerformance();
            performHospitalPerformance(lstBIApi[1].api);
            break;
        }
        // Trang KPIs
        case 7 :
        {
            addElementHospitalPerformance();
            performHospitalPerformance(lstBIApi[2].api);
            break;
        }
        // Trang Tư vấn BHYT
        case 8 :
        {
            addElementHospitalPerformance();
            performHospitalPerformance(lstBIApi[3].api);
            break;
        }
        // Trang yêu cầu BHYT
        case 9 :
        {
            addElementHospitalPerformance();
            performHospitalPerformance(lstBIApi[4].api);
            break;
        }
    }
}
function clearIntervalDefault()
{
    clearIntervalUploadXML();
    clearIntervalUploadFileXML();
}
function performJqueryHome()
{
    $(".menu-toggle").unbind().on("click",function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
        $(".i-arrow").toggleClass("i-right");
        $(".div-content-function").toggleClass("div-content-toggle");
        if( $(".div-content-function").hasClass("div-content-toggle"))
        {
            $(".div-rp-pmcy-content").css('width', '100%').css('width', '-=100px');
            $(".div-lst-menu-child").css("padding","15px 3px");
        }
        else
        {
            $(".div-rp-pmcy-content").css('width', '100%').css('width', '-=255px');
            $(".div-lst-menu-child").css("padding","15px 3px 15px 20px");
        }
    });
    $(".div-menu-title-content p:first-child").text(_cookies.get('information_user'));
    $(".div-content-function").height(_height_window-60);
    $(".div-lst-menu:first-child").addClass("div-lst-menu-active");
    $(".div-lst-menu-child").css("padding","15px 3px 15px 20px");
    $(".div-lst-menu").unbind().on("click",function(){
        $(".div-lst-menu , .div-click-child-menu").removeClass("div-lst-menu-active");
        $(".div-lst-menu-child").removeClass("div-lst-menu-active-2");
        $(".div-lst-menu-child").addClass("h");
        $(this).addClass("div-lst-menu-active");
        var id = $(this).attr("name");
        program(Number(id));
    })
    $(".div-lst-menu-child").unbind().on("click",function(){
        $(".div-lst-menu-child").removeClass("div-lst-menu-active-2");
        $(this).addClass("div-lst-menu-active-2");
        var id = $(this).attr("name");
        program(Number(id));
    })
    $(".div-click-child-menu").unbind().on("click",function(){
        $(".div-lst-menu , .div-click-child-menu").removeClass("div-lst-menu-active");
        $(this).addClass("div-lst-menu-active");
        var id = $(this).attr("name");
        $(".div-lst-menu-child-"+id).toggleClass("h");
        $(".div-lst-menu-child").removeClass("div-lst-menu-active-2");
        $(".div-lst-menu-child:first").addClass("div-lst-menu-active-2");
        var id = $(this).attr("name");
        program(Number(3));
    })
}
function addElementListMenu()
{
   for(var i=0;i<config.menu.length;i++)
    {
        var elm    = config.menu[i].icon!=null?'<i class="fal '+config.menu[i].icon+'" />':'&nbsp;';
        var parent = $('<div class="div-lst-menu c-p" name="'+config.menu[i].id+'"/>');
        var child_1 = $('<div class="div-DM-icon f-l t-c">'+elm+'</div>')
        var child_2 = $('<div class="div-DM-content text-ellipsis-n-w f-l">'+config.menu[i].name+'</div>');
        if(!isNumber(config.menu[i].id)) parent = $('<div class="div-click-child-menu c-p" name="'+config.menu[i].id+'"/>');
        parent.append([child_1,child_2])
        $(".div-menu-content").append(parent);
        if(safeRead(config.menu[i],"child")!="")
        {
            for(var x = 0 ; x<config.menu[i].child.length;x++)
            {
                var parent_child_child = $('<div class="div-lst-menu-child div-lst-menu-child-'+config.menu[i].id+' c-p h" name="'+config.menu[i].child[x].id+'"></div>');
                var parent_child_child_child_1 = $('<div class="div-DM-icon f-l t-c"><i class="fal '+config.menu[i].child[x].icon+'" /></div>')
                var parent_child_child_child_2 = $('<div class="div-DM-content text-ellipsis-n-w f-l" title="'+config.menu[i].child[x].name+'">'+config.menu[i].child[x].name+'</div>');
                parent_child_child.append([
                    parent_child_child_child_1,
                    parent_child_child_child_2
                ]);
                $(".div-menu-content").append(parent_child_child);
            }
        }
       
    }
    // for(var i=0;i<lstDMMenu.length;i++)
    // {
    //     var elm    = lstDMMenu[i].icon!=null?'<i class="fal '+lstDMMenu[i].icon+'" />':'&nbsp;';
    //     var parent = $('<div class="div-lst-menu c-p" name="'+lstDMMenu[i].id+'"/>');
    //     var child_1 = $('<div class="div-DM-icon f-l t-c">'+elm+'</div>')
    //     var child_2 = $('<div class="div-DM-content text-ellipsis-n-w f-l">'+lstDMMenu[i].name+'</div>');
    //     if(!isNumber(lstDMMenu[i].id)) parent = $('<div class="div-click-child-menu c-p" name="'+lstDMMenu[i].id+'"/>');
    //     parent.append([child_1,child_2])
    //     $(".div-menu-content").append(parent);
    //     if(safeRead(lstDMMenu[i],"child")!="")
    //     {
    //         for(var x = 0 ; x<lstDMMenu[i].child.length;x++)
    //         {
    //             var parent_child_child = $('<div class="div-lst-menu-child div-lst-menu-child-'+lstDMMenu[i].id+' c-p h" name="'+lstDMMenu[i].child[x].id+'"></div>');
    //             var parent_child_child_child_1 = $('<div class="div-DM-icon f-l t-c"><i class="fal '+lstDMMenu[i].child[x].icon+'" /></div>')
    //             var parent_child_child_child_2 = $('<div class="div-DM-content text-ellipsis-n-w f-l" title="'+lstDMMenu[i].child[x].name+'">'+lstDMMenu[i].child[x].name+'</div>');
    //             parent_child_child.append([
    //                 parent_child_child_child_1,
    //                 parent_child_child_child_2
    //             ]);
    //             $(".div-menu-content").append(parent_child_child);
    //         }
    //     }
       
    // }
}
export {program , performJqueryHome , addElementListMenu}