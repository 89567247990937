import $ from 'jquery';
export const config =
{
    host: GetJson("config-api.json"),
    status: GetJson("config-status.json"),
    data: GetJson("config-data.json"),
    menu: GetJson("config-menu.json"),
};
function GetJson(fileName) {
    var json_Out = "";
    var json = $.getJSON({ 'url': process.env.PUBLIC_URL + "/config/" + fileName, 'async': false });
    $.ajaxSetup({ cache: false });
    if (json.responseText != null) {
        let returnJson = json.responseText;
        while (returnJson.indexOf("/*") != -1) {
            let firtcmt = returnJson.indexOf("/*");
            let firtJson = returnJson.slice(0, firtcmt);
            let lastcmt = returnJson.indexOf("*/");
            let lastJson = returnJson.slice(lastcmt+2,returnJson.length);
            returnJson=firtJson+lastJson;
        }
        json_Out = JSON.parse(returnJson);
    }
    return json_Out;
}
export default config;