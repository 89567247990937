//Danh sách phần menu
// var lstDMMenu = 
// [
//     {
//         id : 0,
//         "name" : "Quản lý tập tin",
//         "icon" : "fa-cloud-upload-alt"
//     },
//     {
//         id : 1,
//         "name" : "Quản lý bệnh án",
//         "icon" : "fa-file-medical"
//     },
//     {
//         id : "tk",
//         "name"  : "Quản lý thống kê" ,
//         "icon"  : "fa-file-chart-line",
//         "child" : [
//             {
//                 id : 3,
//                 "name" : "Bệnh nhân" ,
//                 "icon" : "fal fa-user-injured",
//             },
//             {
//                 id : 2,
//                 "name" : "Dược" ,
//                 "icon" : "fal fa-capsules",
//             },
//             {
//                 id : 4,
//                 "name" : "Chi phí KCB" ,
//                 "icon" : "fa-money-check-edit-alt",
//             },
//             {
//                 id : 5,
//                 "name" : "Hiệu suất KCB" ,
//                 "icon" : "fal fa-chart-line",
//             },
//             {
//                 id : 6,
//                 "name" : "Quản lý bệnh viện" ,
//                 "icon" : "fal fa-hospital",
//             },
//             {
//                 id : 7,
//                 "name" : "Chỉ số sức khỏe" ,
//                 "icon" : "fal fa-heart-rate",
//             },
//             {
//                 id : 8,
//                 "name" : "Tư vấn BHYT" ,
//                 "icon" : "fal fa-comment-alt-medical",
//             },
//             {
//                 id : 9,
//                 "name" : "Chi phí KCB BHYT" ,
//                 "icon" : "far fa-money-check",
//             },
//         ]
//     },
// ];
var lstBIApi = 
[
    {
        name : "Hospital Performance",
        api : "https://bidemo.surehis.com/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ANGULAR_ACTION&NEW_SESSION=TRUE&OBJECT_LABEL=HospitalPerformance&TOOLBAR_VISIBLE=false"
    },
    {
        name : "Hospital Management",
        api : "https://bidemo.surehis.com/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ANGULAR_ACTION&NEW_SESSION=TRUE&OBJECT_LABEL=HospitalManagement&TOOLBAR_VISIBLE=false"
    },
    {
        name : "Hospital KPIs",
        api : "https://bidemo.surehis.com/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ANGULAR_ACTION&NEW_SESSION=TRUE&OBJECT_LABEL=HospitalKPIs&TOOLBAR_VISIBLE=false"
    },
    {
        name : "Health Insurance Consultation",
        api : "https://bidemo.surehis.com/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ANGULAR_ACTION&NEW_SESSION=TRUE&OBJECT_LABEL=HealthInsuranceConsultation&TOOLBAR_VISIBLE=false"
    },
    {
        name : "Health Insurance Claims",
        api : "https://bidemo.surehis.com/knowage/servlet/AdapterHTTP?ACTION_NAME=EXECUTE_DOCUMENT_ANGULAR_ACTION&NEW_SESSION=TRUE&OBJECT_LABEL=HealthInsuranceClaims&TOOLBAR_VISIBLE=false"
    }
]
 export {lstBIApi}
// export {lstDMMenu , lstBIApi}