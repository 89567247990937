import './ui.css';
import $ from 'jquery';
import { formatDate2, formatDateTime, getManagementAgency, safeRead, SendData, SendDataFile, ToggleSelectFilter3 } from '../../Function/ui';
import { notification } from '../Notification/ui_fc';
import {  highlight , unhighlight } from "../PatientRecords/UploadXML/ui_fc";
import { _cookies, _height_window } from '../../Variable/defaultvar';
import config from '../../../config';
import { logout } from '../DefaultInterface/Header/ui_fc';
var progressBar , 
    _dtFile     ,
    _crHstId    ,
    _dt_managementAgency, 
    _idDelete   ,
    _resetStatus,
    _uploadProgress ,
    _textFileFailReset = "Không thành công , Vui lòng kiểm tra lỗi tại file. ",
    _textFileFail      = _textFileFailReset,
    _file_upload       = [],
    _list_file         = [],
    _list_file_error   = [],
    _list_show_error   = [],
    _list_image_dicom  = [],
    _searchMA = null ,
    _filterFile = 
    {
        To          : null,
        From        : null,
        FileName    : null,
        PageNumber  : 1,
        RowsPerPage : 20,
        HospitalId  : null
    },
    _pg_index = 
    {
        first : 1,
        last  : 5
    };
var _filterMA = {};
    _filterMA.ID = null;
    _filterMA.Find = null;
    _filterMA.RowsPerPage = 10;
    _filterMA.PageNumber = 1;
function turnoffSetIntervalUploadFileXML()
{
    clearInterval(_resetStatus);
}
function getDataUploadFileXML()
{
    _dtFile = SendData("api/File/GetList","get",_filterFile); 
}
function getDataManagementAgencyUploadFile()
{
    _filterMA.PageNumber = 1;
    _dt_managementAgency = getManagementAgency(_filterMA);
    if(_dt_managementAgency.length> 0) 
    {
        _filterFile.HospitalId = _dt_managementAgency[0].HospitalId;
        _crHstId = _dt_managementAgency[0].HospitalId;
    }
}
function addElementUploadFileXML()
{
    addElemmentprView();
    addElementFilter();
    addElementGridView();
    addELementPagination();
    addElementDataGridView(_dtFile);
    addELementListPagination(_dtFile.TotalPages)
    addElementManagementAgency(_dt_managementAgency);
    
}
function addELementListPagination(count)
{
    var index = Number(count);
    $(".div-lst-pagination").empty();
    $(".div-pagination").show();
    if(count < 2) 
    {
        $(".div-pagination").hide();
        return ;
    }
    if( index > _pg_index.last)
    {
        index = _pg_index.last;
    }
    if(_pg_index.first == 0)
    {
        _pg_index.first = 1;
        index  = 5;
    }
    for(var i = _pg_index.first ;i<= index;i++)
    {
        var parent = $('<a class="a-pagination" href="#" name="'+i+'">'+i+'</a>');
        parent.appendTo($(".div-lst-pagination"));
    }
    $('.div-lst-pagination a[name='+_filterFile.PageNumber+']').addClass("active");
    $(".a-pagination").unbind().on("click",clickPagination);
}
function clickPagination()
{
    $(".a-pagination").removeClass("active");
    $(this).addClass("active");
    _filterFile.PageNumber = Number($(this).attr("name"));
    filterUploadFileXML(false);
}
function reloadDataXML()
{
    //resetFilterUploadFileXML();
    getDataUploadFileXML();
    var clsDis =  document.getElementsByClassName('div-dis-active');
    if(clsDis.length == 0) clearIntervalUploadFileXML();
    for (var i = 0; i < clsDis.length ; i++) 
    {
        undisDataXML(clsDis[i].getAttribute("name"))
    }
    $(".div-dl-up-f").unbind().on("click",function()
    {
        var id = $(this).attr('name'); 
        showNotiDeleteUploadFile(id);
    });
}
function undisDataXML(id)
{
    try
    {
        var data = _dtFile.filter(x=>x._id == id)[0];
        //Trạng thái cần sửa lỗi
        if(safeRead(data.Status,"ID")==2)
            addElementError(id,data);
        //Các trạng thái còn lại
        else
            addElementDisXML(id,data);
    }
    catch(ex){}
}
function addElementDisXML(id,data)
{
    $(".div-dis-name"+id).text(data.Status.Name).css("background-color",data.Status.Color);
    //Đã thực hiện
    if(data.Status.ID == 4 || data.Status.ID == -1 || data.Status.ID == -2 || data.Status.ID == -3) 
    {
        $(".div-dis-name"+id).removeClass("div-dis-active").text(data.Status.Name).css("background-color",data.Status.Color);
        $(".div-dis-dl-active"+id).addClass("div-dl-up-f c-p").removeClass("div-dis-2");
        $(".div-dl-up-f").unbind().on("click",function()
        {
            var id = $(this).attr('name'); 
            showNotiDeleteUploadFile(id);
        });
    }
}
function addElementError(id,data)
{
    $(".div-dis-name"+id).text(data.Status.Name).css("background-color",data.Status.Color).addClass("c-p").removeClass("div-dis-active");
    $(".div-dis-name"+id).append($('<i class="far fa-level-down-alt"></i>'));
    $(".div-dis-name"+id).attr({"data-bs-toggle":"collapse", "data-bs-target":"#collapse"+id , "aria-expanded":"true"});
    $(".div-dis-dl-active"+id).addClass("div-dl-up-f c-p").removeClass("div-dis-2");
    var parent_error = $('<div class="w-100" style="display:grid"/>') ;
    for(var x = 0 ; x<data.Errors.length;x++)
    {
        var parent_er_child_2 = $('<div class="w-100 p-d-b-2-5 f-l"><div class="w-50 f-l"><span class="text-at-break-line">'+data.Errors[x].Location+'</span></div><div class="w-50 f-l"><span class="text-at-break-line">'+data.Errors[x].Description+'</span></div></div>')
        parent_error.append(parent_er_child_2);
    }
    $("#collapse"+id).empty().append(parent_error);
    $(".div-dl-up-f").unbind().on("click",function()
    {
        var id = $(this).attr('name'); 
        showNotiDeleteUploadFile(id);
    });
}
function addElementManagementAgency(data,action)
{
    for(var i = 0 ; i < data.length ; i++)
    {
        var parent = $('<div class="text-ellipsis-n-w c-p div-lst-a-m-child w-100" name="'+data[i].HospitalId+'">'+data[i].HospitalName+'</div>')
        $(".div-lst-ma").append(parent);
    }
}
function addElementFilter()
{
      var parent          = $('<div class="div-gettested-content"/>');
      var child_2         = $('<div class="div-filter-content div-filter-content-upload" />');
      var child_2_child_0 = _dt_managementAgency.length > 2 ?$('<div class="w-20 f-l div-vcskcb"><label>Cơ sở KCB</label><div class="w-100 ip-cskcb s-p"><span class="sp-cskcb text-ellipsis-n-w w-100"></span><i class="far fa-angle-down f-r" style="color:black;margin-top:-19px"></i></div><div class="div-s-h-m-a h"><div><input class="ip-search-m-a w-100" placeholder="Tìm kiếm"/></div><div class="div-lst-ma" /></div></div></div>'):null;
      var child_2_child_1 = $('<div class="'+(_dt_managementAgency.length > 2 ? "w-30":"w-50")+' f-l"><label>Tên tập tin</label><input class="ip-name w-100 h-100" type="text" /></div>');
      var child_2_child_2 = $('<div class="w-40 f-l"><label>Thời gian tải lên</label><div class="w-100"><div class="w-50 f-l p-d-l-r-0-4"><input class="w-100 ip-formdate" type="date" /></div><div class="w-50 f-l p-d-l-r-0-4"><input class="w-100 ip-todate" type="date" /></div></div></div>');
      var child_2_child_3 = $('<div class="w-10 f-l" style="padding:0;'+(_dt_managementAgency.length < 2 ? "height: 54px ; background-color: rgba(153 , 153 , 153 , 0.64) ; border-right:solid 1px white":null)+'"><div class="w-50 f-l t-c div-filter-find c-p"><i class="far fa-search"></i></div><div class="w-50 f-l t-c c-p div-filter-reset"><i class="far fa-undo"></i></div></div>')
      child_2.append([child_2_child_0,child_2_child_1 , child_2_child_2,child_2_child_3]);
      parent.append([child_2]).appendTo($(".div-gridview"))
}
function addElementGridView()
{
    var parent  = $('<div class="div-upload-file-gw p-d-l-r-1"/>');
    var child_1 = $('<div class="w-100 c-w div-upl-title"/>');
    var child_2 = $('<div class="div-upl-data w-100" id="accordionParent"/>');
    var child_1_child_1 = $('<div class="w-26 f-l"><span>Tập tin</span></div>');
    var child_1_child_2 = $('<div class="w-14 f-l"><span>Kích thước (byte)</span></div>');
    var child_1_child_3 = $('<div class="w-20 f-l"><span>Bệnh viện thực hiện</span></div>');
    var child_1_child_4 = $('<div class="w-20 f-l t-c"><span>Ngày thực hiện</span></div>');
    var child_1_child_5 = $('<div class="w-16 f-l t-c"><span>Trạng thái</span></div>');
    var child_1_child_6 = $('<div class="w-4 f-l" />');
    parent.append([
        child_1.append([
            child_1_child_1,
            child_1_child_2,
            child_1_child_3,
            child_1_child_4,
            child_1_child_5,
            child_1_child_6
        ]),
        child_2
    ])
    parent.appendTo($(".div-gridview"));
}
function addElemmentprView()
{
    var parent = $('<div />');
    var child_1 = $('<div class="w-100 div-collapse"/>');
    var child_2 = $('<div class="div-upload" />');
    var child_3 = $('<div class="div-gridview" />');
    var child_1_child = $('<div class="w-5 h-100 f-r t-c" />');
    var child_2_child = $('<div class="div-upload_content w-100 h-100" />');
    var child_1_child_child = $('<div class="w-100 h-100 div-s-h-upload c-p"/>');
    var child_1_child_child_child = $('<i class="fas fa-arrow-circle-up"/>');
    var child_2_child_child = $('<div id="drop-area" />');
    var child_2_child_child_child = $('<form class="form_UploadDicom" />');
    var child_2_child_child_child_child_1 = $('<div class="form_UploadDicom_Top t-c"/>');
    var child_2_child_child_child_child_2 = $('<div class="form_UploadDicom_Center"><input type="file" id="fileElem" multiple  accept=".xml"/><label class="button" for="fileElem" style={{display:"block"}}></label></div>');
    var child_2_child_child_child_child_3 = $('<div class="form_UploadDicom_Bottom lineHeight1_2"><progress id="progress-bar" max="100" value="0" ></progress></div>');
    var child_2_child_child_child_child_1_child_1 = $('<div class="w-80 f-l"><div id="div-add-file-dicom"/></div>');
    var child_2_child_child_child_child_1_child_2 = $('<div class="w-20 f-r" />');
    var child_2_child_child_child_child_1_child_2_child_1 = $('<div class="f-r"><div class="f-l div-uploadfile t-c c-p" title="Tải lên"><i class="far fa-upload"></i></div><div class="f-l div_refreshfile c-p" title="Làm mới"><i class="fal fa-repeat-alt"></i></div></div>');
    var child_2_child_child_child_child_1_child_2_child_2 = $('<div class="f-r"><span class="p_choosefile"></span></div>');
    child_2_child_child_child_child_1.append([child_2_child_child_child_child_1_child_1,child_2_child_child_child_child_1_child_2.append([child_2_child_child_child_child_1_child_2_child_2,child_2_child_child_child_child_1_child_2_child_1])]);
    child_1.append(child_1_child.append(child_1_child_child.append(child_1_child_child_child)));
    child_2.append(child_2_child.append(child_2_child_child.append(child_2_child_child_child.append([child_2_child_child_child_child_1,child_2_child_child_child_child_2,child_2_child_child_child_child_3]))))
    parent.append([child_1,child_2,child_3]).appendTo($(".div-content-function"));
}
function addElementDataGridView(params)
{
    if(safeRead(params,"Items")!="" && params.Items.length>0)
    {
        var data = params.Items;
        for(var i=0;i<data.length;i++)
        {
            var name    =  data[i].FileName + "";
            var parent  = $('<div class="w-100"/>');
            var child_1 = $('<div class="w-100"/>');
            var child_2 = $('<div class="w-100 collapse div-s-er" id="collapse'+data[i]._id+'" data-bs-parent="#accordionParent"/>');
            var child_1_child_1 = $('<div class="w-26 text-ellipsis-n-w f-l" title="'+name.replace(".xml","")+'">'+name.replace(".xml","")+'</div>');
            var child_1_child_2 = $('<div class="w-14 text-ellipsis-n-w f-l">'+(Number(data[i].Size).toLocaleString('en')).replaceAll(",",".")+'</div>');
            var child_1_child_3 = $('<div class="w-20 text-ellipsis-n-w f-l">'+data[i].UserInsert.HospitalName+'</div>');
            var child_1_child_4 = $('<div class="w-20 text-ellipsis-n-w f-l t-c">'+formatDateTime(data[i].DateCreate)+'</div>');
            var child_1_child_5 = $('<div class="w-16 text-ellipsis-n-w f-l t-c div-dis-active div-dis-name'+data[i]._id+'" name="'+data[i]._id+'" style="background:'+data[i].Status.Color+'" >'+data[i].Status.Name+'</div>');
            var child_1_child_6 = $('<div class="w-4 f-l t-c c-w div-dis-2 div-dis-dl-active'+data[i]._id+'" name="'+data[i]._id+'"><i class="fal fa-times"></i></div>');
            if(data[i].Status.ID == 2 || data[i].Status.ID == 4 || data[i].Status.ID == -1 ||  data[i].Status.ID == -2) 
            { 
                child_1_child_6.addClass("div-dl-up-f c-p").removeClass("div-dis-2");
                child_1_child_5.removeClass("div-dis-active");
            }
            if(data[i].Status.ID == 2)
            {
                child_1_child_5.append($('<i class="far fa-level-down-alt"></i>')).addClass("c-p").removeClass("div-dis-active");
                child_1_child_5.attr({"data-bs-toggle":"collapse", "data-bs-target":"#collapse"+data[i]._id , "aria-expanded":"true"});
                var parent_error =  $('<div class="w-100" style="display:grid"/>');
                for(var x = 0 ; x<data[i].Errors.length;x++)
                {
                    var parent_error_child_2 = $('<div class="w-100 p-d-b-2-5"><div class="w-50 f-l"><span class="text-at-break-line">'+data[i].Errors[x].Location+'</span></div><div class="w-50 f-l"><span class="text-at-break-line">'+data[i].Errors[x].Description+'</span></div></div>')
                    parent_error.append(parent_error_child_2);
                }
                parent_error.appendTo(child_2);
            } 
            parent.append([
                child_1.append([
                    child_1_child_1,
                    child_1_child_2,
                    child_1_child_3,
                    child_1_child_4,
                    child_1_child_5,
                    child_1_child_6
                ]),
                child_2
            ])
            $(".div-upl-data").append(parent);
        }
    }
}
function addELementPagination()
{
    var parent = $('<div class="div-pagination w-100 t-c"/>');
    var child_2 = $('<div class="f-r div-pg-center"><div class="f-l"><a class="a-prev-first" href="#">«</a><a class="a-prev-pg" href="#"><</a></div><div class="f-l div-lst-pagination"></div><div class="f-l"><a class="a-next-pg" href="#">></a><a class="a-next-last" href="#">»</a></div></div>');
    parent.append([child_2]);
    parent.appendTo($(".div-gridview"));
}
function ElmFileUpload(elm,className)
{
    var parent = $('<div class="w-10 div-file-icon f-l c-p" name="'+(elm.name).replaceAll(/ |[.xml]/g,"")+'" title="'+(elm.name).replaceAll(/ |[.xml]/g,"")+'" />')
    var child_1 = $('<div name="div-rm-'+elm.name+'" id="div-rm-'+(elm.name).replaceAll(/ |[.xml]/g,"")+'" style="position:relative;right:-19px" />').unbind().on("click",rmFile);
    var child_2 = $('<div class="'+className+'" />');
    var child_3 = $('<div class="div-file-content">'+elm.name+'</div>');
    var child_1_child = $('<i id="i-rm-'+elm.name+'" class="far fa-times-circle c-p" />')
    child_1.append(child_1_child);
    parent.append([child_1,child_2,child_3]).appendTo($("#div-add-file-dicom"));
}
function performUploadFileXML()
{
    $(".div-content").unbind().on("click",function(){
        if(!$(".div-ft-ex-content").hasClass("h")) $(".div-ft-ex-content").addClass("h")
    })
    $(".div-filter-content").unbind().on("click",function(e){
        e.stopPropagation();
    })
    $(".div-filter-extend").unbind().on("click",function()
    {
        var child = $(this).children();
        var down  = "fa-chevron-down";
        var up    = "fa-chevron-up"
        if(child.hasClass(down)) child.removeClass(down).addClass(up);
        else child.removeClass(up).addClass(down);
        $(".div-ft-ex-content").toggleClass("h")
    })
    $(".ip-name:text:visible:first").focus();
    $(".div-s-h-upload").unbind().on("click",showHideUpload);
    $(".div-upl-data").height($(window).height()-$(".div-collapse").height()-$(".div-header").height()-$(".div-upload_content").height()-$(".div-filter-content").height()-105);
    $(".div-s-h-m-a").width($(".ip-cskcb").width()+3);
    $(".div-content").unbind('mouseup').mouseup(function(e) 
    {
        ToggleSelectFilter3("ip-cskcb , .div-s-h-m-a , .ip-search-m-a , .div-lst-ma , .sp-cskcb","div-s-h-m-a",e);
    });
    $(".div-lst-ma").css("max-height",_height_window/3)
    $(".div-lst-ma").scroll(function () {
        var scrollHeight = $(".div-lst-ma").prop("scrollHeight");
        if ($(".div-lst-ma").scrollTop() ==  Math.round(scrollHeight-($(".div-lst-ma").height()))) {
                fetchMoreDataMA();
                addElementManagementAgency(getManagementAgency(_filterMA),false);
                performSearchCSKCB();
        }
    });
    $(".ip-search-m-a").unbind().on("keyup",function(e){
        /*if(e.keyCode == 13) 
        {
            _filterMA.Find = $(this).val();
            _filterMA.PageNumber = 1;
            $(".div-lst-ma").empty();
            addElementManagementAgency(getManagementAgency(_filterMA),true);
            performSearchCSKCB();
        }*/
        clearTimeout(_searchMA);
        // Make a new timeout set to go off in 1000ms (1 second)
        _searchMA = setTimeout(function () {
            _filterMA.Find = $(".ip-search-m-a").val();
            _filterMA.PageNumber = 1;
            $(".div-lst-ma").empty();
            addElementManagementAgency(getManagementAgency(_filterMA),true);
            performSearchCSKCB();
        }, 800);
    })
    if(_dt_managementAgency.length > 0)
    {
        $(".sp-cskcb").attr("name",_dt_managementAgency[0].HospitalId).text(_dt_managementAgency[0].HospitalName);
    }
    /*$(".div-upl-data").scroll(function () {
        var scrollHeight = $(".div-upl-data").prop("scrollHeight");
        if ($(".div-upl-data").scrollTop() ==  Math.round(scrollHeight-($(".div-upl-data").height()))) {
                fetchMoreData();
                getDataUploadFileXML();
                addElementDataGridView(_dtFile);
        }
    });*/
    $(".ip-name").unbind().on("keyup",function(e){
        if(e.keyCode == 13)
            filterUploadFileXML();
    });
    $(".div-pg-next").unbind().on("click",function(){
        pagination("next");
    })
    $(".div-pg-per").unbind().on("click",function(){
        pagination("per");
    })
    $(".div-filter-find").unbind().on("click",filterUploadFileXML);
    $(".div-filter-reset").unbind().on("click",refeshFilterUploadFileXML);
    $(".ip-formdate , .ip-todate").unbind().on("change",filterUploadFileXML);
    $(".div-dl-up-f").unbind().on("click",function()
    {
        var id = $(this).attr('name'); 
        showNotiDeleteUploadFile(id);
    });
    localStorage.setItem("trActive", "");
    clearIntervalUploadFileXML();
    _resetStatus = setInterval(function(){
        reloadDataXML()
      },10000)
    UploadDicomFile();
    reloadDataXML();
    performSearchCSKCB();
    actionPagination(_dtFile.TotalPages);
    checkStatusPagination(_dtFile.TotalPages);
}
function checkStatusPagination(count)
{
    if(_pg_index.first<=1) $('.a-prev-pg , .a-prev-first').addClass("div-dis").unbind();
    if((count <= 5) || (_pg_index.last >= count)) 
        $('.a-next-pg , .a-next-last').addClass("div-dis").unbind();
    else 
    {
        $('.a-next-pg , .a-next-last').removeClass("div-dis");
        //performUploadXML();
    }
}
function actionPagination(count)
{
    addELementListPagination(count);
    $('.a-next-pg').unbind().on("click",function(){
        _pg_index.first +=5;
        _pg_index.last  +=5;
        addELementListPagination(count);
        $('.a-prev-pg').removeClass("div-dis");
        checkPrevPg(count);
        $(".a-prev-first").removeClass("div-dis");
        checkPrevFirst(count);
        if(_pg_index.last >= count)
        {
            $('.a-next-pg , .a-next-last').addClass("div-dis").unbind();
            return;
        }
    })
    if(_pg_index.last>5) checkPrevPg(count);
    checkNextLast(count);
    checkPrevFirst(count);
}
function checkPrevPg(count)
{
    $('.a-prev-pg').unbind().on("click",function() {
        _pg_index.first -=5;
        _pg_index.last  -=5;
        if(_pg_index.first<1)
        {
            _pg_index.first = 1;
            _pg_index.last = 5;
        }
        addELementListPagination(count);
        if(_pg_index.first <= 1)
        {
            $('.a-prev-pg , .a-prev-first').addClass("div-dis").unbind();
        }
        if(_pg_index.last > 5 || count>5) 
        {
            $('.a-next-pg , .a-next-last').removeClass("div-dis");
            actionPagination(count);
        }
        localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
    })
}
function checkPrevFirst(count)
{
    $(".a-prev-first").unbind().on("click",function(){
        if(count >=5)
        {
            _pg_index.first = 1;
            _pg_index.last  = 5;
            $('.a-next-pg , .a-next-last').removeClass("div-dis");
            checkNextLast();
        }
        else
        {
            _pg_index.first = 1;
            _pg_index.last  = count;
        }
        addELementListPagination(count);
        actionPagination(count);
        $('.a-prev-pg , .a-prev-first').addClass("div-dis").unbind();
        localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
    })
}
function checkNextLast(count)
{
    $(".a-next-last").unbind().on("click",function(){
        if(count >5)
        {
            _pg_index.first = count - 4;
            _pg_index.last  = count;
            $('.a-prev-pg , .a-prev-first').removeClass("div-dis");
            checkPrevPg(count);
            checkPrevFirst(count);
        }
        else
        {
            _pg_index.first = 1;
            _pg_index.last  = count;
            $('.a-prev-pg').addClass("div-dis").unbind();
        }
        addELementListPagination(count);
        $('.a-next-pg , .a-next-last').addClass("div-dis").unbind();
        localStorage.setItem("pgIndex", JSON.stringify(_pg_index));
    })
}
function performSearchCSKCB()
{
    $(".div-lst-a-m-child").unbind().on("click",function(){
        var val = $(this).text();
        var name = $(this).attr("name");
        $(".sp-cskcb").attr("name",name).text(val);
        filterUploadFileXML();
        /*if(getKeyFilter() == "")
        {
            resetFilter();
            return;
        }
        resetPagination();
        filterUploadXML(true);*/
    })
}
function clearIntervalUploadFileXML()
{
    clearInterval(_resetStatus);
}
function fetchMoreData()
{
    _filterFile.PageNumber += 1;
}
function pagination(action)
{
    var pg = _filterFile.PageNumber;
    if(action=="next")
        _filterFile.PageNumber += 1;
    else if(_filterFile.PageNumber > 1)
        _filterFile.PageNumber -= 1;
    else
        return ;
    getDataUploadFileXML();
    if(_dtFile.length>0)
    {
        $(".sp-pg").text(_filterFile.PageNumber);
        $(".div-upl-data").empty();
        addElementDataGridView(_dtFile);
        if(_filterFile.PageNumber==1)
            $(".div-pg-per").addClass("div-dis");
        else
            $(".div-pg-per").removeClass("div-dis");
    }
    else
        _filterFile.PageNumber = pg
}
function showHideUpload()
{   var h = $(".div-upl-data").height();
    //Show
    if($(".div-upload").hasClass("h")) 
    {
        $(".div-upload").removeClass("h");
        h -= 125;
    }
    //Hide
    else 
    {
        $(".div-upload").addClass("h");
        h += 125;
    }
    $(".div-upl-data").height(h);
}
function UploadDicomFile()
{
     $("#div-upload").click(function(event)
     {
         if($("#drop-area").has(event.target).length==0 && !$("#drop-area").is(event.target))
         {
            $("#div-upload").css("display","none");
            ResetUploadFile();
         }
     })
     //Thực hiện upload file
     $(".div-uploadfile").unbind().on("click",function()
     {
        progressBar.style.backgroundColor="";
        handleFiles();
       
     });
     //Reset upload file dicom
     $(".div_refreshfile").click(function()
     {
        ResetUploadFile();
     });
     //Nhận file từ input khi upload file dicom
     $("#fileElem").on("click",function()
     {
        this.value=null;
     });
     $("#fileElem").on('change',function(e)
     {
        readURL(this);
        AppendFileUpload(e.target.files);
    });
     progressBar = document.getElementById('progress-bar');
     var dropArea = document.getElementById('drop-area');
     ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
       dropArea.addEventListener(eventName, preventDefaults, false)
     })
     ;['dragenter', 'dragover'].forEach(eventName => {
       dropArea.addEventListener(eventName, highlight, false)
     })
     
     ;['dragleave', 'drop'].forEach(eventName => {
       dropArea.addEventListener(eventName, unhighlight, false)
     })
     dropArea.addEventListener('drop', handleDrop, false) 
}
//Hiệu ứng vùng Drag and drop 
function preventDefaults (e) {
    e.preventDefault()
    e.stopPropagation()
  }
function handleFiles() {
    if(_file_upload.length<1 )
        notification.notifaction("Vui lòng chọn file trước khi upload.");
    else{
        _textFileFail=_textFileFailReset;
        _list_file_error=[];
        _list_show_error=[]
        initializeProgress(_file_upload.length); // <- Add this line
        uploadFile();
    }
}
function readURL(input) {
    for(var i=0;i<input.files.length;i++)
    {
        if((input.files[i].type).indexOf("image")>-1)
        {
            let name=input.files[i].name;
            var reader = new FileReader();
            reader.onload = function (e) {
                if((_list_image_dicom.filter(x=>x.props.children[1].props.src==e.target.result)).length==0)
                    _list_image_dicom.push(<div style={{padding:"4%"}} id={"div-"+name} className="f-l">
                                                <div className="img-dicom-close"><i id={"i-"+name} className="shis-b-cancel img-dicom-close-hover cur"/></div>
                                                <img src={e.target.result } id={name} className="img-upload-dicom"/>
                                            </div>);
            };
            reader.readAsDataURL(input.files[i]);
        }
    }
}
//Kiểm tra trùng file dicom
async function AppendFileUpload(data)
{
    $("#div-add-file-dicom").empty();
    _list_file=[];
    data=[...data];
    if(_file_upload.length>0)
    {
        data.forEach(function(elm){
            if(_file_upload.findIndex(x=>x.name==elm.name) < 0 && elm.name.toLowerCase().indexOf(".xml")>-1)
                pushFileUpload(elm);
        });
    }
    else{
        data.forEach(function(elm){
            if(elm.name.toLowerCase().indexOf(".xml")>-1)
                pushFileUpload(elm);
        })
    }
    _file_upload.forEach(function(elm){ 
        if(elm.name.toLowerCase().indexOf(".xml")>-1)
        {
            _list_file.push(
                ElmFileUpload(elm,"div-file-dcm")
            );
        }
    });
    $(".p_choosefile").text("("+_file_upload.length+")");
}
function pushFileUpload(elm)
{
    _file_upload.push(elm);
}
function handleDrop(e) {
    let dt = e.dataTransfer
    var data=dt.files;
    readURL(dt);
    AppendFileUpload(data);
}
function initializeProgress(numFiles) {
    progressBar.value = 0;
    _uploadProgress = [];
    for(let i = numFiles; i > 0; i--) {
        _uploadProgress.push(0)
    }
}
function uploadFile() {
    var fdata = new FormData();
    for (var i = 0; i < _file_upload.length; i++) {
        fdata.append("files", _file_upload[i]);
    };
    var result = SendDataFile("api/File/Multiple","post",fdata)
    if(result == true)
    {
        UploadSuccess();
        notification.notifaction("Thành công .");
        clearIntervalUploadFileXML();
        _resetStatus = setInterval(function(){
            reloadDataXML()
        },10000)
    }
    else notification.notifaction(result);
}
function UploadSuccess()
{
    _file_upload.length = 0;
    $("#div-add-file-dicom").empty();
    $(".p_choosefile").text("");
    refeshFilterUploadFileXML();
}
function filterUploadFileXML()
{
    $('.div-loading').show();
    $(".div-upl-data").empty();
    _filterFile.FileName = $('.ip-name').val();
    _filterFile.From = $('.ip-formdate').val();
    _filterFile.To = $('.ip-todate').val() + " 23:59:59";
    _filterFile.RowsPerPage = 20;
    _filterFile.HospitalId = $(".sp-cskcb").attr("name")==undefined ? _filterFile.HospitalId : $(".sp-cskcb").attr("name");
    if(_crHstId != _filterFile.HospitalId)
    {
        _filterFile.PageNumber = 1;
    }
    $.ajax({
        url: config.host.fhir_api + "api/File/GetList",
        type: "get",
        data: _filterFile,
        headers:{
            'Authorization': 'bearer ' + _cookies.get('access_Token'),
            "Accept": "application/json",
            "content-type": "application/json; charset=utf-8"
        },
        traditional: true,
        dataType: 'json',
        timeout: 5000,
        success: function (data) {
            addElementDataGridView(data);
            if(_crHstId != _filterFile.HospitalId)
            {
                _crHstId = _filterFile.HospitalId;
                _pg_index.first = 1;
                _pg_index.last  = 5; 
                actionPagination(data.TotalPages);
                checkStatusPagination(data.TotalPages);
            }
        }.bind(this),
        complete: function() {
            $('.div-loading').hide();
        },
        error: function (request, error) {
          if(request.status == "401")
            notification.notifactionF2("Vui lòng đăng nhập lại .",logout)
        }
    });
    $(".div-dl-up-f").unbind().on("click",function()
    {
        var id = $(this).attr('name'); 
        showNotiDeleteUploadFile(id);
    });
}
function fetchMoreDataMA()
{
    _filterMA.PageNumber += 1;
}
function refeshFilterUploadFileXML()
{
    $(".div-loading").show();
    $(".div-upl-data").empty();
    $(".ip-name").val("");
    $(".ip-formdate").val("");
    $(".ip-todate").val("");
    $(".sp-cskcb").attr("name",_dt_managementAgency[0].HospitalId).text(_dt_managementAgency[0].HospitalName);
    resetFilterUploadFileXML();
    $.ajax({
        url: config.host.fhir_api + "api/File/GetList",
        type: "get",
        data: _filterFile,
        headers:{
            'Authorization': 'bearer ' + _cookies.get('access_Token'),
            "Accept": "application/json",
            "content-type": "application/json; charset=utf-8"
        },
        traditional: true,
        dataType: 'json',
        timeout: 5000,
        success: function (data) {
            addElementDataGridView(data);
            performUploadFileXML();
        }.bind(this),
        complete: function() {
            $('.div-loading').hide();
        },
        error: function (request, error) {
          if(request.status == "401")
            notification.notifactionF2("Vui lòng đăng nhập lại .",logout)
        }
    });
   
}
function resetFilterUploadFileXML()
{

    _filterFile.From        = null;//formatDate2(new Date());
    _filterFile.To          = null;//formatDate2(new Date()) + " 23:59:59";
    _filterFile.FileName    = null;
    _filterFile.RowsPerPage = 20;
    _filterFile.PageNumber  = 1;
    _filterFile.HospitalId  = _dt_managementAgency.length> 0 ? _dt_managementAgency[0].HospitalId : null;
    _pg_index.first = 1;
    _pg_index.last  = 5;
    $(".sp-pg").text(_filterFile.PageNumber);
    _crHstId = _filterFile.HospitalId;
    actionPagination(safeRead(_dtFile,"TotalPages"));
    checkStatusPagination(safeRead(_dtFile,"TotalPages"));

}
//Reset Upload
function ResetUploadFile()
{
    _list_image_dicom=[];
    _file_upload=[];
    progressBar.value = 0;
    progressBar.style.backgroundColor="";
    _textFileFail=_textFileFailReset;
    $("#div-add-file-dicom").empty();
    $(".p_choosefile").text(""); 
    $(".form_UploadDicom_Center").css("visibility","inherit");
}
function rmFile()
{
    $(this).parent().css("display","none");
    var id_img=$(this).attr("name").slice(7,$(this).attr("name").length);
    _file_upload = _file_upload.filter(x=>x.name!=id_img);
    var lng = "("+ _file_upload.length +")";
    if(lng == 0) lng = "";
    $(".p_choosefile").text(lng); 
    try
    {
        var id = $(this).attr("id").replace("-rm","");
        $("#"+id).remove();
    }
    catch(ex){}
}
function showNotiDeleteUploadFile(id)
{
    _idDelete = id;
    notification.requestconfirm("Bạn có chắc chắn muốn xóa ?",deleteUploadFileXML);
}
function deleteUploadFileXML()
{
    var result = SendData("api/File?_id="+_idDelete,"delete",null);
    if(result == true) 
    {
        notification.notifaction("Thành công. ");
        refeshFilterUploadFileXML();
    }
    else notification.notifaction("Đã xảy ra lỗi. Vui lòng thử lại");
}
export { addElementUploadFileXML , performUploadFileXML , getDataUploadFileXML , getDataManagementAgencyUploadFile , resetFilterUploadFileXML , turnoffSetIntervalUploadFileXML , clearIntervalUploadFileXML ,addELementPagination}