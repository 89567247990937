import $ from 'jquery';
import { _height_window } from '../../../Variable/defaultvar';
import { checkErrorLogin } from '../ui/ui_fc';
import { md5, SendDataJsonString, shortCutImplemented, validateEmail } from '../../../Function/ui';
import { notification } from '../../Notification/ui_fc';
import { returnLogin } from '../ConfirmEmail/ui_fc';
//Giao diện trang login
function addElementChangePassword()
{
    var parent = $('<div class="w-100 h-100"/>');
    var child_1 = $('<div class="div-login w-100 h-80" />');
    var child_1_child_2 = $('<div class="w-100 div-title-login t-c" />');
    var child_1_child_3 = $('<div class="div-login-form" />');
    var child_1_child_5 = $('<div class="w-100" />');
    var child_1_child_2_child_1 = $('<p>Thay đổi mật khẩu</p>');
    var child_1_child_2_child_2 = $('<p></p>');
    var child_1_child_3_child_1 = $('<div class="holder"><div class="input-holder"><input class="input ip-email" type="text" placeholder=" " disabled/><div class="placeholder">Email / Mã BV</div></div></div>');
    var child_1_child_3_child_2 = $('<div class="div-err-login ip-email-err"><p /></div>');
    var child_1_child_3_child_3 = $('<div class="holder"><div class="input-holder"><input style="padding:13px 53px 13px 15px" class="input ip-pw" type="password" placeholder=" " /><div class="placeholder">Mật khẩu cũ</div><div class="c-p div-sh-pw"><i class="fal fa-eye"></i></div></div></div>');
    var child_1_child_3_child_4 = $('<div class="div-err-login ip-pw-err"><p /></div>');
    var child_1_child_3_child_5 = $('<div class="holder"><div class="input-holder"><input style="padding:13px 53px 13px 15px" class="input ip-newpw" type="password" placeholder=" " /><div class="placeholder">Mật khẩu mới</div><div class="c-p div-sh-newpw"><i class="fal fa-eye"></i></div></div></div>');
    var child_1_child_3_child_6 = $('<div class="div-err-login ip-newpw-err"><p /></div>');
    var child_1_child_5_child_1 = $('<div class="t-c c-w div-btn-change-pw c-p"><p style="color:white;margin:14px 0">Cập nhật</p></div>');
    var child_1_child_5_child_2 = $('<div class="f-l c-p f-w-600 div-create-account"><span>Đăng nhập</span></div>');
    parent.append([
        child_1.append([
            child_1_child_2.append([
                child_1_child_2_child_1,
                child_1_child_2_child_2
            ]),
            child_1_child_3.append([
                child_1_child_3_child_1,
                child_1_child_3_child_2,
                child_1_child_3_child_3,
                child_1_child_3_child_4,
                child_1_child_3_child_5,
                child_1_child_3_child_6
            ]),
            child_1_child_5.append([
                child_1_child_5_child_1,
                child_1_child_5_child_2
            ])
        ]),
        
    ]);
    parent.appendTo($(".div-content-login"));
}
function performJQChangePassword() 
{
    var url = new URL(window.location.href);
    shortCutImplemented("enter",changePassword);
    $(".div-f-login-df").css("height",$(window).height() - 120 )
    $(".div-info-user , .div-setting").remove();
    $(".div-header .w-35").removeClass("w-35").addClass("w-45");
    $(".div-sh-pw").unbind().on("click",function()
    {
        if($(".div-sh-pw").children().hasClass("fa-eye"))
        {
            $(".div-sh-pw").children().removeClass("fa-eye").addClass("fa-eye-slash");
            $(".ip-pw").attr("type", 'text');
        }
        else
        {
            $(".div-sh-pw").children().removeClass("fa-eye-slash").addClass("fa-eye");
            $(".ip-pw").attr("type", 'password');
        }
    })
    $(".div-sh-newpw").unbind().on("click",function()
    {
        if($(".div-sh-newpw").children().hasClass("fa-eye"))
        {
            $(".div-sh-newpw").children().removeClass("fa-eye").addClass("fa-eye-slash");
            $(".ip-newpw").attr("type", 'text');
        }
        else
        {
            $(".div-sh-newpw").children().removeClass("fa-eye-slash").addClass("fa-eye");
            $(".ip-newpw").attr("type", 'password');
        }
    })
    $(".div-btn-change-pw").unbind().on("click",changePassword);
    $("input:text:visible:first").focus();
    $(".div-create-account").unbind().on("click",returnLogin);
    $(".ip-email").val(url.searchParams.get("hosID"))
    $(".div-content-login").unbind().on("click", function(){shortCutImplemented("enter",changePassword);})
    //$(".div-create-account").unbind().on("click", function(){actionLogin(2)})
    //$(".p-fg-pw").unbind().on("click",fogotPassword)
}
function changePassword()
{
    if(!checkChangePassword()) return;
    var data = {};
    if(validateEmail($(".ip-email").val())) data.email = $(".ip-email").val();
    else data.HospitalId = $(".ip-email").val();
    data.NewPassword     = md5(md5($(".ip-newpw").val()));
    data.CurrentPassword = md5(md5($(".ip-pw").val()));
    var result = SendDataJsonString("api/Account/ChangePassword","post",data);
    if(result[0] == true) notification.notifactionF2("Thành công . Vui lòng đăng nhập lại",returnLogin)
    else notification.notifaction("Không thành công .");
}
function checkChangePassword()
{
    var dataField = ["email","pw","newpw"] , action = true;
    for(var i=0;i<dataField.length;i++)
    {
        if(!checkErrorLogin(dataField[i],$(".ip-"+dataField[i]).val(),dataField[i]))
          action = false;
    }
    return action;
}
export {addElementChangePassword,performJQChangePassword}