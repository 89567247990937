import $ from 'jquery'
import Cookies from 'universal-cookie';
import config from '../../config';
import { logout } from '../UI/DefaultInterface/Header/ui_fc';
import { notification } from '../UI/Notification/ui_fc';
import { _cookies } from '../Variable/defaultvar';
//Hàm thực hiện phím tắt
function shortCutImplemented(action, function_s) {
  $(document).keydown(function (event) {
    switch (action) {
      case "save":
        {
          var currKey = 0, e = e || event; currKey = e.keyCode || e.which || e.charCode;
          if (!(String.fromCharCode(event.which).toLowerCase() == 's' && event.ctrlKey) && !(event.which == 19))
            return true;
          event.preventDefault();
          function_s();
          break;
        }
      case "enter":
        {
          if (event.which == 13) {
            function_s();
            return;
          }
          break;
        }
    }
  });
}
function SendData(api, type, data) {
  const _cookiess = new Cookies();
  var dataRequest = [];
  $.ajax({
    url: config.host.fhir_api + api,
    type: type,
    data: data,
    headers: {
      'Authorization': 'bearer ' + _cookiess.get('access_Token'),
      "Accept": "application/json",
      "content-type": "application/json; charset=utf-8"
    },
    traditional: true,
    async: false,
    dataType: 'json',
    success: function (data) {
      dataRequest = data;
      if (data == undefined) dataRequest = []
    }.bind(this),
    timeout: 5000,
    error: function (request, error) {
      dataRequest = [];
      if (request.status == "401") {
        notification.notifactionF2("Vui lòng đăng nhập lại .", logout)
      }
    }
  });
  return dataRequest
}
function SendDataNoAsync(api, type, data) {
  var dataRequest = [];
  $.ajax({
    url: config.host.fhir_api + api,
    type: type,
    data: data,
    headers: {
      'Authorization': 'bearer ' + _cookies.get('access_Token'),
      "Accept": "application/json",
      "content-type": "application/json; charset=utf-8"
    },
    traditional: true,
    dataType: 'json',
    success: function (data) {
      dataRequest = data;
      if (data == undefined) dataRequest = []
    }.bind(this),
    timeout: 5000,
    error: function (request, error) {
      dataRequest = [];
      if (request.status == "401")
        notification.notifactionF2("Vui lòng đăng nhập lại .", logout)
    }
  });
  return dataRequest
}
function SendDataCreateAccount(api, type, data) {
  var dataRequest = null;
  $.ajax({
    url: config.host.fhir_api + api,
    type: type,
    data: JSON.stringify(data),
    headers: {
      'Authorization': 'bearer ' + _cookies.get('access_Token'),
      "Accept": "application/json",
      "content-type": "application/json; charset=utf-8"
    },
    traditional: true,
    async: false,
    dataType: 'json',
    success: function (data) {
      dataRequest = data;
    }.bind(this),
    complete: function (xhr) {
      dataRequest = xhr.status;
      if (xhr.status != 200)
        dataRequest = xhr.responseText;
    },
    timeout: 5000,
  });
  return dataRequest
}
function SendDataHFIR(api, type, data) {
  var dataRequest;
  $.ajax({
    url: config.host.h_fhir_api + api,
    type: type,
    data: data,
    headers: {
      "Accept": "application/json",
      "content-type": "application/fhir+xml;charset=utf-8",
      'Authorization': 'bearer ' + _cookies.get('access_Token'),
    },
    traditional: true,
    async: false,
    dataType: 'json',
    success: function (data) {
      dataRequest = data;
    }.bind(this),
    timeout: 5000,
    error: function (request, error) {
      dataRequest = [];
      if (request.status == "401")
        notification.notifactionF2("Vui lòng đăng nhập lại .", logout)
    }
  });
  return dataRequest
}
function SendDataNoHost(api, type, data) {
  var dataRequest;
  $.ajax({
    url: api,
    type: type,
    data: data,
    headers: {
      "Accept": "application/json",
      "content-type": "application/fhir+xml;charset=utf-8",
      'Authorization': 'bearer ' + _cookies.get('access_Token'),
    },
    traditional: true,
    async: false,
    dataType: 'json',
    success: function (data) {
      dataRequest = data;
    }.bind(this),
    timeout: 5000,
    error: function (request, error) {
      dataRequest = [];
      if (request.status == "401")
        notification.notifactionF2("Vui lòng đăng nhập lại .", logout)
    }
  });
  return dataRequest
}
function SendDataFile(api, type, data) {
  var dataRequest;
  $.ajax({
    url: config.host.fhir_api + api,
    type: type,
    data: data,
    beforeSend: function (xhr) {
      xhr.setRequestHeader("Authorization", 'bearer ' + _cookies.get('access_Token'))
    },
    contentType: false,
    processData: false,
    async: false,
    success: function (data) {
      dataRequest = true;
    }.bind(this),
    timeout: 5000,
    error: function (request) {
      dataRequest = request.responseText;
    }
  });
  return dataRequest
}
function formatDay(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || d == null || d == "") return "";
  var day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1);
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month].join('/')
}
function formatDate(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || d == null || d == "") return "";
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join('/')
}
function formatDate2(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || d == null || d == "") return "";
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-')
}
function formatTime(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || date == null || date == "") return "";
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [hour, min].join(":")
}
function formatDateTime(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || d == null || d == "") return "";
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join('/') + " " + [hour, min].join(":")
}
function formatDateTimeName(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || d == null || d == "") return "";
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join('') + [hour, min].join("")
}
function formatDateTime2(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || d == null || d == "") return "";
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [hour, min].join(":") + " " + [day, month, year].join('/');
}
function formatTimeDate(date) {
  var d = new Date(date);
  if (d == "Invalid Date" || d == null || d == "") return "";
  var month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    min = d.getMinutes();

  if (hour < 10) hour = "0" + hour;
  if (min < 10) min = "0" + min;
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [hour, min].join(":") + " " + [day, month, year].join('/')
}
function formatGender(gender) {
  if (gender == 1) return "Nam";
  else if (gender == 2) return "Nữ";
  else return "Không xác định"
}

function calculateAge(birthday) {
  if (birthday == null || birthday == "") return "";
  // birthday is a date
  var bd = new Date(birthday);
  var ageDifMs = Date.now() - bd.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function ShowBirthday(birthday) {
  if (birthday == null || birthday == "") return "";
  // birthday is a date
  var bd = new Date(birthday);
  return bd.getFullYear();
}

function SendDataJsonString(api, type, data) {
  var dataRequest, errorRequest;
  $.ajax({
    url: config.host.fhir_api + api,
    type: type,
    data: JSON.stringify(data),
    headers: {
      'Authorization': 'bearer ' + _cookies.get('access_Token'),
      "Accept": "application/json",
      "content-type": "application/json; charset=utf-8"
    },
    traditional: true,
    async: false,
    dataType: 'json',
    success: function (data) {
      dataRequest = data;
    }.bind(this),
    timeout: 5000,
    error: function (request, error) {
      //if(request.status==401) logOut();
      dataRequest = [];
      errorRequest = request.responseText;
    }
  });
  return [dataRequest, errorRequest]
}
function SendDataJsonStringAwait(api, type, data) {
  return $.ajax({
    url: config.host.fhir_api + api,
    type: type,
    data: JSON.stringify(data),
    headers: {
      'Authorization': 'bearer ' + _cookies.get('access_Token'),
      "Accept": "application/json",
      "content-type": "application/json; charset=utf-8"
    },
    dataType: 'json',
  });
}
function validateEmail(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}
//Hash MD5
function md5cycle(x, k) {
  var a = x[0], b = x[1], c = x[2], d = x[3];

  a = ff(a, b, c, d, k[0], 7, -680876936);
  d = ff(d, a, b, c, k[1], 12, -389564586);
  c = ff(c, d, a, b, k[2], 17, 606105819);
  b = ff(b, c, d, a, k[3], 22, -1044525330);
  a = ff(a, b, c, d, k[4], 7, -176418897);
  d = ff(d, a, b, c, k[5], 12, 1200080426);
  c = ff(c, d, a, b, k[6], 17, -1473231341);
  b = ff(b, c, d, a, k[7], 22, -45705983);
  a = ff(a, b, c, d, k[8], 7, 1770035416);
  d = ff(d, a, b, c, k[9], 12, -1958414417);
  c = ff(c, d, a, b, k[10], 17, -42063);
  b = ff(b, c, d, a, k[11], 22, -1990404162);
  a = ff(a, b, c, d, k[12], 7, 1804603682);
  d = ff(d, a, b, c, k[13], 12, -40341101);
  c = ff(c, d, a, b, k[14], 17, -1502002290);
  b = ff(b, c, d, a, k[15], 22, 1236535329);

  a = gg(a, b, c, d, k[1], 5, -165796510);
  d = gg(d, a, b, c, k[6], 9, -1069501632);
  c = gg(c, d, a, b, k[11], 14, 643717713);
  b = gg(b, c, d, a, k[0], 20, -373897302);
  a = gg(a, b, c, d, k[5], 5, -701558691);
  d = gg(d, a, b, c, k[10], 9, 38016083);
  c = gg(c, d, a, b, k[15], 14, -660478335);
  b = gg(b, c, d, a, k[4], 20, -405537848);
  a = gg(a, b, c, d, k[9], 5, 568446438);
  d = gg(d, a, b, c, k[14], 9, -1019803690);
  c = gg(c, d, a, b, k[3], 14, -187363961);
  b = gg(b, c, d, a, k[8], 20, 1163531501);
  a = gg(a, b, c, d, k[13], 5, -1444681467);
  d = gg(d, a, b, c, k[2], 9, -51403784);
  c = gg(c, d, a, b, k[7], 14, 1735328473);
  b = gg(b, c, d, a, k[12], 20, -1926607734);

  a = hh(a, b, c, d, k[5], 4, -378558);
  d = hh(d, a, b, c, k[8], 11, -2022574463);
  c = hh(c, d, a, b, k[11], 16, 1839030562);
  b = hh(b, c, d, a, k[14], 23, -35309556);
  a = hh(a, b, c, d, k[1], 4, -1530992060);
  d = hh(d, a, b, c, k[4], 11, 1272893353);
  c = hh(c, d, a, b, k[7], 16, -155497632);
  b = hh(b, c, d, a, k[10], 23, -1094730640);
  a = hh(a, b, c, d, k[13], 4, 681279174);
  d = hh(d, a, b, c, k[0], 11, -358537222);
  c = hh(c, d, a, b, k[3], 16, -722521979);
  b = hh(b, c, d, a, k[6], 23, 76029189);
  a = hh(a, b, c, d, k[9], 4, -640364487);
  d = hh(d, a, b, c, k[12], 11, -421815835);
  c = hh(c, d, a, b, k[15], 16, 530742520);
  b = hh(b, c, d, a, k[2], 23, -995338651);

  a = ii(a, b, c, d, k[0], 6, -198630844);
  d = ii(d, a, b, c, k[7], 10, 1126891415);
  c = ii(c, d, a, b, k[14], 15, -1416354905);
  b = ii(b, c, d, a, k[5], 21, -57434055);
  a = ii(a, b, c, d, k[12], 6, 1700485571);
  d = ii(d, a, b, c, k[3], 10, -1894986606);
  c = ii(c, d, a, b, k[10], 15, -1051523);
  b = ii(b, c, d, a, k[1], 21, -2054922799);
  a = ii(a, b, c, d, k[8], 6, 1873313359);
  d = ii(d, a, b, c, k[15], 10, -30611744);
  c = ii(c, d, a, b, k[6], 15, -1560198380);
  b = ii(b, c, d, a, k[13], 21, 1309151649);
  a = ii(a, b, c, d, k[4], 6, -145523070);
  d = ii(d, a, b, c, k[11], 10, -1120210379);
  c = ii(c, d, a, b, k[2], 15, 718787259);
  b = ii(b, c, d, a, k[9], 21, -343485551);

  x[0] = add32(a, x[0]);
  x[1] = add32(b, x[1]);
  x[2] = add32(c, x[2]);
  x[3] = add32(d, x[3]);

}

function cmn(q, a, b, x, s, t) {
  a = add32(add32(a, q), add32(x, t));
  return add32((a << s) | (a >>> (32 - s)), b);
}

function ff(a, b, c, d, x, s, t) {
  return cmn((b & c) | ((~b) & d), a, b, x, s, t);
}

function gg(a, b, c, d, x, s, t) {
  return cmn((b & d) | (c & (~d)), a, b, x, s, t);
}

function hh(a, b, c, d, x, s, t) {
  return cmn(b ^ c ^ d, a, b, x, s, t);
}

function ii(a, b, c, d, x, s, t) {
  return cmn(c ^ (b | (~d)), a, b, x, s, t);
}

function md51(s) {
  var txt = '';
  var n = s.length,
    state = [1732584193, -271733879, -1732584194, 271733878], i;
  for (i = 64; i <= s.length; i += 64) {
    md5cycle(state, md5blk(s.substring(i - 64, i)));
  }
  s = s.substring(i - 64);
  var tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (i = 0; i < s.length; i++)
    tail[i >> 2] |= s.charCodeAt(i) << ((i % 4) << 3);
  tail[i >> 2] |= 0x80 << ((i % 4) << 3);
  if (i > 55) {
    md5cycle(state, tail);
    for (i = 0; i < 16; i++) tail[i] = 0;
  }
  tail[14] = n * 8;
  md5cycle(state, tail);
  return state;
}

/* there needs to be support for Unicode here,
 * unless we pretend that we can redefine the MD-5
 * algorithm for multi-byte characters (perhaps
 * by adding every four 16-bit characters and
 * shortening the sum to 32 bits). Otherwise
 * I suggest performing MD-5 as if every character
 * was two bytes--e.g., 0040 0025 = @%--but then
 * how will an ordinary MD-5 sum be matched?
 * There is no way to standardize text to something
 * like UTF-8 before transformation; speed cost is
 * utterly prohibitive. The JavaScript standard
 * itself needs to look at this: it should start
 * providing access to strings as preformed UTF-8
 * 8-bit unsigned value arrays.
 */
function md5blk(s) { /* I figured global was faster.   */
  var md5blks = [], i; /* Andy King said do it this way. */
  for (i = 0; i < 64; i += 4) {
    md5blks[i >> 2] = s.charCodeAt(i)
      + (s.charCodeAt(i + 1) << 8)
      + (s.charCodeAt(i + 2) << 16)
      + (s.charCodeAt(i + 3) << 24);
  }
  return md5blks;
}

var hex_chr = '0123456789abcdef'.split('');

function rhex(n) {
  var s = '', j = 0;
  for (; j < 4; j++)
    s += hex_chr[(n >> (j * 8 + 4)) & 0x0F] + hex_chr[(n >> (j * 8)) & 0x0F];
  return s;
}

function hex(x) {
  for (var i = 0; i < x.length; i++)
    x[i] = rhex(x[i]);
  return x.join('');
}

function md5(s) {
  return hex(md51(s));
}

/* this function is much faster,
so if possible we use it. Some IEs
are the only ones I know of that
need the idiotic second function,
generated by an if clause.  */

function add32(a, b) {
  return (a + b) & 0xFFFFFFFF;
}

if (md5('hello') != '5d41402abc4b2a76b9719d911017c592') {
  function add32(x, y) {
    var lsw = (x & 0xFFFF) + (y & 0xFFFF),
      msw = (x >> 16) + (y >> 16) + (lsw >> 16);
    return (msw << 16) | (lsw & 0xFFFF);
  }
}
var safeRead = function (obj, strfield) {
  var current, prop, props, val, _i, _len;
  //obj = arguments[0], props = 2 <= arguments.length ? [].slice.call(arguments, 1) : [];
  props = strfield.split('.'); // cat[0].coding[0];

  var read = function (obj, prop) {
    if ((obj != null ? obj[prop] : void 0) == null) {
      return;
    }
    return obj[prop];
  };

  current = obj;
  for (_i = 0, _len = props.length; _i < _len; _i++) {
    prop = props[_i];

    if (val = read(current, prop)) {
      current = val;
    } else {
      return '';
    }
  }
  return current;
};
function safeReadCategori(obj) {
  if (obj["category"] != undefined) {
    if (typeof obj["category"] === "object")
      return safeRead(obj.category, "text");
    else if (Array.isArray(obj["category"]))
      return safeRead(obj.category[0], "text")
  }
  return '';
};
function safeReadKB(obj, index) {
  if (obj[index] != null)
    return obj[index].Id;
  return ""
};
function safeReadValueXNCLS(obj, param) {
  return safeRead(obj, param);
}
function randomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getIconGender(gender) {
  var obj = {};
  //Nam
  if (gender == 1 || gender == "Nam") {
    obj.font = "fa-mars";
    obj.color = "#4799F3"
  }
  //Nữ
  else if (gender == 2 || gender == "Nu") {
    obj.font = "fa-venus";
    obj.color = "#F777A7"
  }
  else {
    obj.font = "fa-genderless";
    obj.color = "black";
  }
  return obj
}
// Xét checked cho input
function SetChecked(className, id, status) {
  if (status)
    $(className + id).prop("checked", true);
  else
    $(className + id).prop("checked", false);
}
function ToggleSelectFilter(classSelect, idbox, e) {
  var container = $("." + classSelect);
  // if the target of the click isn't the container nor a descendant of the container
  if (!container.has(e.target).length && !container.is(e.target)) {
    $("." + idbox).addClass("h")
  }
  else {
    $("." + idbox).removeClass("h")
  }
}
function ToggleSelectFilter2(classSelect, idbox, e) {
  var container = $("." + classSelect);
  // if the target of the click isn't the container nor a descendant of the container
  if (!container.is(e.target) && container.has(e.target).length === 0) {
  }
  else {
    $("." + idbox).addClass("h")

  }
}
function ToggleSelectFilter3(classSelect, idbox, e) {
  var container = $("." + classSelect);
  // if the target of the click isn't the container nor a descendant of the container
  if (container.is(e.target)) {
    $("." + idbox).removeClass("h")
  }
  else {
    $("." + idbox).addClass("h")
  }
}
function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
}
function nFormatter(num, digits) {
  try {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
      { value: 1e21, symbol: "Z" },
      { value: 1e24, symbol: "Y" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
    });
    return (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol;
  }
  catch (ex) {
    return num;
  }
}
function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
function getManagementAgency(obj) {
  return SendData("api/ManagementAgency", "get", obj);
}
function checkErrorStatus(e) {
  var status = safeRead(e, "status");
  if (status == 404)
    notification.notifaction("Lỗi " + e.status + " : " + e.statusText);
  else if (status == 401)
    notification.notifactionF2("Vui lòng đăng nhập lại .", logout)
}
function SendDataAwait(api, type, data) {
  const _cookiess = new Cookies();
  return $.ajax({
    url: config.host.fhir_api + api,
    type: type,
    data: data,
    headers: {
      'Authorization': 'bearer ' + _cookiess.get('access_Token'),
      "Accept": "application/json",
      "content-type": "application/json; charset=utf-8"
    },
    traditional: true,
    dataType: 'json',
  });
}
function dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}
export {
  md5,
  safeRead,
  safeReadKB,
  SendData,
  formatDate,
  randomColor,
  calculateAge,
  formatGender,
  validateEmail,
  formatDateTime,
  getIconGender,
  formatTimeDate,
  shortCutImplemented,
  ToggleSelectFilter,
  ToggleSelectFilter2,
  ToggleSelectFilter3,
  safeReadCategori,
  SendDataHFIR,
  SetChecked,
  SendDataJsonString,
  safeReadValueXNCLS,
  formatDateTime2,
  formatTime,
  SendDataCreateAccount,
  removeVietnameseTones,
  SendDataFile,
  SendDataNoAsync,
  SendDataAwait,
  SendDataJsonStringAwait,
  formatDate2,
  nFormatter,
  daysInMonth,
  formatDay,
  checkErrorStatus,
  getManagementAgency,
  formatDateTimeName,
  dragElement,
  validURL,
  SendDataNoHost,
  ShowBirthday
}