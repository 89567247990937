import $ from 'jquery';
import { _height_window , _height_header, _cookies } from '../../../Variable/defaultvar';
import { validateEmail , md5 , shortCutImplemented , SendDataCreateAccount, SendData, SendDataJsonStringAwait, safeRead} from '../../../Function/ui';
import { returnLogin } from '../ConfirmEmail/ui_fc';
import { variable } from './ui_var';
import { notification } from '../../Notification/ui_fc';
import { logout } from '../../DefaultInterface/Header/ui_fc';
var _email ;
function performJQLogin() {
    shortCutImplemented("enter",login);
    $(".div-info-user , .div-setting").remove();
    $(".div-header .w-35").removeClass("w-35").addClass("w-45");
    $(".div-f-login-df").css("height",$(window).height() - 60 )
    $(".div-sh-pw").unbind().on("click",function()
    {
        if($(".div-sh-pw").children().hasClass("fa-eye"))
        {
            $(".div-sh-pw").children().removeClass("fa-eye").addClass("fa-eye-slash");
            $(".ip-pw").attr("type", 'text');
        }
        else
        {
            $(".div-sh-pw").children().removeClass("fa-eye-slash").addClass("fa-eye");
            $(".ip-pw").attr("type", 'password');
        }
    })
    $(".div-btn-login").unbind().on("click",login);
    $("input:text:visible:first").focus();
    $(".div-content-login").unbind().on("click", function(){shortCutImplemented("enter",login);})
    $(".div-create-account").unbind().on("click",logout)
    $(".p-fg-pw").unbind().on("click",fogotPassword);
    $(".div-login-lp").addClass("h");
    var url = new URL(window.location.href);
    var c = url.searchParams.get("action");
    if (c == "create-account") 
    {
        actionLogin(2);
        $(".div-login-lp").removeClass("h");
        $(".div-create-acc-lp").addClass("h");
    }
}
function performJQCreatAccount()
{
    shortCutImplemented("enter",createAccount);
    $(".div-content-login").css("height",$(window).height - 60);
    $("input:text:visible:first").focus();
    $(".div-rt-login").unbind().on("click", function(){
        removeCookie();
        window.location.replace(process.env.PUBLIC_URL+"/dang-nhap");
    });
    $(".div-btn-creatAccount").unbind().on("click",function()
    {
        //notification.notifaction("Chức năng đang phát triển. ");
        createAccount();
    });
    $(".div-sh-pw").unbind().on("click",function()
    {
        if($(".div-sh-pw").children().hasClass("fa-eye"))
        {
            $(".div-sh-pw").children().removeClass("fa-eye").addClass("fa-eye-slash");
            $(".ip-pw").attr("type", 'text');
        }
        else
        {
            $(".div-sh-pw").children().removeClass("fa-eye-slash").addClass("fa-eye");
            $(".ip-pw").attr("type", 'password');
        }
    })
    $(".div-sh-pw-rp").unbind().on("click",function()
    {
        if($(".div-sh-pw-rp").children().hasClass("fa-eye"))
        {
            $(".div-sh-pw-rp").children().removeClass("fa-eye").addClass("fa-eye-slash");
            $(".ip-pw-rp").attr("type", 'text');
        }
        else
        {
            $(".div-sh-pw-rp").children().removeClass("fa-eye-slash").addClass("fa-eye");
            $(".ip-pw-rp").attr("type", 'password');
        }
    })
}
function performJQSendEmail()
{
    $(".div-create-account-s-e").unbind().on("click", function(){actionLogin(1);})//})
    $(".div-btn-login").unbind().on("click", function(){sendEmailForgotPW();})
}
//Hàm đăng nhập
async function login()
{
    $(".div-loading").show();
    if(!checkLogin()) 
    {
        $(".div-loading").hide();
        return;
    }
    var data = {};
    if(validateEmail($(".ip-email").val())) data.email = $(".ip-email").val();
    else data.HospitalId = $(".ip-email").val();
    data.password = md5(md5($(".ip-pw").val()));
    try
    {
        var result = await SendDataJsonStringAwait("api/Token","post",data);
        if(safeRead(result,"Access_Token")!="") SetTokenCookie(result);
        document.cookie = "email_user" + "=" + data.email +"; path=/";
    }
    catch(e)
    {
        if(typeof e.responseJSON == 'number') notification.notifaction(variable.errMessenger[(Number(e.responseJSON)+6)]);
        else notification.notifaction("Xảy ra lỗi. ");
        $(".div-loading").hide();
    }
    $(".div-loading").hide();
}
//Hàm tạo tài khoản
function createAccount()
{
    if(!checkCreateAccount()) return;
    var obj = {};
    obj.Email            = $(".ip-email").val();
    obj.Password         = md5(md5($(".ip-pw").val()));
    obj.HospitalId       = $(".ip-id-hospital").val();
    obj.HospitalName     = $(".ip-name-hospital").val();
    obj.ManagementAgency = $(".ip-management-agency").val();
    var result = SendDataCreateAccount("api/Account/Register","post",obj);
    if(result == "200")
        notification.notifactionF2("Mã xác thực đã gửi ,Vui lòng kiểm tra lại email .", returnLogin);
    else
    {
        var split = result.split(":");
        var text = (split[1]).replace(/\[|\]|\"|\}/g,'');
        if(result!=null&&result!="")
            notification.notifaction(text);
    }
        
}
//Hàm quên mật khẩu
function fogotPassword()
{
    if(!checkPorgotPW()) return;
    addElementSendEmail();
    performJQSendEmail();
}
//hàm gửi xác nhận email quên mật khẩu
function sendEmailForgotPW()
{
    var obj = {};
    obj.Email = _email;
    if(SendData("api/Account/ForgotPassword","get",obj))
        notification.notifactionF2("Đã gửi , Vui lòng kiểm tra Email",returnLogin);
    else
        notification.notifaction("Thất bại.");
}
function checkPorgotPW()
{
    _email = $(".ip-email").val();
    var action = true;
    if(!checkErrorLogin("email",$(".ip-email").val(),"check-email"))
          action = false;
    return action;
}
function checkLogin()
{
    var dataField = ["email","pw"] , action = true;
    for(var i=0;i<dataField.length;i++)
    {
        if(!checkErrorLogin(dataField[i],$(".ip-"+dataField[i]).val(),dataField[i]))
          action = false;
    }
    return action;
}
function checkCreateAccount()
{
    var dataField = ["email","pw","pw-rp","id-hospital","name-hospital","management-agency"] , action = true;
    for(var i=0;i<dataField.length;i++)
    {
        if(!checkErrorLogin(dataField[i],$(".ip-"+dataField[i]).val(),dataField[i]))
          action = false;
    }
    return action;
}
function SetTokenCookie(result)
{
    document.cookie = "information_user" + "=" + result.UserName +"; path=/";
    document.cookie = "information_user_id" + "=" + result.UserID +"; path=/";
    document.cookie = "access_Token" + "=" + result.Access_Token +"; path=/";
    document.cookie = "refresh_Token" + "=" + result.Refresh_Token +"; path=/";
    window.location.replace(process.env.PUBLIC_URL+"/trang-chu");
}
function removeCookie()
{
    document.cookie = 'information_user=; Path=/';
    document.cookie = 'information_user_id=; Path=/';
    document.cookie = 'access_Token=; Path=/';
    document.cookie = 'refresh_Token=; Path=/';
    document.cookie = "email_user=; Path=/";
}
function checkErrorLogin(id,value,action)
{
    var ac     = true;
    var err    = "";
    var clsAdd = "input";
    var clsRm  = "error";
    switch(action)
    {
        case "email" :
        {
            if(value == "")
            {
                ac     = false;
                err    = variable.errMessenger[0];
                clsRm  = "input";
                clsAdd = "error";
                ac = false;
            }
            break;
        }
        case "check-email" :
        {
            if(value == "")
            {
                ac     = false;
                err    = variable.errMessenger[0];
                clsRm  = "input";
                clsAdd = "error";
                ac = false;
            }
            else if(!validateEmail(value))
            {
                ac     = false;
                err    = variable.errMessenger[2];
                clsRm  = "input";
                clsAdd = "error"; 
            }
            break;
        }
        case "pw" :
        {
            if(value == "")
            {
                ac     = false;
                err    = variable.errMessenger[1];
                clsRm  = "input";
                clsAdd = "error";
                ac = false;
            }
            else if(value.length<6)
            {
                ac     = false;
                err    = variable.errMessenger[8];
                clsRm  = "input";
                clsAdd = "error"; 
            }
            break;
        }
        case "newpw" :
        {
            if(value == "")
            {
                ac     = false;
                err    = variable.errMessenger[1];
                clsRm  = "input";
                clsAdd = "error";
                ac = false;
            }
            else if(value.length<6)
            {
                ac     = false;
                err    = variable.errMessenger[8];
                clsRm  = "input";
                clsAdd = "error"; 
            }
            else if(value == $(".ip-pw").val())
            {
                ac     = false;
                err    = variable.errMessenger[11];
                clsRm  = "input";
                clsAdd = "error"; 
            }
            break;
        }
        case "pw-rp" :
        {
            if(value == "")
            {
                ac     = false;
                err    = variable.errMessenger[1];
                clsRm  = "input";
                clsAdd = "error";
                ac = false;
            }
            else if(value.length<6)
            {
                ac     = false;
                err    = variable.errMessenger[8];
                clsRm  = "input";
                clsAdd = "error"; 
            }
            else if(value != $(".ip-pw").val())
            {
                ac     = false;
                err    = variable.errMessenger[9];
                clsRm  = "input";
                clsAdd = "error"; 
            }
            break;
        }
        default :
        {
            if(value == "")
            {
                ac     = false;
                clsRm  = "input";
                clsAdd = "error";
                if(action == "id-hospital")
                    err = variable.errMessenger[6];
                else if(action == "name-hospital")
                    err = variable.errMessenger[7];
                else if(action == "management-agency")
                    err = variable.errMessenger[10];
            }
        }
    }
    $(".ip-"+id).addClass(clsAdd).removeClass(clsRm);
    $(".ip-"+id+"-err p").text(err);
    return ac;
}
//Hàm xử lý các thao tác chuyển đổi trang ở trang login
function actionLogin(action)
{
    $(".div-content-login").empty();
    switch(action)
    {
        //Giao diện trang login
        case 1:
        {
            addElementLogin();
            performJQLogin();
            break;
        }
        //Giao diện trang tạo tài khoản
        case 2:
        {
            addElementCreateAccount();
            performJQCreatAccount();
            break;
        }
    }
}
//Giao diện trang login
function addElementLogin()
{
    var parent = $('<div class="w-100 h-100"/>');
    var child_1 = $('<div class="div-login w-100" />');
    var child_1_child_2 = $('<div class="w-100 div-title-login t-c" />');
    var child_1_child_3 = $('<div class="div-login-form" />');
    var child_1_child_4 = $('<div class="w-100 f-w-600 t-r" />');
    var child_1_child_5 = $('<div class="w-100" style="height:124px"/>');
    var child_1_child_2_child_1 = $('<p>Đăng nhập</p>');
    var child_1_child_3_child_1 = $('<div class="holder"><div class="input-holder"><input class="input ip-email" type="text" placeholder=" " /><div class="placeholder">Email / Mã BV</div></div></div>');
    var child_1_child_3_child_2 = $('<div class="div-err-login ip-email-err"><p /></div>');
    var child_1_child_3_child_3 = $('<div class="holder"><div class="input-holder"><input style="padding:13px 53px 13px 15px" class="input ip-pw" type="password" placeholder=" " /><div class="placeholder">Mật khẩu</div><div class="c-p div-sh-pw"><i class="fal fa-eye"></i></div></div></div>');
    var child_1_child_3_child_4 = $('<div class="div-err-login ip-pw-err"><p /></div>');
    var child_1_child_4_child_1 = $('<p class="c-p p-fg-pw">Quên mật khẩu</p>');
    var child_1_child_5_child_1 = $('<div class="t-c c-w div-btn-login c-p"><p>Đăng nhập</p></div>');
    parent.append([
        child_1.append([
            child_1_child_2.append([
                child_1_child_2_child_1,
            ]),
            child_1_child_3.append([
                child_1_child_3_child_1,
                child_1_child_3_child_2,
                child_1_child_3_child_3,
                child_1_child_3_child_4
            ]),
            child_1_child_4.append([
                child_1_child_4_child_1,
            ]),
            child_1_child_5.append([
                child_1_child_5_child_1,
            ])
        ]),
    ]);
    parent.appendTo($(".div-content-login"));
}
//Giao diện trang tạo tài khoản
function addElementCreateAccount()
{
    var parent = $('<div class="w-100 h-100"/>');
    var child  = $('<div class="div-c-ac" />');
    var child_child_2 = $('<div class="w-100 div-title-login-ac" />');
    var child_child_3 = $('<div class="div-login-form-ac" />');
    var child_child_4 = $('<div class="w-100" />');
    var child_child_2_child_1   = $('<p>Tạo tài khoản</p>');
    var child_child_3_child_1   = $('<div class="holder w-50 f-l"><div class="input-holder w-100"><input class="input ip-id-hospital w-100" type="text" placeholder=" " /><div class="placeholder">Mã CSKCB</div></div></div>');
    var child_child_3_child_2   = $('<div class="holder w-50 f-l"><div class="input-holder w-100"><input class="input ip-name-hospital w-100" type="text" placeholder=" " /><div class="placeholder">Tên CSKCB</div></div></div>');
    var child_child_3_child_3   = $('<div class="div-err-login ip-id-hospital-err f-l w-50"><p /></div>');
    var child_child_3_child_4   = $('<div class="div-err-login ip-name-hospital-err f-l"><p /></div>');
    var child_child_3_child_5   = $('<div class="holder w-100"><div class="input-holder w-100"><input class="input ip-management-agency w-100" type="text" placeholder=" " /><div class="placeholder">Cơ quan chủ quản</div></div></div>');
    var child_child_3_child_6   = $('<div class="div-err-login ip-management-agency-err"><p /></div>');
    var child_child_3_child_7   = $('<div class="holder w-100"><div class="input-holder w-100"><input class="input ip-email w-100" type="text" placeholder=" " /><div class="placeholder">Tài khoản</div></div></div>');
    var child_child_3_child_8   = $('<div class="div-err-login ip-email-err"><p /></div>');
    var child_child_3_child_9   = $('<div class="div-email-title"><span>Sử dụng email để đăng ký tài khoản</span></div>');
    var child_child_3_child_10  = $('<div class="holder w-100"><div class="input-holder w-100"><input style="padding:13px 53px 13px 15px" class="input ip-pw" type="password" placeholder=" " /><div class="placeholder">Mật khẩu</div><div class="c-p div-sh-pw"><i class="fal fa-eye"></i></div></div></div>');
    var child_child_3_child_11  = $('<div class="div-err-login ip-pw-err"><p /></div>');
    var child_child_3_child_12  = $('<div class="div-pw-title"><span>Mật khẩu ít nhất 6 ký tự</span></div>');
    var child_child_3_child_13  = $('<div class="holder w-100"><div class="input-holder w-100"><input style="padding:13px 53px 13px 15px" class="input ip-pw-rp" type="password" placeholder=" " /><div class="placeholder">Nhập lại mật khẩu</div><div class="c-p div-sh-pw-rp"><i class="fal fa-eye"></i></div></div></div>');
    var child_child_3_child_14  = $('<div class="div-err-login ip-pw-rp-err"><p /></div>');
    var child_child_3_child_15  = $('<div class="div-pw-title"><span>Mật khẩu ít nhất 6 ký tự</span></div>');
    var child_child_4_child_1   = $('<div class="t-c c-w div-btn-creatAccount c-p"><p>Tạo tài khoản</p></div>');
    parent.append([
        child.append([
            child_child_2.append([
                child_child_2_child_1,
            ]),
            child_child_3.append([
                child_child_3_child_1,
                child_child_3_child_2,
                child_child_3_child_3,
                child_child_3_child_4,
                child_child_3_child_5,
                child_child_3_child_6,
                child_child_3_child_7,
                child_child_3_child_8,
                child_child_3_child_9,
                child_child_3_child_10,
                child_child_3_child_11,
                child_child_3_child_12,
                child_child_3_child_13,
                child_child_3_child_14,
                child_child_3_child_15
            ]),
            child_child_4.append([
                child_child_4_child_1,
            ])
        ]),
    ]);
    parent.appendTo($(".div-content-login"));
}
//Giao diện gửi email
function addElementSendEmail()
{
    $(".div-content-login").empty();
    var parent = $('<div class="w-100 h-100"/>');
    var child_1 = $('<div class="div-login w-100" />');
    var child_1_child_2 = $('<div class="w-100 div-title-login div-title-login-s-e t-c" style="margin:28px 0 0 0"/>');
    var child_1_child_3 = $('<div class="div-login-form div-login-form-s-e" />');
    var child_1_child_4 = $('<div class="div-s-e-title"/>');
    var child_1_child_5 = $('<div class="w-100" />');
    var child_1_child_2_child_1 = $('<p>'+_email+'</p>');
    var child_1_child_3_child_0 = $('<div ></div>');
    var child_1_child_4_child_1 = $('<div><span>Gửi xác nhận</span></div>');
    var child_1_child_4_child_2 = $('<div><span>Hệ thống sẽ gửi xác nhận đến '+_email+' . </span></div>');
    var child_1_child_4_child_3 = $('<div><span>Hãy nhấp vào link được gửi để cập nhật lại mật khẩu.</span></div>');
    var child_1_child_5_child_1 = $('<div class="t-c c-w div-btn-login c-p"><p style="margin:13px 0 0 0 ; color:white">Gửi</p></div>');
    parent.append([
        child_1.append([
            child_1_child_2.append([
                child_1_child_2_child_1,
            ]),
            child_1_child_3.append([
                child_1_child_3_child_0,
            ]),
            child_1_child_4.append([
                child_1_child_4_child_1,
                child_1_child_4_child_2,
                child_1_child_4_child_3
            ]),
            child_1_child_5.append([
                child_1_child_5_child_1,
            ])
        ]),
        
    ]);
    parent.appendTo($(".div-content-login"));
}
export { actionLogin , removeCookie , addElementLogin , performJQLogin , checkErrorLogin , createAccount }