import $ from 'jquery';
import config from "../../../../config";
import { safeRead , SendData, ToggleSelectFilter2 } from '../../../Function/ui';
import { _cookies } from "../../../Variable/defaultvar";
import { program } from '../../Home/ui_fc';
import { notification } from '../../Notification/ui_fc';
import { lstDMMenu } from '../../Home/ui_var';
import { createAccount } from '../../Login/ui/ui_fc';
var setTimeResetToken ;
function ResetToken()
{
    $.ajax({
        url: config.host.fhir_api + 'api/Token/RefreshToken',
        type: 'Post',
        data: JSON.stringify({
            "access_Token":_cookies.get('access_Token'),
            "refresh_Token":_cookies.get('refresh_Token')
        }),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        async: false,
        success: function (data) {
            delete_cookie("access_Token");
            delete_cookie("refresh_Token");
            set_cookie("access_Token",data.access_Token);
            set_cookie("refresh_Token",data.refresh_Token);
        }.bind(this),
        error: function (request, error) {
            notification.notifactionF2("Vui lòng đăng nhập lại .",logout)
        }
    });
}
function performHeader()
{
    $(".s-name").text(_cookies.get('information_user'));
    $(".div-logout").unbind().on("click",logout)
    $(".div-img-logo").unbind().on("click",function() {
        window.location.replace(process.env.PUBLIC_URL+"/trang-chu");
    })
    $(".div-info-user").unbind().on("click",function() {
        if($(".div-menu-logout").hasClass("h")) $(".div-menu-logout").removeClass("h");
        else $(".div-menu-logout").addClass("h")
        $(document).unbind('mouseup').mouseup(function(e) 
        {
            ToggleSelectFilter2("div-content","div-menu-logout",e);
        });
    })
    $(".div-change-p-w").unbind().on("click",changePassword);
    $(".div-login-lp").unbind().on("click",login);
    $(".div-home").unbind().on("click",logout);
    $(".div-create-acc-lp").unbind().on("click",function(){
        removeCookie();
        window.location.replace(process.env.PUBLIC_URL+"/dang-nhap?action=create-account");
    });
    $(".div-acc-management").unbind().on("click",function(){
        window.location.replace(process.env.PUBLIC_URL+"/account/management");
    })
}
function changePassword()
{
    window.location.replace(process.env.PUBLIC_URL+"/account/change-password?hosID="+_cookies.get('information_user_id'));
}
function login()
{
    removeCookie();
    window.location.replace(process.env.PUBLIC_URL+"/dang-nhap");
}
function logout()
{
    removeCookie();
    window.location.replace(process.env.PUBLIC_URL+"/");
}
function removeCookie()
{
    delete_cookie("information_user");
    delete_cookie("access_Token");
    delete_cookie("refresh_Token");
    delete_cookie("access_his_Token");
}
function set_cookie(name, value) {
    document.cookie = name +'='+ value +'; Path=/;';
}
function delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
function ResetTokenLoadPage()
{
    clearInterval(setTimeResetToken);
    setTimeResetToken = setInterval(ResetToken,120000);
}
function getRole()
{
    var email = _cookies.get('email_user');
    var obj = {"email": email};
    var roles = SendData("/api/Account/UsersRoles","get",obj);
    return roles;
}
function checkRoleManagementagency()
{
    var obj = {};
    obj.ID = _cookies.get("information_user_id");
    obj.RowsPerPage = 100;
    obj.PageNumber = 1;
    var roles = SendData("api/ManagementAgency","get",obj);
    if(roles.length > 0) return true;
    return false;
}
export {
    login,
    logout,
    ResetTokenLoadPage,
    performHeader,
    removeCookie,
    checkRoleManagementagency
}