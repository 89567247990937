import React from 'react'
import Footer from '../../DefaultInterface/Footer/ui';
import Header from '../../DefaultInterface/Header/ui';
import logo from '../../../Images/fhir-services.png';
import './ui.css'
import { actionLogin } from './ui_fc';
import { performHeader } from '../../DefaultInterface/Header/ui_fc';
export default class Login extends React.Component {
    componentWillMount()
    {
    }
    componentDidMount()
    {
        actionLogin(1);
    }
    render() {
        return (
            <div>
                <Header />
                <div className="div-f-login-df row">
                    <div className="div-f-login-l col-lg-6  f-l">
                        <div className="w-100 h-100 t-c">
                            <div>
                                <div>
                                    <span>Cổng thông tin bệnh án điện tử</span>
                                </div>
                                <div>
                                    <img className="w-100" src={logo}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div-f-login-r col-lg-6 f-l div-content-login" />
                        <div className="div-notification w-100 h-100">
                            <div>
                                <div className="w-100 h-18 color-w">
                                    <div className="h-100">
                                        <i className="i-notification"></i><span className="sp-title-notification c-w"/>
                                    </div>
                                </div>
                                <div className="w-100 div-notification-content t-c" />
                                <div className="w-100 div-footer-notification" />
                            </div>
                        </div>
                </div>
                <div className="div-loading" style={{display:"none",width:"130%",marginLeft:"-30%"}}>
                    <div className="spinner" />
                </div>
            </div>
        )
    }
}
