import React from 'react'
import './ui.css'
import { getDataJson, performDataJson } from './ui_fc'
import ReactJson from 'react-json-view'
export default class DataJson extends React.Component {
    componentWillMount()
    {
    }
    componentDidMount()
    {
        performDataJson();
    }
    render() {
        return (
            <div className="div-content w-100 h-100" >
                <ReactJson src={getDataJson()} displayDataTypes={false} displayObjectSize={false} enableClipboard={false} theme={'bright:inverted'}/>
            </div>
        )
    }
}