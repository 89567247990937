import $ from 'jquery';
import { SendData, SendDataCreateAccount } from '../../../../Function/ui';
import { _cookies, _height_window } from '../../../../Variable/defaultvar';
import { notification } from '../../../Notification/ui_fc';
import { arrListClassName } from './ui_var';
var _filterMA = {};
    _filterMA.ID = null;
    _filterMA.PageNumber = 1;
    _filterMA.RowsPerPage = 25;
function resetFilterMA()
{
    _filterMA.PageNumber = 1;
}
function getDataMA()
{
    //var obj = {"managementAgencyId":_cookies.get("information_user_id")}
    return SendData("api/ManagementAgency","get",_filterMA);
}
function performAccountManagement()
{
    $(".div-register-f-hos").unbind().on("click",function(){
        if(!checkNullRegister())
        {
            var obj = {};
            obj.Email        = $(".ip-email-cskcb").val();
            obj.Password     = btoa(btoa($(".ip-pw-cskcb").val()));
            obj.HospitalId   = $(".ip-id-cskcb").val();
            obj.HospitalName = $(".ip-name-cskcb").val();
            var result       = SendDataCreateAccount("api/Account/RegisterFromHospital","post",obj);
            if(result == "200") 
            {
                refeshValueInput();
                addElementListAccManagement(getDataMA())
                notification.notifaction("Thành công");
            }
            else notification.notifaction(result);
        }
    })
    $(".div-grid-a-m").scroll(function () {
        var scrollHeight = $(".div-grid-a-m").prop("scrollHeight");
        if ($(".div-grid-a-m").scrollTop() ==  Math.round(scrollHeight-($(".div-grid-a-m").height()))) {
                fetchMoreData();
                addElementListAccManagement(getDataMA());
        }
    });
    var _height_header    = $(".div-header").height();
    $(".div-grid-a-m").css("max-height",_height_window-(_height_header+$(".div-c-f-h-title").height()+275));
    $(".eye-pw").unbind().on("click",function(){
        if($(".ip-pw-cskcb").attr("type")=="password")
        {
            $(".ip-pw-cskcb").prop("type", "text");
            $(".eye-pw").children().removeClass(".fa-eye-slash").addClass("fa-eye");
        }
        else
        {
            $(".ip-pw-cskcb").prop("type", "password");
            $(".eye-pw").children().removeClass(".fa-eye").addClass("fa-eye-slash");
        }

    });
    $(".eye-rp-pw").unbind().on("click",function(){
        if($(".ip-rp-pw-cskcb").attr("type")=="password")
        {
            $(".ip-rp-pw-cskcb").prop("type", "text");
            $(".eye-rp-pw").children().removeClass(".fa-eye-slash").addClass("fa-eye");
        }
        else
        {
            $(".ip-rp-pw-cskcb").prop("type", "password");
            $(".eye-rp-pw").children().removeClass(".fa-eye").addClass("fa-eye-slash");
        }

    });
}
function fetchMoreData()
{
    _filterMA.PageNumber += 1;
}
function checkNullRegister()
{
    var check = false;
    for(var i = 0 ; i<arrListClassName.length ; i++)
    {
        var val   = $("."+arrListClassName[i]).val();
        var color =  "white white #bdbdbd white";
        if(val == "") 
        {
            color = "red";
            check = true;
        }
        $("."+arrListClassName[i]).css("border-color",color);
        if($("."+arrListClassName[arrListClassName.length-1]).val()!=$("."+arrListClassName[arrListClassName.length-2]).val())
        {
            color = "red";
            check = true;
            $("."+arrListClassName[arrListClassName.length-1]).css("border-color",color);
            $("."+arrListClassName[arrListClassName.length-2]).css("border-color",color);
        }
    }
    return check;
}
function refeshValueInput()
{
    for(var i = 0 ; i < arrListClassName.length ; i++)
    {
        $("."+arrListClassName[i]).val("");
    }
}
function addElementAccountManagement()
{
    addElementListAccManagement(getDataMA())
}
function addElementListAccManagement(data)
{
    for(var i=0 ; i < data.length ; i++)
    {
        var parent = $('<div><span>'+data[i].HospitalName+'</span></div>')
        $(".div-grid-a-m").append(parent);
    }
}
export {
    resetFilterMA,
    addElementAccountManagement,
    performAccountManagement
}