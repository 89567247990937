import { EnumChuyenVien } from "./EnumChuyenVien";
import { EnumGiaiPhauBenh } from "./EnumGiaiPhauBenh";
import { EnumGioiTinh } from "./EnumGioiTinh";
import { EnumHinhThucRaVien } from "./EnumHinhThucRaVien";
import { EnumKetQuaDieuTri } from "./EnumKetQuaDieuTri";
import { EnumLoaiKCB } from "./EnumLoaiKCB";
import { EnumNoiGioiThieu } from "./EnumNoiGioiThieu";
import { EnumTaiBienBienChung } from "./EnumTaiBienBienChung";
import { EnumTinhHinhTuVong } from "./EnumTinhHinhTuVong";
import { EnumTrucTiepVao } from "./EnumTrucTiepVao";

var EnumVN = 
{
    "TrucTiepVao" : EnumTrucTiepVao,
    "ChuyenVien"  : EnumChuyenVien,
    "GiaiPhauBenh": EnumGiaiPhauBenh,
    "HinhThucRaVien" : EnumHinhThucRaVien,
    "NoiGioiThieu" : EnumNoiGioiThieu,
    "KetQuaDieuTri" : EnumKetQuaDieuTri,
    "TinhHinhTuVong" : EnumTinhHinhTuVong,
    "TaiBienBienChung": EnumTaiBienBienChung,
    "GioiTinh" : EnumGioiTinh,
    "LoaiKCB" :EnumLoaiKCB
}
export {EnumVN}