import React  from 'react';
import Header from '../../Header/ui';
import { ResetTokenLoadPage } from '../../Header/ui_fc';
import { addElementAccountManagement, addElementCSKCB, addElementListAccountManagement, getData, performAccountManagement, resetFilterMA, resetVariable } from './ui_fc';
import './ui.css';
export default class AccountManagement extends React.Component {
    componentWillMount()
    {
        ResetTokenLoadPage();
    }
    componentDidMount()
    {
        resetFilterMA();
        addElementAccountManagement();
        performAccountManagement();
    }
    render() {
        return (
          <div className="h-100">
                <Header action="home"/>
                <div className="div-content" style={{padding: "14px 3% 0 3%"}}>
                    <div className="div-c-f-h-title">
                        <p>Quản lý tài khoản</p>
                        <p>Quản lý thông tin các tài khoản trực thuộc</p>
                    </div>
                    <div className="div-c-r-f-h">
                        <div className="div-add-ac-view-title">
                            <span className='f-w-600'>Thêm tài khoản trực thuộc</span>
                        </div>
                        <div className="div-add-view">
                            <div className="w-80 f-l" >
                                <div className="w-33 f-l">
                                    <label>Mã CSKCB(*)</label>
                                    <input class="ip-id-cskcb w-100 h-100" type="text" />
                                </div>
                                <div className="w-33 f-l">
                                    <label>Tên CSKCB(*)</label>
                                    <input class="ip-name-cskcb w-100 h-100" type="text" />
                                </div>
                                <div className="w-33 f-l">
                                    <label>Email(*)</label>
                                    <input class="ip-email-cskcb w-100 h-100" type="text" autoComplete="off"/>
                                </div>
                                <div className="w-33 f-l">
                                    <label>Mật khẩu(*)</label>
                                    <input className="ip-pw-cskcb w-90 h-100 f-l" type="password" autoComplete="off"/>
                                    <div className="eye-pw w-10 f-l" style={{padding:"0 7px"}}>
                                        <i className="fal fa-eye-slash c-p" ></i>
                                    </div>
                                </div>
                                <div className="w-33 f-l">
                                    <label>Nhập lại mật khẩu(*)</label>
                                    <input className="ip-rp-pw-cskcb w-90 h-100 f-l" type="password" />
                                    <div className="eye-rp-pw w-10 f-l" style={{padding:"0 7px"}}>
                                        <i className="fal fa-eye-slash c-p " ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="w-20 f-l" style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                                <div className="c-p div-register-f-hos"><span className="c-w">THÊM MỚI</span></div>
                            </div>
                        </div>
                        <div>
                            <div className="w-100 f-l">
                                <div>
                                    <span className='f-w-600'>Danh sách tài khoản trực thuộc</span>
                                </div>
                            </div>
                            <div className="div-grid-a-m" />
                        </div>
                    </div>
                </div>
                <div className="div-notification w-100 h-100">
                    <div>
                        <div className="w-100 h-18 color-w">
                            <div className="h-100">
                                <i className="i-notification"></i><span className="sp-title-notification c-w"/>
                            </div>
                        </div>
                        <div className="w-100 div-notification-content t-c" />
                        <div className="w-100 div-footer-notification" />
                    </div>
                </div>
                <div className="div-loading" style={{display:"none"}}>
                    <div className="spinner" />
                </div>
                {/*<Footer />*/}
          </div>
        )
    }
}