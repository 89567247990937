import $ from 'jquery' ;
import config from '../../../../../config';
import { SendDataHFIR, SendDataNoHost, validURL } from "../../../../Function/ui";
import { safeRead } from '../../../../Function/ui';
function getParamUrl()
{
    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    var start = url.searchParams.get("start");
    var resourcetype = url.searchParams.get("resourcetype");
    return {
        "id"           : id,
        "resourcetype" : resourcetype,
        "start"        : start
    }
} 
function getDataJson()
{
    var pr = getParamUrl() ,json ; 
    if(pr.start != null)
        json = SendDataNoHost(config.host.h_fhir_api+ "_snapshot?id="+pr.id+"&start="+pr.start,"get",null);
    else if(pr.dt != null)
        json = SendDataNoHost(config.host.h_fhir_api+pr.resourcetype+"/"+pr.id+"/"+pr.dt,"get",null);
    else
    {
        json = SendDataHFIR(pr.resourcetype+"/"+pr.id,"get",null);
        if(pr.resourcetype == "Patient")
        {
            if(safeRead(json,"name") !="") json.name[0].text = "***";
            if(safeRead(json,"address") !="") json.address[0].text = "***";
        }
    }
    try
    {
        for(var i = 0 ; i < json.link.length ; i++)
        {
            var dt = json.link[i],
                url   = new URL(dt.url),
                id    = url.searchParams.get("id"),
                start = url.searchParams.get("start");
            json.link[i].url = '<a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?id="+id+'&start='+start+'">'+dt.url+"</a>";
        }
    }catch(ex){}
    try
    {
        for(var i = 0 ; i < json.entry.length ; i++)
        {
            var url = json.entry[i].fullUrl,
                params = url.split("/");
            json.entry[i].fullUrl = '<a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+params[(params.length - 4)]+'&id='+params[(params.length - 3)]+'&dt='+params[(params.length - 2)]+"/"+params[(params.length - 1)]+'">'+url+"</a>";
        }
    }catch(ex){}
    try
    {
        lookdeep(json);
    }catch(ex){}
    return json;
}
function lookdeep(object){
    var index= 0, next, item;
    for(item in object){
        if(item == "reference" )
        {
            var params = String(object[item]).split("/");
            object[item] = '<a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+params[0]+'&id='+params[1]+'">'+object[item]+'</a>"';
        }
        else if(object.hasOwnProperty(item)){
            next= object[item];
            if(typeof next== 'object' && next!= null){
                lookdeep(next);
                index ++;
            }
            else
            {
                index ++;
                if(item == "reference" )
                {
                    var params = String(object[item]).split("/");
                    object[item] = '<a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+params[0]+'&id='+params[1]+'">'+object[item]+'</a>"';
                }
            }
        }
    }
}
function performDataJson()
{
    changeURLHTML();
    $(".object-key-val").on('DOMSubtreeModified', function(){
        changeURLHTML();
    });
}
function changeURLHTML()
{
    var j_object = $("span.string-value");
    j_object.each( function(){
        var val = $(this).text();
        if(val.length > 10 && val.includes('<a target="_blank"'))
        {
            var str = val.replace(/\"/g, '');
            $(this).empty().append($(str));
        }
    });
}
export {getDataJson , performDataJson};