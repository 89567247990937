import $  from 'jquery';
import config from '../../../../../config';
import 
{   calculateAge, 
    removeVietnameseTones,
    formatDate, 
    formatDateTime ,
    formatTime, 
    getIconGender, 
    safeRead, 
    safeReadKB, 
    SendData , 
    SetChecked,
    checkErrorStatus,
    SendDataAwait,
    ShowBirthday
} from '../../../../Function/ui';
import { _cookies } from '../../../../Variable/defaultvar';
import { logout } from '../../../DefaultInterface/Header/ui_fc';
import { program } from '../../../Home/ui_fc';
import { notification } from '../../../Notification/ui_fc';
import { EnumVN } from '../Enum/EnumVN';
import './ui.css';
var _idEncounter, 
    _indexEncounter , 
    _indexEncounter2 = null, 
    _dtDTPT  ,
    _dtXNCLSBYDATE , 
    _dtLstEncounter,
    _ssXN = false , 
    _ssTH = false ,
    _ssCDHA = false ,
    _ssDBB = false,
    _ssPTTT = false;
function performPatientRecords()
{
    var h_w      = $(window).height();
    var h_if_pt  = $(".div-if-pt").height();
    var h_header = $(".div-header").height(); 
    var h_footer = $(".div-footer").height(); 
    var h_m_r_d  = $(".div-m-r-d").height(); 
    $(".div-mc-rc").height(h_w-h_header-h_footer-h_if_pt-h_m_r_d-8);
    $("#myTabContent").height(h_w-h_header-112);
    $(".div-rt").unbind().on("click",function(){
        program(1);
    })
    $(".li-c-ba").unbind().on("click",function()
    {
        autoFocus("#myTabContent");
    })
    autoFocus("#myTabContent");
    for(var i = 0; i<_dtDTPT.HasValue.length ; i++)
    {
        let id = _dtDTPT.HasValue[i];
        let index = i;
        $(".li-c-"+ id).unbind().on("click",function()
        {
            _ssXN = false; 
            _ssTH = false;
            _ssCDHA = false;
            _ssDBB = false;
            _ssPTTT = false;
            getDataPatientResourceIDByType(id,index);
        })
    }
}
function autoFocus(param)
{
    document.querySelector(param).focus();
    document.querySelector(param).style.outline="none"
}
function performJqueryXNCLS(cls,id)
{
    setHeight(cls,id);
    $(".div-xncls-compare").children().unbind().on("click",function() {
        var id     = Number($(this).attr("name"));
        var action = _dtDTPT.HasValue[id];
        $(".div-xncls-compare-"+id+" , .div-xncls-n-title-"+id+" , .div-xncls-title-"+id+"").remove();
        switch(action)
        {
            //Diễn biến bệnh
            case "ClinicalImpression" :
            {
                $(".div-fhir-gridview-dbb").remove();
                interfaceSwitchDBB(_ssDBB,id);
                performJqueryXNCLS("dbb",id);
                _ssDBB = !_ssDBB;
                break;
            }
            // CHẩn đoán hình ảnh
            case "DiagnosticReport" :
            {
                $(".div-fhir-gridview-cdha").remove();
                interfaceSwitchCDHA(_ssCDHA,id);
                performJqueryXNCLS("cdha",id);
                _ssCDHA = !_ssCDHA;
                break;
            }
            // Xét nghiệm
            case "Observation_LabResult" :
            {
                $(".div-fhir-gridview-xn").remove();
                interfaceSwitchXNCLS(_ssXN,id);
                performJqueryXNCLS("xn",id);
                _ssXN = !_ssXN;
                break;
            }
            //Thuốc
            case "Medication" :
            {
                $(".div-fhir-gridview-th").remove();
                interfaceSwitchTH(_ssTH,id);
                performJqueryXNCLS("th",id);
                _ssTH = !_ssTH;
                break;
            }
            //Phẩu thuật - thủ thuật
            case "Procedure" :
            {
                $(".div-fhir-gridview-pttt").remove();
                interfaceSwitchPTTT(_ssPTTT,id);
                performJqueryXNCLS("pttt",id);
                _ssPTTT = !_ssPTTT;
                break;
            }
        }
    })
}
function setHeight(cls,id)
{
    var h_w             = $(window).height();
    var h_title         = 0;
    var h_n_title       = 0;
    var h_cp            = $(".div-xncls-compare").height();
    var h_xncls         = 30;
    if($(".div-xncls-title-"+id)[0])
    {
        h_title  = $(".div-xncls-title").height();
        h_xncls  = 50;
    }
    if($(".div-xncls-n-title-"+id)[0])
        h_n_title  = $(".div-xncls-n-title").height();
    $(".div-h-view-"+cls).css("max-height",h_w - h_title - h_n_title - h_cp - h_xncls - $(".div-header").height()- $(".c-header-tb").height() - $(".div-if-pt").height() - $(".ul-tab-lst-pt").height());
}
function performJqueryXNCLSDate()
{
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2);
    $('.sl-r option[value="'+_indexEncounter+'"] , .sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
    $(".sl-l").unbind().on("change",function() {
        _indexEncounter = parseInt($(this).val());
        $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-xn-l").empty();
        addElementMedicalRecordDataXNCLSLR(_indexEncounter,"l");
    });
    $(".sl-r").unbind().on("change",function() {
        _indexEncounter2 = parseInt($(this).val());
        $('.sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-xn-r").empty();
        addElementMedicalRecordDataXNCLSLR(_indexEncounter2,"r");
    })
    $(".div-c-n-xn").unbind().on("click",function() {
        if(_indexEncounter2 < _dtLstEncounter.length -1)
        {
            _indexEncounter +=1;
            _indexEncounter2 +=1;
            displaySelectXNCLS();
            $(".div-c-p-xn").removeClass("div-dis");
            if(_indexEncounter2 == _dtLstEncounter.length -1)
                $(this).addClass("div-dis");
        }
    })
    $(".div-c-p-xn").unbind().on("click",function() {
        if(_indexEncounter>0)
        {
            _indexEncounter  -=1;
            _indexEncounter2 -=1;
            displaySelectXNCLS();
            $(".div-c-n-xn").removeClass("div-dis");
            if(_indexEncounter == 0)
                $(this).addClass("div-dis");
        }
    })
    if(_indexEncounter == 0)
    {
        $(".div-c-p-xn").addClass("div-dis");
    }
    if(_indexEncounter2 == _dtLstEncounter.length -1)
    {
        $(".div-c-n-xn").addClass("div-dis");
    }
}
function performJqueryTHDate()
{
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2);
    $('.sl-r option[value="'+_indexEncounter+'"] , .sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
    $(".sl-l").unbind().on("change",function() {
        _indexEncounter = parseInt($(this).val());
        $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-th-l").empty();
        addElementMedicalRecordDataTHLR(_indexEncounter,"l");
    });
    $(".sl-r").unbind().on("change",function() {
        _indexEncounter2 = parseInt($(this).val());
        $('.sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-th-r").empty();
        addElementMedicalRecordDataTHLR(_indexEncounter2,"r");
    })
    $(".div-c-n-xn").unbind().on("click",function() {
        if(_indexEncounter2 < _dtLstEncounter.length - 1)
        {
            _indexEncounter +=1;
            _indexEncounter2 +=1;
            displaySelectTH();
            $(".div-c-p-xn").removeClass("div-dis");
            if(_indexEncounter2 == _dtLstEncounter.length -1)
                $(this).addClass("div-dis");
        }
    })
    $(".div-c-p-xn").unbind().on("click",function() {
        if(_indexEncounter > 0)
        {
            _indexEncounter  -=1;
            _indexEncounter2 -=1;
            displaySelectTH();
            $(".div-c-n-xn").removeClass("div-dis");
            if(_indexEncounter == 0)
                $(this).addClass("div-dis");
        }
    })
    if(_indexEncounter == 0)
    {
        $(".div-c-p-xn").addClass("div-dis");
    }
    if(_indexEncounter2 == _dtLstEncounter.length -1)
    {
        $(".div-c-n-xn").addClass("div-dis");
    }
}
function performJqueryPTTTDate()
{
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2);
    $('.sl-r option[value="'+_indexEncounter+'"] , .sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
    $(".sl-l").unbind().on("change",function() {
        _indexEncounter = parseInt($(this).val());
        $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-pttt-l").empty();
        addElementMedicalRecordDataPTTTLR(_indexEncounter,"l");
    });
    $(".sl-r").unbind().on("change",function() {
        _indexEncounter2 = parseInt($(this).val());
        $('.sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-pttt-r").empty();
        addElementMedicalRecordDataPTTTLR(_indexEncounter2,"r");
    })
    $(".div-c-n-xn").unbind().on("click",function() {
        if(_indexEncounter2 < _dtLstEncounter.length -1)
        {
            _indexEncounter +=1;
            _indexEncounter2 +=1;
            displaySelectPTTT();
            $(".div-c-p-xn").removeClass("div-dis");
            if(_indexEncounter2 == _dtLstEncounter.length -1)
                $(this).addClass("div-dis");
        }
    })
    $(".div-c-p-xn").unbind().on("click",function() {
        if(_indexEncounter > 0)
        {
            _indexEncounter  -=1;
            _indexEncounter2 -=1;
            displaySelectPTTT();
            $(".div-c-n-xn").removeClass("div-dis");
            if(_indexEncounter == 0)
                $(this).addClass("div-dis");
        }
    })
    if(_indexEncounter == 0)
    {
        $(".div-c-p-xn").addClass("div-dis");
    }
    if(_indexEncounter2 == _dtLstEncounter.length -1)
    {
        $(".div-c-n-xn").addClass("div-dis");
    }
}
function performJqueryDBBDate()
{
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2);
    $('.sl-r option[value="'+_indexEncounter+'"] , .sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
    $(".sl-l").unbind().on("change",function() {
        _indexEncounter = parseInt($(this).val());
        $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-dbb-l").empty();
        addElementMedicalRecordDataDBBLR(_indexEncounter,"l");
    });
    $(".sl-r").unbind().on("change",function() {
        _indexEncounter2 = parseInt($(this).val());
        $('.sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-dbb-r").empty();
        addElementMedicalRecordDataDBBLR(_indexEncounter2,"r");
    })
    $(".div-c-n-xn").unbind().on("click",function() {
        if(_indexEncounter2 < _dtLstEncounter.length -1)
        {
            _indexEncounter +=1;
            _indexEncounter2 +=1;
            displaySelectDBB();
            $(".div-c-p-xn").removeClass("div-dis");
            if(_indexEncounter2 == _dtLstEncounter.length -1)
                $(this).addClass("div-dis");
        }
    })
    $(".div-c-p-xn").unbind().on("click",function() {
        if(_indexEncounter > 0 )
        {
            _indexEncounter  -=1;
            _indexEncounter2 -=1;
            displaySelectDBB();
            $(".div-c-n-xn").removeClass("div-dis");
            if(_indexEncounter == 0)
                $(this).addClass("div-dis");
        }
    })
    if(_indexEncounter == 0)
    {
        $(".div-c-p-xn").addClass("div-dis");
    }
    if(_indexEncounter2 == _dtLstEncounter.length -1)
    {
        $(".div-c-n-xn").addClass("div-dis");
    }
}
function performJqueryCDHADate()
{
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2);
    $('.sl-r option[value="'+_indexEncounter+'"] , .sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
    $(".sl-l").unbind().on("change",function() {
        _indexEncounter = parseInt($(this).val());
        $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-cdha-l").empty();
        addElementMedicalRecordDataCDHALR(_indexEncounter,"l");
    });
    $(".sl-r").unbind().on("change",function() {
        _indexEncounter2 = parseInt($(this).val());
        $('.sl-l option[value="'+_indexEncounter2+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled");
        $("#tb-data-cdha-r").empty();
        addElementMedicalRecordDataCDHALR(_indexEncounter2,"r");
    })
    $(".div-c-n-xn").unbind().on("click",function() {
        if(_indexEncounter2 < _dtLstEncounter.length - 1)
        {
            _indexEncounter +=1;
            _indexEncounter2 +=1;
            displaySelectCDHA();
            $(".div-c-p-xn").removeClass("div-dis");
            if(_indexEncounter2 == _dtLstEncounter.length -1)
                $(this).addClass("div-dis");
        }
    })
    $(".div-c-p-xn").unbind().on("click",function() {
        if(_indexEncounter > 0)
        {
            _indexEncounter  -=1;
            _indexEncounter2 -=1;
            displaySelectCDHA();
            $(".div-c-n-xn").removeClass("div-dis");
            if(_indexEncounter == 0)
                $(this).addClass("div-dis");
        }
    })
    if(_indexEncounter == 0)
    {
        $(".div-c-p-xn").addClass("div-dis");
    }
    if(_indexEncounter2 == _dtLstEncounter.length -1)
    {
        $(".div-c-n-xn").addClass("div-dis");
    }
}
function displaySelectXNCLS()
{
    $("#tb-data-xn-l , #tb-data-xn-r").empty();
    addElementMedicalRecordDataXNCLSLR(_indexEncounter,"l");
    addElementMedicalRecordDataXNCLSLR(_indexEncounter2,"r");  
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2); 
    $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled"); 
}
function displaySelectTH()
{
    $("#tb-data-th-l , #tb-data-th-r").empty();
    addElementMedicalRecordDataTHLR(_indexEncounter,"l");
    addElementMedicalRecordDataTHLR(_indexEncounter2,"r");  
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2); 
    $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled"); 
}
function displaySelectPTTT()
{
    $("#tb-data-pttt-l , #tb-data-pttt-r").empty();
    addElementMedicalRecordDataPTTTLR(_indexEncounter,"l");
    addElementMedicalRecordDataPTTTLR(_indexEncounter2,"r");  
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2); 
    $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled"); 
}
function displaySelectDBB()
{
    $("#tb-data-dbb-l , #tb-data-dbb-r").empty();
    addElementMedicalRecordDataDBBLR(_indexEncounter,"l");
    addElementMedicalRecordDataDBBLR(_indexEncounter2,"r");  
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2); 
    $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled"); 
}
function displaySelectCDHA()
{
    $("#tb-data-cdha-l , #tb-data-cdha-r").empty();
    addElementMedicalRecordDataCDHALR(_indexEncounter,"l");
    addElementMedicalRecordDataCDHALR(_indexEncounter2,"r");  
    $(".sl-l").val(_indexEncounter);
    $(".sl-r").val(_indexEncounter2); 
    $('.sl-r option[value="'+_indexEncounter+'"]').attr("disabled", "disabled").siblings().removeAttr("disabled"); 
}
function getDataPatientID(id)
{
    var url   = "/api/BenhAn/GetDetais/"+id;
    _dtDTPT = SendData(url,"get",null);
}
function getDataPatientResourceID()
{
    var id         = _dtDTPT.QuanLyNguoiBenh.Resource;
    var obj        = {};
    obj.patientResourceID = _dtDTPT.HanhChinh.Resource.Id; 
    _dtLstEncounter = SendData("api/Encounter","get",obj);
    //Tìm vị trí encouter mặc định
    _idEncounter   = id.Id;
    _indexEncounter= _dtLstEncounter.findIndex(x=>x.EncounterResourceID == id.Id);
}
async function getDataPatientResourceIDByType(param,index)
{
    $(".div-loading").show();
    var id  = _dtDTPT.QuanLyNguoiBenh.Resource;
    try
    {
        var api = "api/Encounter/ResourceType/"+_dtDTPT.HanhChinh.Resource.Id+"/"+param;
        var data = await SendDataAwait(api,"get",null);
        if(data == undefined) data = [];
        _dtLstEncounter = data;
        _indexEncounter= _dtLstEncounter.findIndex(x=>x.EncounterResourceID == id.Id);
        _indexEncounter2 = null
        addElementHasValueTab(param,index);
        performJqueryXNCLS("",index);
        if(_dtLstEncounter.length < 2) $(".div-xncls-compare-"+index).addClass("h");
    }
    catch(e)
    {
        checkErrorStatus(e);
    }
}
function addElementDetailPatientRecords()
{
    addElementInforPT();
    addElementTab();
    addELementTabContent();
}
function setValElementDetailPatientRecords()
{
    setValElementInforPT();
}
//Phần giao diện thông tin bệnh nhân
function addElementInforPT()
{
    var parent_1                = $('<div class="w-100 div-if-pt"/>');
    var parent_2                = $('<div class="w-100 h-100 div-ba h">')                     
    var child_0                 = $('<div class="w-3 f-l t-c div-rt c-p"><div class="w-100 h-100 div-s-h-upload"><i class="far fa-arrow-left"></i></div></div>');
    var child_1                 = $('<div class="f-l w-7 h-100 t-c"><a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+_dtDTPT.HanhChinh.Resource.TypeName+"&id="+_dtDTPT.HanhChinh.Resource.Id+'"><div class="div-if-img cur"></div></a></div>');
    var child_2                 = $('<div class="f-l w-86 h-100  div-if-pt-t" />');
    var child_2_child_1         = $('<div class="div-n-pt"><span class="sp-n-pt">*****</span><span class="sp-age"></span><span class="sp-gender"><i class="fas"></i></span><a class="f-r" target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+_dtDTPT.HanhChinh.Resource.TypeName+"&id="+_dtDTPT.HanhChinh.Resource.Id+'/$everything">[show Json]</a></div>');
    var child_2_child_2         = $('<div class="w-100 div-if-pt-t-t" />');
    var child_2_child_2_child_1 = $('<div class="f-l w-25"><div class="w-45 f-l"><span>Năm sinh: </span></div><div class="w-55 f-l text-ellipsis-n-w"><span class="sp-birthday" /></div>');
    var child_2_child_2_child_2 = $('<div class="f-l w-25"><div class="w-45 f-l"><span>Điện thoại: </span></div><div class="w-55 f-l text-ellipsis-n-w"><span class="sp-phone" /></div>');
    var child_2_child_2_child_3 = $('<div class="f-l w-25"><div class="w-45 f-l"><span>Email: </span></div><div class="w-55 f-l text-ellipsis-n-w"><span class="sp-email"></span></div>');
    var child_2_child_2_child_4 = $('<div class="f-l w-25"><div class="w-45 f-l"><span>Người thân: </span></div><div class="w-55 f-l text-ellipsis-n-w"><span class="sp-nt"></span></div>');
    var child_2_child_2_child_5 = $('<div class="f-l w-25"><div class="w-45 f-l"><span>Nghề nghiệp: </span></div><div class="w-55 f-l text-ellipsis-n-w"><span class="sp-nn" /></div>');
    var child_2_child_2_child_6 = $('<div class="f-l w-25"><div class="w-45 f-l"><span>Dân tộc: </span></div><div class="w-55 f-l text-ellipsis-n-w"><span class="sp-dt"></span></div>');
    var child_2_child_2_child_7 = $('<div class="f-l w-50"><div class="w-23 f-l"><span>Địa chỉ: </span></div><div class="w-77 f-l text-ellipsis-n-w"><span class="sp-address"></span></div>');
    parent_1.append([child_0,child_1,child_2.append([child_2_child_1,child_2_child_2.append([
        child_2_child_2_child_1,
        child_2_child_2_child_2,
        child_2_child_2_child_3,
        child_2_child_2_child_4,
        child_2_child_2_child_5,
        child_2_child_2_child_6,
        child_2_child_2_child_7
    ])])]);
    $(".div-content-function").append([parent_1,parent_2]);
}
function setValElementInforPT()
{
    var sdt  = safeRead(_dtDTPT.HanhChinh,"DiDongNQH")!=""?" ( "+safeRead(_dtDTPT.HanhChinh,"DiDongNQH")+" ) ":""
    var icon = getIconGender(_dtDTPT.HanhChinh.GioiTinh);
    $(".sp-n-pt").text(_dtDTPT.HanhChinh.HoTen);
    $(".sp-age").text(calculateAge(_dtDTPT.HanhChinh.NgaySinh));
    $(".sp-gender i").addClass(icon.font).css("color",icon.color);
    $(".sp-birthday").text(ShowBirthday(_dtDTPT.HanhChinh.NgaySinh));
    $(".sp-phone").text("******");
    $(".sp-address").text("******")//_dtDTPT.HanhChinh.DiaChi).prop("title",_dtDTPT.HanhChinh.DiaChi);
    $(".sp-email").text("******");
    $(".sp-nn").text(_dtDTPT.HanhChinh.NgheNghiep).prop("title",_dtDTPT.HanhChinh.NgheNghiep);
    $(".sp-dt").text(_dtDTPT.HanhChinh.DanToc);
    $(".sp-nt").text("******")//safeRead(_dtDTPT.HanhChinh,"NguoiQuanHe") + sdt).prop("title",safeRead(_dtDTPT.HanhChinh,"NguoiQuanHe") + sdt);
}
//Phần giao diện tab
function addElementTab()
{
    var parent = $('<div />');
    var child_1 = $('<ul class="ul-tab-lst-pt nav nav-pills" id="myTab" role="tablist" />');
    var child_2 = $('<div class="tab-content" id="myTabContent" tabindex="0"/>'); 
    var child_1_child = $('<li class="nav-item c-p li-c li-c-ba" role="presentation"><div class="nav-link active" id="div-tab-ba" data-bs-toggle="tab" data-bs-target="#tabba"  role="tab" aria-controls="tabba" aria-selected="true">Bệnh án</div></li>'); 
    var child_2_child = $('<div class="tab-pane fade show active div-tab-ba" id="tabba" role="tabpanel" aria-labelledby="div-tabba"></div>');
    child_1.append(child_1_child);
    child_2.append(child_2_child)
    for(var i = 0 ; i < _dtDTPT.HasValue.length ; i++)
    {
        var child_1_child_1 = $('<li class="nav-item c-p li-c li-c-'+_dtDTPT.HasValue[i]+'" role="presentation"><div class="nav-link" id="div-tab'+i+'" data-bs-toggle="tab" data-bs-target="#tab'+i+'"  role="tab" aria-controls="tab'+i+'" aria-selected="false">'+convertHasValueToVN(_dtDTPT.HasValue[i])+'</div></li>'); 
        var child_2_child_1 = $('<div class="tab-pane fade div-tab-'+i+'" id="tab'+i+'" role="tabpanel" aria-labelledby="div-tab'+i+'"></div>');
        child_1.append(child_1_child_1);
        child_2.append(child_2_child_1)
    }
    parent.append([
        child_1,
        child_2
    ])
    $(".div-content-function").append(parent);
}
//Phần giao diện nội dung tab
function addELementTabContent()
{
    addElementMedicalRecord(0,"ba");
    /*for(var i = 0 ;i<_dtDTPT.HasValue.length;i++)
    {
        addElementHasValueTab(_dtDTPT.HasValue[i],i);
    }*/
}
//Phần giao diện bệnh án
function addElementMedicalRecord(id,cls)
{
    switch(id)
    {
        //bệnh án nội khoa
        case 0 :
        {
            addElementMedicalRecordDefault(cls);
            addElementMedicalRecordQLNB();
            addElementMedicalRecordCD();
            addElementMedicalRecordTTRV();
            addElementMedicalRecordLDVV();
            addElementMedicalRecordHB();
            addElementMedicalRecordKB();
            addElementMedicalRecordCDKVKDT();
            addElementMedicalRecordTL();
            addElementMedicalRecordHDT();
            try
            {
                addElementMedicalRecordPT();
            }
            catch(ex){}
            addElementMedicalRecordBS();
            performJqueryMedicalRecordBAN();
            break;
        }
         //bệnh án ngoại khoa
         case 1 :
        {
            addElementMedicalRecordDefault();
            addElementMedicalRecordQLNB();
            addElementMedicalRecordCD();
            performJqueryMedicalRecordBANG();
            break;
        }
    }
    
}
//---------------------------------------------Jquery phần bệnh án----------------------------------------------------------------------
//Phần xử lý jquery bện án nội khoa
function performJqueryMedicalRecordBAN()
{
    $(".sp-title-mr").text("Bệnh án");
    //set checked checkbox trực tiếp vào
    SetChecked(".ip-ttv-",_dtDTPT.QuanLyNguoiBenh.TrucTiepVao,true);
    //set checked checkbox nơi giới thiệu
    SetChecked(".ip-ngt-",_dtDTPT.QuanLyNguoiBenh.NoiGioiThieu,true);
    //set checked checkbox Chuyển viện
    SetChecked(".ip-cv-",_dtDTPT.QuanLyNguoiBenh.TuyenChuyenDi,true);
    //set checked checkbox Chuyển viện
    SetChecked(".ip-htrv-",_dtDTPT.QuanLyNguoiBenh.HinhThucRaVien,true);
    //set checked checkbox Kết quả điều trị
    SetChecked(".ip-kqdt-",_dtDTPT.TinhTrangRaVien.KetQuaDieuTri,true);
    //set checked checkbox Giải phẩu bệnh
    SetChecked(".ip-gpb-",_dtDTPT.TinhTrangRaVien.GiaiPhauBenh,true);
    if(safeRead(_dtDTPT.ChanDoan,"TaiBienBienChung.TaiBien")!="")
        //set checked checkbox Tai biến
        SetChecked(".ip-tb","",_dtDTPT.ChanDoan.TaiBienBienChung.TaiBien);
    if(safeRead(_dtDTPT.ChanDoan,"TaiBienBienChung.BienChung")!="")
        //set checked checkbox Biến chứng
        SetChecked(".ip-bc","",_dtDTPT.ChanDoan.TaiBienBienChung.BienChung);
    if(safeRead(_dtDTPT.TinhTrangRaVien,"TinhHinhTuVong.NguyenNhanTuVong")!="")
        //set checked checkbox tình hình tử vong
        SetChecked(".ip-thtv-",_dtDTPT.TinhTrangRaVien.TinhHinhTuVong.NguyenNhanTuVong,true);
    if(safeRead(_dtDTPT.TinhTrangRaVien,"KhamTuThi")!="")
        //set checked checkbox khám nghiệm tử thi
        SetChecked(".ip-kntt","",_dtDTPT.TinhTrangRaVien.KhamTuThi);
    if(safeRead(_dtDTPT.TinhTrangRaVien,"TinhHinhTuVong.ThoiDiemTuVong")!="")
        //set checked checkbox thời điểm tử vong
        SetChecked(".ip-tdtv-",_dtDTPT.TinhTrangRaVien.TinhHinhTuVong.ThoiDiemTuVong,true);
}
//Phần xử lý jquery bện án ngoại khoa
function performJqueryMedicalRecordBANG()
{
    $(".sp-title-mr").text("Bệnh án ngoại");
}
//---------------------------------------------Jquery phần bệnh án----------------------------------------------------------------------
//Giao diện phần tiêu đề bệnh án
function addElementMedicalRecordDefault(id)
{
    var parent = $('<div/>');
    var child_1 = $('<div class="t-c f-w-500 div-m-r-d"><span class="sp-title-mr">Bệnh Án</span><a class="f-r" target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Encounter&id="+_dtDTPT.QuanLyNguoiBenh.Resource.Id)+'/$everything">[show Json]</a></div>');
    var child_2 = $('<div class="div-mc-rc"><div /></div>')
    parent.append([child_1,child_2]);
    parent.appendTo($(".div-tab-"+id));
}
//Giao diện phần quản lý người bệnh
function addElementMedicalRecordQLNB()
{
    var parent                  = $('<div />');
    var child_1                 = safeRead(_dtDTPT.QuanLyNguoiBenh,"Resource.TypeName")!=""?$('<div><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+_dtDTPT.QuanLyNguoiBenh.Resource.TypeName+"&id="+_dtDTPT.QuanLyNguoiBenh.Resource.Id)+'">Quản lý người bệnh</a></span></div>'):$('<div><span>Quản lý người bệnh</span></div>');
    var child_2                 = $('<div />');
    var child_2_child_1         = $('<div class="w-50 f-l" />');
    var child_2_child_2         = $('<div class="w-50 f-l" />');
    var child_2_child_1_child_1 = $('<div class="w-100" style="padding: 1% 0 5% 0"><div class="w-23 f-l"><span>1. </span><span>Vào viện: </span></div><div class="w-77 f-l"><span class="f-w-500">'+formatTime(_dtDTPT.QuanLyNguoiBenh.NgayVaoVien)+'</span><span> ngày </span><span class="f-w-500">'+formatDate(_dtDTPT.QuanLyNguoiBenh.NgayVaoVien)+'</span></div></div>');
    var child_2_child_1_child_2 = $('<div class="w-100" style="padding: 1.5% 0 5.5% 0"><div class="w-25 f-l"><span>2. </span><span>Trực tiếp vào: </span></div><div class="w-25 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-ttv-CapCuu" type="checkbox" disabled /></span><span class="p-italic f-l">Cấp cứu </span></div><div class="w-20 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-ttv-KhoaKhamBenh" type="checkbox" disabled /></span><span class="p-italic f-l">KKB </span></div><div class="w-30 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-ttv-KhoaDieuTri" type="checkbox" disabled /></span><span class="p-italic f-l">Khoa điều trị </span></div>');
    var child_2_child_1_child_3 = $('<div class="w-100 p-d-t-b-1"></div>');
    var child_2_child_2_child_1 = $('<div class="w-100 p-d-t-b-1"><div class="w-25 f-l"><span>4. </span><span>Nơi giới thiệu: </span></div><div class="w-30 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-ngt-CoQuanYTe" type="checkbox" disabled /></span><span class="p-italic f-l">Cơ quan y tế </span></div><div class="w-23 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-ngt-TuDen" type="checkbox" disabled /></span><span class="p-italic f-l">Tự đến </span></div><div class="w-22 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-ngt-Khac" type="checkbox" disabled /></span><span class="p-italic f-l">Khác </span></div></div>');
    var child_2_child_2_child_2 = $('<div class="w-100 p-d-t-b-1" style="padding:5% 1% 2% 1%"><span>- Vào viện do bệnh này lần thứ: </span><div class="inline t-c" ><span class="f-w-500">'+safeRead(_dtDTPT.QuanLyNguoiBenh,"LanVaoVienThu")+'</span></div></div>');
    var child_2_child_2_child_3 = $('<div class="w-100 p-d-t-b-1"><div class="w-25 f-l"><span>5. </span><span>Chuyển viện: </span></div><div class="w-27 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-cv-TuyenTren" type="checkbox" disabled /></span><span class="p-italic f-l">Tuyến trên </span></div><div class="w-26 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-cv-TuyenDuoi" type="checkbox" disabled /></span><span class="p-italic f-l">Tuyến dưới </span></div><div class="w-22 f-l p-d-l-r-1"><span class="p-m-cb f-l"><input class="ip-cv-CK" type="checkbox" disabled /></span><span class="p-italic f-l">CK </span></div></div>');
    var child_2_child_2_child_4 = $('<div class="w-100 p-d-t-b-1" style="padding:5% 1% 2% 1%"><span>- Chuyển đến: </span><div class="inline t-c" ><span class="f-w-500">'+convertVN("ChuyenDi",_dtDTPT.QuanLyNguoiBenh.NoiChuyenDi)+'</span></div></div>');
    var child_2_child_2_child_5 = $('<div class="w-100" style="padding:0 0 5% 0"><div class="w-20 f-l"><span>6. </span><span>Ra viện: </span></div><div class="w-80 f-l"><span class="f-w-500">'+formatTime(_dtDTPT.QuanLyNguoiBenh.NgayRaVien)+'</span><span> ngày </span><span class="f-w-500">'+formatDate(_dtDTPT.QuanLyNguoiBenh.NgayRaVien)+'</span></div></div>');
    var child_2_child_2_child_6 = $('<div class="w-100 p-d-t-b-1" style="padding:0% 1% 6% 1%"><div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-htrv-RaVien" type="checkbox" disabled /></span><span class="p-italic f-l">Ra viện </span></div><div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-htrv-ChuyenVien" type="checkbox" disabled /></span><span class="p-italic f-l">Chuyển viện </span></div><div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-htrv-TronVien" type="checkbox" disabled /></span><span class="p-italic f-l">Trốn viện </span></div><div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-htrv-XinRaVien" type="checkbox" disabled /></span><span class="p-italic f-l">Xin ra viện </span></div></div>');
    var child_2_child_2_child_7 = $('<div class="w-100 p-d-t-b-1 f-l"><span>7. </span><span>Tổng số ngày điều trị: </span><span class="f-w-500">'+_dtDTPT.QuanLyNguoiBenh.TongSoNgayDieuTri+'</span></div>');
    var child_2_child_1_child_3_child_1 = $('<div class="f-l p-d-t-b-1 w-100"><span>3. </span><span>Khoa điều trị: </span></div>');
    var child_2_child_1_child_3_child_2 = $('<div class="f-l w-100"></div>');
    if(Array.isArray(_dtDTPT.QuanLyNguoiBenh.DS_DieuTriTaiKhoa))
    {
        for(var i = 0 ; i< _dtDTPT.QuanLyNguoiBenh.DS_DieuTriTaiKhoa.length ; i++)
        {
           var child_2_child_1_child_3_child_2_child_1 = $('<div class="w-50 p-d-1-3 f-l">'+_dtDTPT.QuanLyNguoiBenh.DS_DieuTriTaiKhoa[i].TenKhoa+'</div>');
           var child_2_child_1_child_3_child_2_child_2 = $('<div class="w-50 p-d-1-3 f-l"><span class="f-w-500">'+formatTime(_dtDTPT.QuanLyNguoiBenh.DS_DieuTriTaiKhoa[i].NgayDieuTri)+'</span><span> ngày </span><span class="f-w-500">'+formatDate(_dtDTPT.QuanLyNguoiBenh.DS_DieuTriTaiKhoa[i].NgayDieuTri)+'</span></div>');
           child_2_child_1_child_3_child_2.append([child_2_child_1_child_3_child_2_child_1,child_2_child_1_child_3_child_2_child_2])
        }
    }
    parent.append([
        child_1,
        child_2.append([
            child_2_child_1.append([
                child_2_child_1_child_1,
                child_2_child_1_child_2,
                child_2_child_1_child_3.append([
                    child_2_child_1_child_3_child_1,
                    child_2_child_1_child_3_child_2
                ])
            ]),
            child_2_child_2.append([
                child_2_child_2_child_1,
                child_2_child_2_child_2,
                child_2_child_2_child_3,
                child_2_child_2_child_4,
                child_2_child_2_child_5,
                child_2_child_2_child_6,
                child_2_child_2_child_7
            ])
        ])
    ]);
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần chẩn đoán
function addElementMedicalRecordCD()
{
    //$('<div><span><a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+_dtDTPT.ChanDoan.Resource.TypeName+"&id="+_dtDTPT.ChanDoan.Resource.Id+'">Chẩn đoán</a></span></div>');
    var parent  = $('<div/>');
    var child_1 = safeRead(_dtDTPT.ChanDoan,"Resource.TypeName")!="" ? $('<div><span><a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+_dtDTPT.ChanDoan.Resource.TypeName+"&id="+_dtDTPT.ChanDoan.Resource.Id+'">Chẩn đoán</a></span></div>') : $('<div><span>Chẩn đoán</span></div>'); 
    var child_2 = $('<div style="display:flex"/>');
    var child_2_child_1 = $('<div class="w-50 f-l"></div>');
    var child_2_child_2 = $('<div class="w-50 f-l"></div>');
    if(safeRead(_dtDTPT.ChanDoan,"VaoKhoa")!="" && Array.isArray(_dtDTPT.ChanDoan.VaoKhoa))
    {
        for(var i=0;i<_dtDTPT.ChanDoan.VaoKhoa.length;i++)
        {
            var child_2_child_1_child_p = $('<div class="w-100 p-d-t-b-2 b-b" />');
            var child_2_child_1_child_1 = $('<div class="w-100"><span>'+(i+1)+'. '+_dtDTPT.ChanDoan.VaoKhoa[i].MoTa+': </span></div>');
            var child_2_child_1_child_2 = $('<div class="w-100 p-d-l-5" style="height: 10px; padding-bottom:5%" />');
            var child_2_child_1_child_2_child_1 = $('<div class="w-70 f-l f-w-500"><span>'+_dtDTPT.ChanDoan.VaoKhoa[i].TenBenh+'</span></div>');
            var child_2_child_1_child_2_child_2 = $('<div class="w-30 f-l f-w-500 t-r"></div>');
            for(var x = 0 ; x < _dtDTPT.ChanDoan.VaoKhoa[i].MaBenh.length ; x++)
            {
                var style = "border:solid 1px #C6C6C6;border-right:none;width:22px;height:22px";
                if(x == _dtDTPT.ChanDoan.VaoKhoa[i].MaBenh.length-1) style = style.replace("border-right:none;","");
                var child_2_child_1_child_2_child_2_child = $('<div class="inline t-c" style="'+style+'"><span>'+(_dtDTPT.ChanDoan.VaoKhoa[i].MaBenh).slice(x,x+1)+'</span></div>');
                child_2_child_1_child_2_child_2.append(child_2_child_1_child_2_child_2_child);
            }
            child_2_child_1.append(child_2_child_1_child_p.append([
                child_2_child_1_child_1,
                child_2_child_1_child_2.append([
                    child_2_child_1_child_2_child_1,
                    child_2_child_1_child_2_child_2
                ])
            ]))
        }
        var child_2_child_2_child_0 = $('<div class="w-100 inline"><span>'+(_dtDTPT.ChanDoan.VaoKhoa.length+1)+'. Ra viện: </span></div>');
        child_2_child_2.append(child_2_child_2_child_0);
    }
    if(safeRead(_dtDTPT.ChanDoan,"RaVien")!="" && Array.isArray(_dtDTPT.ChanDoan.RaVien))
    {
        for(var i=0;i<_dtDTPT.ChanDoan.RaVien.length;i++)
        {
            var child_2_child_2_child_p = $('<div class="w-100 p-d-t-b-2 b-b" />');
            var child_2_child_2_child_1 = $('<div class="w-100"><span>+ '+_dtDTPT.ChanDoan.RaVien[i].MoTa+': </span></div>');
            var child_2_child_2_child_2 = $('<div class="w-100 p-d-l-5" />');
            var child_2_child_2_child_2_child_1 = $('<div class="w-70 inline f-w-500"><span>'+_dtDTPT.ChanDoan.RaVien[i].TenBenh+'</span></div>');
            var child_2_child_2_child_2_child_2 = $('<div class="w-30 inline f-w-500 t-r"></div>')
            for(var x = 0 ; x < _dtDTPT.ChanDoan.RaVien[i].MaBenh.length ; x++)
            {
                var style = "border:solid 1px #C6C6C6;border-right:none;width:22px;height:22px";
                if(x == _dtDTPT.ChanDoan.RaVien[i].MaBenh.length-1) style = style.replace("border-right:none;","");
                var child_2_child_2_child_2_child_2_child = $('<div class="inline t-c" style="'+style+'"><span>'+(_dtDTPT.ChanDoan.RaVien[i].MaBenh).slice(x,x+1)+'</span></div>');
                child_2_child_2_child_2_child_2.append(child_2_child_2_child_2_child_2_child);
            }
            child_2_child_2.append(child_2_child_2_child_p.append([
                child_2_child_2_child_1,
                child_2_child_2_child_2.append([
                    child_2_child_2_child_2_child_1,
                    child_2_child_2_child_2_child_2
                ])
            ]))
        }
    }
    var child_2_child_1_child_1 = $('<div class="w-25 f-l" style="padding:1%"><span>Nguyên nhân: </span></div>');
    var child_2_child_1_child_2 = $('<div class="w-75 f-l" style="padding:1%"><span class="f-w-500">'+(convertVN("TaiBienBienChung",safeRead(_dtDTPT.ChanDoan,"TaiBienBienChung.NguyenNhan")))+'&nbsp;</span></div>');
    var child_2_child_1_child_3 = $('<div class="w-50 f-l" style="padding:1%"><span class="f-l"><input class="ip-tb" type="checkbox" disabled /></span><span class="f-l" style="margin-left:4px">Tai biến</span></div>');
    var child_2_child_1_child_4 = $('<div class="w-50 f-l" style="padding:1%"><span class="f-l"><input class="ip-bc" type="checkbox" disabled/></span><span class="f-l" style="margin-left:4px">Biến chứng</span></div>');
    child_2_child_1.append([child_2_child_1_child_1,child_2_child_1_child_2,child_2_child_1_child_3,child_2_child_1_child_4])
    parent.append([
        child_1,
        child_2.append([
            child_2_child_1,
            child_2_child_2
        ])
    ])
    parent.appendTo($(".div-mc-rc"));
 
} 
//Giao diện phần tình trạng ra viện
function addElementMedicalRecordTTRV()
{
    var tgtv = safeRead(_dtDTPT.TinhTrangRaVien,"TinhHinhTuVong.ThoiGianTuVong")!=""?formatTime(_dtDTPT.TinhTrangRaVien.TinhHinhTuVong.ThoiGianTuVong)+'</span><span> ngày </span><span class="f-w-500">'+formatDate(_dtDTPT.TinhTrangRaVien.TinhHinhTuVong.ThoiGianTuVong):"";
    var parent  = $('<div class="w-100" style="display: inline-block"/>');
    var child_1 = safeRead(_dtDTPT.TinhTrangRaVien,"Resource.TypeName")!="" ? $('<div><span><a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+_dtDTPT.TinhTrangRaVien.Resource.TypeName+"&id="+safeRead(_dtDTPT.TinhTrangRaVien,"Resource.Id")+'">Tình trạng ra viện</a></span></div>'):$('<div><span>Tình trạng ra viện</span></div>')
    var child_2 = $('<div/>');
    var child_2_child_1 = $('<div class="w-40 f-l p-d-t-b-1"/>');
    var child_2_child_2 = $('<div class="w-60 f-l" />');
    var child_2_child_1_child_1  = $('<div class="w-100 p-d-t-b-1"><span>1. </span><span>Kết quả điều trị:</span></div>');
    var child_2_child_1_child_2  = $('<div class="w-50 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-kqdt-Khoi" type="checkbox" disabled /></span><span class="p-italic f-l">Khỏi </span></div>');
    var child_2_child_1_child_3  = $('<div class="w-50 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-kqdt-KhongThayDoi" type="checkbox" disabled /></span><span class="p-italic f-l">Không thay đổi </span></div>');
    var child_2_child_1_child_4  = $('<div class="w-50 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-kqdt-TuVong" type="checkbox" disabled /></span><span class="p-italic f-l">Tử vong </span></div>');
    var child_2_child_1_child_5  = $('<div class="w-50 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-kqdt-Giam" type="checkbox" disabled /></span><span class="p-italic f-l">Đỡ , giảm </span></div>');
    var child_2_child_1_child_6  = $('<div class="w-50 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-kqdt-NangHon" type="checkbox" disabled /></span><span class="p-italic f-l">Nặng hơn </span></div><div class="w-50 f-l p-d-1" style="height:38px">&nbsp;</div>');
    var child_2_child_1_child_7  = $('<div class="w-100 p-d-t-b-1"><span>2. </span><span>Giải phẩu bệnh (khi có sinh thiết):</span></div>');
    var child_2_child_1_child_8  = $('<div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-gpb-LanhTinh" type="checkbox" disabled /></span><span class="p-italic f-l">Lành tính </span></div>');
    var child_2_child_1_child_9  = $('<div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-gpb-NghiNgo" type="checkbox" disabled /></span><span class="p-italic f-l">Nghi ngờ </span></div>');
    var child_2_child_1_child_10 = $('<div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-gpb-AcTinh" type="checkbox" disabled /></span><span class="p-italic f-l">Ác tính </span></div>');
    var child_2_child_2_child_1  = $('<div class="w-100" style="display:flex"><div class="w-40 f-l"><span>3. </span><span>Tình hình tử vong: </span></div><div class="w-60 f-l"><span class="f-w-500">'+tgtv+'</span></div></div>');
    var child_2_child_2_child_2  = $('<div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-thtv-DoBenh" type="checkbox" disabled /></span><span class="p-italic f-l">Do bệnh </span></div>');
    var child_2_child_2_child_3  = $('<div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-thtv-DoTaiBienDieuTri" type="checkbox" disabled /></span><span class="p-italic f-l">Do tai biến điều trị </span></div>');
    var child_2_child_2_child_4  = $('<div class="w-33 f-l p-d-1"><span class="p-m-cb f-l"><input class="ip-thtv-Khac" type="checkbox" disabled /></span><span class="p-italic f-l">Khác </span></div>');
    var child_2_child_2_child_5  = safeRead(_dtDTPT.TinhTrangRaVien,"TinhHinhTuVong.ThoiDiemTuVong")!=""?$('<div class="w-33 f-l p-d-1"><span class="p-italic f-l"> - Tử vong '+(_dtDTPT.TinhTrangRaVien.TinhHinhTuVong.ThoiDiemTuVong).replace("Trong","trong ").replace("Sau","sau ")+' vào viện </span></div>'):"";
    var child_2_child_2_child_6  = $('<div class="w-33 f-l p-d-1"><span class="p-italic f-l"></span><span class="p-m-cb f-r"></span></div>');
    var child_2_child_2_child_7  = $('<div class="w-33 f-l p-d-1"><span class="p-italic f-l"></span><span class="p-m-cb f-r"></span></div>');
    var child_2_child_2_child_8  = $('<div class="w-100 f-l p-d-t-b-1"><div class="w-40 f-l"><span>4. </span><span>Nguyên nhân chính tử vong: </span></div><div class="w-60 f-l text-at-break-line"><span class="f-w-500">'+safeRead(_dtDTPT.TinhTrangRaVien,"NguyenNhanTuVong.TenBenh")+'</span></div></div>');
    var child_2_child_2_child_9  = $('<div class="w-100 f-l p-d-t-b-1"><div class="w-40 f-l"><span>5. </span><span>Khám nghiệm tử thi: </span></div><div class="w-5 f-l"><input class="ip-kntt" type="checkbox" disabled /></div></div>');
    var child_2_child_2_child_10 = $('<div class="w-100 f-l p-d-t-b-1"><div class="w-40 f-l"><span>6. </span><span>Chẩn đoán giải phẩu tử thi: </span></div><div class="w-60 f-l text-at-break-line"><span class="f-w-500">'+safeRead(_dtDTPT.TinhTrangRaVien.ChuanDoanKhamTuThi,"TenBenh")+'</span></div></div>');
    parent.append([
        child_1,
        child_2.append([
            child_2_child_1.append([
                child_2_child_1_child_1,
                child_2_child_1_child_2,
                child_2_child_1_child_3,
                child_2_child_1_child_4,
                child_2_child_1_child_5,
                child_2_child_1_child_6,
                child_2_child_1_child_7,
                child_2_child_1_child_8,
                child_2_child_1_child_9,
                child_2_child_1_child_10
            ]),
            child_2_child_2.append([
                child_2_child_2_child_1,
                child_2_child_2_child_2,
                child_2_child_2_child_3,
                child_2_child_2_child_4,
                child_2_child_2_child_5,
                child_2_child_2_child_6,
                child_2_child_2_child_7,
                child_2_child_2_child_8,
                child_2_child_2_child_9,
                child_2_child_2_child_10
            ])
        ])
    ])
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần Bệnh án Lý do vào viện
function addElementMedicalRecordLDVV()
{
    var parent  = $('<div />');
    var child_1 = $('<div class="w-100"><div class="w-12 f-l"><span class="f-w-400">Lý do vào viện: </span></div><div class="w-88 f-l"><span class="f-w-500">'+safeRead(_dtDTPT,"LyDoVaoVien")+'</span></div></div>');
    var child_2 = $('<div class="w-100"><div class="w-12 f-l"><span class="f-w-400">Vào ngày: </span></div><div class="w-88 f-l" style="padding:0.5% 1.5%"><span class="f-w-500">'+formatDateTime(_dtDTPT.QuanLyNguoiBenh.NgayVaoVien)+'</span></div></div>');
    parent.append([child_1,child_2]);
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần Hỏi bệnh
function addElementMedicalRecordHB()
{
    var parent                = $('<div />');
    var child_1               = safeRead(_dtDTPT.HoiBenh,"Resource.TypeName")!="" ? $('<div><span><a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(_dtDTPT.HoiBenh,"Resource.TypeName")+"&id="+safeRead(_dtDTPT.HoiBenh,"Resource.Id")+'">Hỏi bệnh</a></span></div>') : $('<div><span>Hỏi bệnh</span></div>');
    var child_2               = $('<div style="display: grid"/>');
    var child_2_child_1       = $('<div class="w-100"><div class="w-20 f-l"><span>1. Quá trình bệnh lý: </span></div><div class="w-80 f-l"><span class="f-w-500">'+safeRead(_dtDTPT.HoiBenh,"QuaTrinhBenhLy")+'</span></div></div>');
    var child_2_child_2       = $('<div class="w-100"><span>2. Tiền sử bệnh: </span><div class="p-d-t-b-1"><div class="w-14 f-l"><span>+ Bản thân: </span></div><div class="w-86 f-l"><span class="f-w-500">'+safeRead(_dtDTPT.HoiBenh,"TienSuBenh.BanThan")+'</span></div></div><div style="padding:2% 0 3% 0"><div class="w-14 f-l"><span>+ Gia đình: </span></div><div class="w-86 f-l"><span class="f-w-500">'+safeRead(_dtDTPT.HoiBenh,"TienSuBenh.GiaDinh")+'</span></div></div></div>');
    var child_2_child_2_child = $('<div><span>Đặc điểm liên quan: </span></div>')
    if(safeRead( _dtDTPT.HoiBenh,"TienSuBenhDacDiemLienQuan") !="")
        for(var i = 0 ; i< _dtDTPT.HoiBenh.TienSuBenh.DacDiemLienQuan.length ; i++)
        {
            var child_2_child_2_child_child_parent = $('<div class="p-d-l-r-4 b-b"/>');
            var child_2_child_2_child_child_1      = $('<div class="w-40 inline"><span> - '+_dtDTPT.HoiBenh.TienSuBenh.DacDiemLienQuan[i].Ten+'</span></div>');
            var child_2_child_2_child_child_2      = $('<div class="w-10 inline"><div><span>Thời gian: </span></div>');
            var child_2_child_2_child_child_3      = $('<div class="w-50 inline"><div><span class="f-w-500">'+_dtDTPT.HoiBenh.TienSuBenh.DacDiemLienQuan[i].ThoiGian+'</span></div>')
            child_2_child_2_child.append(child_2_child_2_child_child_parent.append([
                child_2_child_2_child_child_1,
                child_2_child_2_child_child_2,
                child_2_child_2_child_child_3
            ]))
        }
        child_2_child_2.append(child_2_child_2_child);
        parent.append([child_1,child_2.append([
            child_2_child_1,
            child_2_child_2,
        ])]);
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần khám bệnh
function addElementMedicalRecordKB()
{
    var count                   = 0;
    var parent                  = $('<div />');
    var child_1                 = safeRead(_dtDTPT.KhamBenh,"Resource.TypeName") != "" ? $('<div><span><a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(_dtDTPT.KhamBenh,"Resource.TypeName")+"&id="+safeRead(_dtDTPT.KhamBenh,"Resource.Id")+'">Khám bệnh</a></span></div>') : $('<div><span>Khám bệnh</span></div>');
    var child_2                 = $('<div />');
    var child_2_child_1         = $('<div class="w-70 f-l"/>');
    var child_2_child_2         = $('<div class="w-30 f-l"/>');
    //// phần toàn thân
    if(safeRead(_dtDTPT.KhamBenh,"ToanThan") != "" && Array.isArray(_dtDTPT.KhamBenh.ToanThan) && _dtDTPT.KhamBenh.ToanThan.length>0)
    {
        count +=1 ;
        var th                      = groupArrayByValue(_dtDTPT.KhamBenh.ToanThan);
        var th_keys                 = Object.keys(th);
        var child_2_child_1_child_1 = $('<div class="w-100 p-d-t-b-1" style="background:#EEEEEE"><span>'+count+'. Toàn thân </span><div/>');
        child_2_child_1.append(child_2_child_1_child_1);
        for(var i = 0 ; i < th_keys.length ; i++)
        {
            if(th_keys[i]=="")
            {
                for(var x = 0 ; x < th[th_keys[i]].length;x++)
                {
                    var child_2_child_1_child_2 = $('<div class="w-100 p-d-t-b-1"><div class="w-25 f-l"><span>- '+th[th_keys[i]][x].DacDiem+':</span></div><div class="w-75 f-l"><span class="f-w-500">'+th[th_keys[i]][x].MoTa+'</span></div><div/>');
                    if(th[th_keys[i]][x].DacDiem==null||th[th_keys[i]][x].DacDiem=="")
                        child_2_child_1_child_2 = $('<div class="w-100 p-d-t-b-1"><div class="w-100"><span> - '+th[th_keys[i]][x].MoTa+'</span></div><div/>');
                    child_2_child_1.append(child_2_child_1_child_2);
                }
                
            }
            else
            {
                var child_2_child_1_child_2 = $('<div class="w-100 p-d-t-b-1">');
                var child_2_child_1_child_2_child_1 = $('<div class="w-25 f-l"><span>- '+th[th_keys[i]][0].BoPhanCoThe+':</span></div>');
                var child_2_child_1_child_2_child_2 = $('<div class="w-75 f-l"></div>');
                for(var x = 0 ; x < th[th_keys[i]].length;x++)
                {
                    var child_2_child_1_child_2_child_2_child = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span>+ '+th[th_keys[i]][x].DacDiem+':</span></div><div class="w-100 p-d-l-5 f-l"><span class="f-w-500">'+th[th_keys[i]][x].MoTa+'</span></div><div/>');
                    if(th[th_keys[i]][x].DacDiem==null||th[th_keys[i]][x].DacDiem=="")
                        child_2_child_1_child_2_child_2_child = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span> + '+th[th_keys[i]][x].MoTa+'</span></div><div/>');
                    child_2_child_1_child_2_child_2.append(child_2_child_1_child_2_child_2_child)
                }
                child_2_child_1.append(child_2_child_1_child_2.append([
                    child_2_child_1_child_2_child_1,
                    child_2_child_1_child_2_child_2
                ]));
            }
        }
    }
    //// phần các cơ quan
    if(safeRead(_dtDTPT.KhamBenh,"CacCoQuan") != "" && Array.isArray(_dtDTPT.KhamBenh.CacCoQuan) && _dtDTPT.KhamBenh.CacCoQuan.length>0)
    {
        count +=1 ;
        var ccq                     = groupArrayByValue(_dtDTPT.KhamBenh.CacCoQuan);
        var ccq_keys                = Object.keys(ccq);
        var child_2_child_1_child_3 = $('<div class="w-100 p-d-t-b-1" style="background:#EEEEEE"><span>'+count+'. Các cơ quan </span><div/>');
        child_2_child_1.append(child_2_child_1_child_3);
        for(var i = 0 ; i < ccq_keys.length ; i++)
        {
            if(ccq_keys[i]=="")
            {
                for(var x = 0 ; x < th[ccq_keys[i]].length;x++)
                {
                    var child_2_child_1_child_2 = $('<div class="w-100 p-d-b-1"><div class="w-25 f-l"><span>- '+ccq[ccq_keys[i]][x].DacDiem+':</span></div><div class="w-75 f-l"><span class="f-w-500">'+ccq[ccq_keys[i]][x].MoTa+'</span></div><div/>');
                    if(ccq[ccq_keys[i]][x].DacDiem == null || ccq[ccq_keys[i]][x].DacDiem =="")
                        child_2_child_1_child_2 = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span> - '+ccq[ccq_keys[i]][x].MoTa+'</span></div><div/>');
                    child_2_child_1.append(child_2_child_1_child_2);
                }
                
            }
            else
            {
                var child_2_child_1_child_2 = $('<div class="w-100 p-d-t-b-1">');
                var child_2_child_1_child_2_child_1 = $('<div class="w-25 f-l"><span>- '+ccq[ccq_keys[i]][0].BoPhanCoThe+':</span></div>');
                var child_2_child_1_child_2_child_2 = $('<div class="w-75 f-l"></div>');
                for(var x = 0 ; x < ccq[ccq_keys[i]].length;x++)
                {
                    var child_2_child_1_child_2_child_2_child = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span>+ '+ccq[ccq_keys[i]][x].DacDiem+':</span></div><div class="w-100 p-d-l-5 f-l"><span class="f-w-500">'+ccq[ccq_keys[i]][x].MoTa+'</span></div><div/>');
                    if(ccq[ccq_keys[i]][x].DacDiem == null || ccq[ccq_keys[i]][x].DacDiem =="")
                        child_2_child_1_child_2_child_2_child = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span> + '+ccq[ccq_keys[i]][x].MoTa+'</span></div><div/>');
                    child_2_child_1_child_2_child_2.append(child_2_child_1_child_2_child_2_child)
                }
                child_2_child_1.append(child_2_child_1_child_2.append([
                    child_2_child_1_child_2_child_1,
                    child_2_child_1_child_2_child_2
                ]));
            }
        }
    }
    //// phần khám chuyên khoa
    if(safeRead(_dtDTPT.KhamBenh,"KhamChuyenKhoa") != "" && Array.isArray(_dtDTPT.KhamBenh.KhamChuyenKhoa) && _dtDTPT.KhamBenh.KhamChuyenKhoa.length>0)
    {
        count +=1 ;
        var kck                     = groupArrayByValue(_dtDTPT.KhamBenh.KhamChuyenKhoa);
        var kck_keys                = Object.keys(kck);
        var child_2_child_1_child_3 = $('<div class="w-100 p-d-t-b-1" style="background:#EEEEEE"><span>'+count+'. Khám chuyên khoa </span><div/>');
        child_2_child_1.append(child_2_child_1_child_3);
        for(var i = 0 ; i < kck_keys.length ; i++)
        {
            if(kck_keys[i]=="")
            {
                for(var x = 0 ; x < kck[kck_keys[i]].length;x++)
                {
                    var child_2_child_1_child_2 = $('<div class="w-100 p-d-b-1"><div class="w-25 f-l"><span>- '+kck[kck_keys[i]][x].DacDiem+':</span></div><div class="w-75 f-l"><span class="f-w-500">'+kck[kck_keys[i]][x].MoTa+'</span></div><div/>');
                    if(kck[kck_keys[i]][x].DacDiem == null || kck[kck_keys[i]][x].DacDiem == "")
                        child_2_child_1_child_2 = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span> - '+kck[kck_keys[i]][x].MoTa+'</span></div><div/>');
                    child_2_child_1.append(child_2_child_1_child_2);
                }
                
            }
            else
            {
                var child_2_child_1_child_2 = $('<div class="w-100 p-d-t-b-1">');
                var child_2_child_1_child_2_child_1 = $('<div class="w-25 f-l"><span>- '+kck[kck_keys[i]][0].BoPhanCoThe+':</span></div>');
                var child_2_child_1_child_2_child_2 = $('<div class="w-75 f-l"></div>');
                for(var x = 0 ; x < kck[kck_keys[i]].length;x++)
                {
                    var child_2_child_1_child_2_child_2_child = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span> + '+kck[kck_keys[i]][x].DacDiem+':</span></div><div class="w-100 p-d-l-5 f-l"><span class="f-w-500">'+kck[kck_keys[i]][x].MoTa+'</span></div><div/>');
                    if(kck[kck_keys[i]][x].DacDiem == null || kck[kck_keys[i]][x].DacDiem == "")
                        child_2_child_1_child_2_child_2_child = $('<div class="w-100 p-d-b-1"><div class="w-100 f-l"><span> + '+kck[kck_keys[i]][x].MoTa+'</span></div><div/>');
                    child_2_child_1_child_2_child_2.append(child_2_child_1_child_2_child_2_child)
                }
                child_2_child_1.append(child_2_child_1_child_2.append([
                    child_2_child_1_child_2_child_1,
                    child_2_child_1_child_2_child_2
                ]));
            }
        }
    }
    var child_2_child_1_child_13  = $('<div class="w-100 p-d-t-b-3"><div class="w-20 f-l"><span>'+(count+1)+'. Tóm tắt bệnh án: </span></div> <div class="w-80 f-l"><span class="f-w-500">'+safeRead(_dtDTPT.KhamBenh,"TomTatBenhAn")+'</span></div><div/>');
    if(safeRead(_dtDTPT.KhamBenh,"DauHieuSinhTon")!="")
    {
        var child_2_child_2_child_1  = $('<div class="w-100"><div class="w-50 f-l"><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Observation&id="+safeReadKB(getIDResource("Observation","Vital-signs-HeartRate"),0))+'">Mạch: </span></a></div><div class="w-20 f-l t-r"><span class="f-w-500">'+_dtDTPT.KhamBenh.DauHieuSinhTon.Mach+'</span></div><div class="w-30 f-l t-r"><span>lần/phút</span></div><div/>');
        var child_2_child_2_child_2  = $('<div class="w-100"><div class="w-50 f-l"><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Observation&id="+safeReadKB(getIDResource("Observation","Vital-signs-BodyTemperature"),0))+'">Nhiệt độ: </span></a></div><div class="w-20 f-l t-r"><span class="f-w-500">'+_dtDTPT.KhamBenh.DauHieuSinhTon.NhietDo+'</span></div><div class="w-30 f-l t-r"><span>&deg;C</span></div><div/>');
        var child_2_child_2_child_3  = $('<div class="w-100"><div class="w-50 f-l"><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Observation&id="+safeReadKB(getIDResource("Observation","Vital-signs-RespiratoryRate"),0))+'">Nhịp thở: </span></a></div><div class="w-20 f-l t-r"><span class="f-w-500">'+_dtDTPT.KhamBenh.DauHieuSinhTon.NhipTho+'</span></div><div class="w-30 f-l t-r"><span>lần/phút</span></div><div/>');
        var child_2_child_2_child_4  = $('<div class="w-100"><div class="w-50 f-l"><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Observation&id="+safeReadKB(getIDResource("Observation","Vital-signs-BodyWeight"),0))+'">Cân nặng: </span></a></div><div class="w-20 f-l t-r"><span class="f-w-500">'+_dtDTPT.KhamBenh.DauHieuSinhTon.CanNang+'</span></div><div class="w-30 f-l t-r"><span>kg</span></div><div/>');
        var child_2_child_2_child_5  = $('<div class="w-100"><div class="w-50 f-l"><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Observation&id="+safeReadKB(getIDResource("Observation","Vital-signs-BodyHeight"),0))+'">Chiều cao: </span></a></div><div class="w-20 f-l t-r"><span class="f-w-500">'+_dtDTPT.KhamBenh.DauHieuSinhTon.ChieuCao+'</span></div><div class="w-30 f-l t-r"><span>cm</span></div><div/>');
        var child_2_child_2_child_6  = $('<div class="w-100"><div class="w-50 f-l"><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Observation&id="+safeReadKB(getIDResource("Observation","Vital-signs-SystolicBloodPressure"),0))+'">Huyết áp tâm thu: </span></a></div><div class="w-20 f-l t-r"><span class="f-w-500">'+_dtDTPT.KhamBenh.DauHieuSinhTon.HuyetApTamThu+'</span></div><div class="w-30 f-l t-r"><span>mmHg</span></div><div/>');
        var child_2_child_2_child_7  = $('<div class="w-100"><div class="w-50 f-l"><span><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype=Observation&id="+safeReadKB(getIDResource("Observation","Vital-signs-DiastolicBloodPressure"),0))+'">Huyết áp tâm trương: </span></a></div><div class="w-20 f-l t-r"><span class="f-w-500">'+_dtDTPT.KhamBenh.DauHieuSinhTon.HuyetApTamTruong+'</span></div><div class="w-30 f-l t-r"><span>mmHg</span></div><div/>');
        child_2_child_2.append([
            child_2_child_2_child_1,
            child_2_child_2_child_2,
            child_2_child_2_child_3,
            child_2_child_2_child_4,
            child_2_child_2_child_5,
            child_2_child_2_child_6,
            child_2_child_2_child_7
        ]);
    }
    parent.append([child_1,child_2.append([
        child_2_child_1.append([
            child_2_child_1_child_13
        ]),
        child_2_child_2
        ])
    ])
    parent.appendTo($(".div-mc-rc"));
}
function groupArrayByValue(myArray)
{
    var groups = {};
    for (var i = 0; i < myArray.length; i++) {
        var name = myArray[i].BoPhanCoThe==null?"":myArray[i].BoPhanCoThe;
        var groupName = removeVietnameseTones(name).replaceAll(/ |\/|/g,"");
        if (!groups[groupName]) {
            groups[groupName] = [];
        }
        groups[groupName].push(myArray[i]);
    }
    myArray = [];
    for (var groupName in groups) {
      myArray.push({group: groupName, ToanThan: groups[groupName]});
    }
    return groups;
}
//Giao diện phần chẩn đoán khi vào khoa điều trị
function addElementMedicalRecordCDKVKDT()
{
    var bkt                   = safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"BenhKemTheo.MaBenh")!=""?"("+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"BenhKemTheo.MaBenh")+") - "+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"BenhKemTheo.TenBenh"):"";
    var bc                    = safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"BenhChinh.MaBenh")!=""?"("+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"BenhChinh.MaBenh")+") - "+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"BenhChinh.TenBenh"):"";
    var pb                    = safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"PhanBiet.MaBenh")!=""?"("+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"PhanBiet.MaBenh")+") - "+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"PhanBiet.TenBenh"):"";
    var parent                = $('<div/>');
    var child_1               = safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"Resource.Id") != "" ? $('<div><span><a target="_blank" rel="noopener noreferrer" href="'+process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"Resource.TypeName")+"&id="+safeRead(_dtDTPT.ChuanDoanKhiVaoNoiDieuTri,"Resource.Id")+'">Chẩn đoán khi vào khoa điều trị</a></span></div>') : $('<div><span>Chẩn đoán khi vào khoa điều trị</span></div>');
    var child_2               = $('<div style="display:grid"/>');
    var child_2_child_1       = $('<div class="p-d-t-b-1"><div class="w-15 f-l"><span>1. Bệnh chính: </span></div><div class="w-85 f-l"><span class="f-w-500">'+bc+'</span></div></div>');
    var child_2_child_2       = $('<div class="p-d-t-b-1"><div class="w-15 f-l"><span>2. Bệnh kèm theo: </span></div><div class="w-85 f-l"><span class="f-w-500">'+bkt+'</span></div></div>');
    var child_2_child_3       = $('<div class="p-d-t-b-1"><div class="w-15 f-l"><span>3. Phân biệt: </span></div><div class="w-85 f-l"><span class="f-w-500">'+pb+'</span></div></div>');
    parent.append([child_1,child_2.append([
        child_2_child_1,
        child_2_child_2,
        child_2_child_3
    ])]);
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần xét nghiệm CLS
function addElementXNCLS(id)
{
    interfaceSwitchXNCLS(true,id);
}
//Chuyển sang giao diện so sánh 2 lần thực hiện xét nghiệm
function interfaceSwitchXNCLS(action,id)
{
    addElementButtonCompare(id);
    if(action)
    {
        addElementMedicalRecordXNCLS(id);
        addElementMedicalRecordDataXNCLSLR(_indexEncounter,"");
    }
    else
    {
        addElementMedicalRecordXNCLSTitle(id);
        addElementSLDataRecordDataXNCLS();
        addElementMedicalRecordXNCLSL(id);
        addElementMedicalRecordXNCLSR(id);
        addElementMedicalRecordDataXNCLS(null);
        performJqueryXNCLSDate();
    }
}
function addElementButtonCompare(id)
{
    var parent  = $('<div class="w-100 div-xncls-compare div-xncls-compare-'+id+' c-w"/>');
    var child  = $('<div class="w-3 t-c f-r c-p" name="'+id+'"></div>');
    parent.append([child]);
    $(".div-tab-"+id).append(parent)
}
//Giao điện phần title xét nghiệm cls
function addElementMedicalRecordXNCLSTitle(id)
{
    var parent_0  = $('<div class="div-xncls-n-title div-xncls-n-title-'+id+'"><div class="w-94 f-l h-100"><span>Thời gian thực hiện</span></div><div class="w-3 f-l t-c c-w c-p div-c-p-xn h-100"><i class="far fa-chevron-left"></i></div><div class="w-3 f-l t-c c-w c-p div-c-n-xn h-100"><i class="far fa-chevron-right"></i></div></div>');
    var parent_1  = $('<div class="w-100 div-xncls-title div-xncls-title-'+id+'"/>');
    var child_1   = $('<div class="w-50 f-l"><select class="sl-l w-100 form-select"></select></div>');
    var child_2   = $('<div class="w-50 f-l"><select class="sl-r w-100 form-select"></select></div>');
    parent_1.append([child_1,child_2]);
    $(".div-tab-"+id).append([parent_0,parent_1])
}
//Giao diện phần xét nghiệm cận lâm sàng
function addElementMedicalRecordXNCLS(id)
{
    
    var parent                                = $('<div class="div-fhir-gridview div-fhir-gridview-xn"/>');
    var child                                 = $('<table class="w-100" />');
    var child_child                           = $('<tr class="c-w c-header-tb"/>');
    var child_child_2                         = $('<tr />');
    var child_child_child                     = $('<td />');
    var child_child_2_child                   = $('<td />');
    var child_child_child_child               = $('<table class="w-100" />');
    var child_child_2_child_child             = $('<div class="w-100 div-h-view- div-h-view-xn" style="overflow:auto"/>');
    var child_child_child_child_child         = $('<tr />');
    var child_child_2_child_child_child       = $('<table id="tb-data-xn-" class="w-100 tb-dp-data tb-dp-data-xn"/>');
    var child_child_child_child_child_child_1 = $('<th class="w-30">Xét nghiệm</th>');
    var child_child_child_child_child_child_2 = $('<th class="w-20">Kết quả</th>');
    var child_child_child_child_child_child_3 = $('<th class="w-20 t-c">Đơn vị</th>');
    var child_child_child_child_child_child_4 = $('<th class="w-30 t-c">Ngày kết quả</th>');
    child.append([child_child.append(child_child_child.append(child_child_child_child.append(child_child_child_child_child.append([child_child_child_child_child_child_1,child_child_child_child_child_child_2,child_child_child_child_child_child_3,child_child_child_child_child_child_4])))),child_child_2.append(child_child_2_child.append(child_child_2_child_child.append(child_child_2_child_child_child)))])
    $(".div-tab-"+id).append([parent.append(child)])
}
function addElementMedicalRecordXNCLSL(id)
{
    addElementMedicalRecordXNCLS(id);
    $(".div-fhir-gridview-xn").addClass("w-50 f-l").css({"border-right":"solid 1px rgba(127, 127, 127, 0.54)","padding":"0 0 0 1%"});
    $(".tb-dp-data-xn").attr("id","tb-data-xn-l");
}
function addElementMedicalRecordXNCLSR(id)
{
    addElementMedicalRecordXNCLS(id);
    $(".div-fhir-gridview-xn:last-child").addClass("w-50 f-l").css({"padding":"0 1% 0 0"});
    $(".tb-dp-data-xn:eq(1)").attr("id","tb-data-xn-r");
}
//Add element select option phần xét nghiệm cls
function addElementSLDataRecordDataXNCLS()
{
    $(".sl-l , .sl-r").empty();
    for(var i = 0 ; i< _dtLstEncounter.length ; i++)
    {
        var date = formatDateTime(_dtLstEncounter[i].Date);
        var parent = $('<option value="'+i+'">'+date+'</option>');
        $(".sl-l , .sl-r").append(parent);
    }
}
function addElementMedicalRecordDataXNCLS(id)
{
    $("#tb-data-xn-l , #tb-data-xn-r").empty();
    if(id!=null)
        _indexEncounter = _dtLstEncounter.findIndex(x=>x.EncounterResourceID == id);
    if (_indexEncounter == _dtLstEncounter.length-1 && _dtLstEncounter.length-1 > 0)
    {
        _indexEncounter2 = _indexEncounter;
        _indexEncounter  = _indexEncounter -1;
    }
    else if(_indexEncounter < _dtLstEncounter.length-1 )
        _indexEncounter2 = _indexEncounter +1 ;
    if(_indexEncounter  !=null) addElementMedicalRecordDataXNCLSLR(_indexEncounter,"l");
    if(_indexEncounter2 !=null) addElementMedicalRecordDataXNCLSLR(_indexEncounter2,"r");
}
async function addElementMedicalRecordDataXNCLSLR(index,cls)
{
    var data = await getDataXNCLSBYECT("api/BenhAn/GetDetais/"+ _dtLstEncounter[index]._Id_emr);
    if(safeRead(data,"DS_XetNghiem")) data = data.DS_XetNghiem;
    try
    {
        for(var i=0;i<data.length;i++)
        {
            var dv = data[i].DonVi;
            if(dv == null) dv = "";
            var parent  = $('<tr />')
            //var child_0 = $('<td class="w-12 text-ellipsis" title="'+data[i].MaXN+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+data[i].resource.resourceType+"&id="+data[i].resource.id)+'">'+data[i].MaXN+'</a></td>');
            var child_1 = $('<td class="w-30 text-ellipsis c-p" title="'+data[i].MaXN+"-"+data[i].TenXN+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(data[i].Resource,"TypeName")+"&id="+safeRead(data[i].Resource,"Id"))+'"><div>'+data[i].MaXN+'</div><div>'+data[i].TenXN+'</div></a></td>');
            var child_2 = $('<td class="w-20 text-ellipsis" style="background-color:#DFDFDF">'+data[i].KetQua+'</td>');
            var child_3 = $('<td class="w-20 text-ellipsis t-c">'+dv+'</td>');
            var child_4 = $('<td class="w-30 text-ellipsis t-c" title="'+(formatDateTime(data[i].NgayKQ))+'">'+(formatDateTime(data[i].NgayKQ))+'</td>');
            parent.append([child_1,child_2,child_3,child_4]);
            $("#tb-data-xn-"+cls).append(parent);
        }
    }
    catch(ex){}
}
//Lấy data danh sách xét nghiệm từ id Encounter
async function getDataXNCLSBYECT(api)
{
    $(".div-loading").show();
    var data  = await SendDataAwait(api,"get",null); 
    $(".div-loading").hide();
    return data;
}
function interfaceSwitchTH(action,id)
{
    addElementButtonCompare(id);
    if(action)
    {
        addElementMedicalRecordTHU(id);
        addElementMedicalRecordDataTHLR(_indexEncounter,"");
    }
    else
    {
        addElementMedicalRecordXNCLSTitle(id);
        addElementSLDataRecordDataXNCLS();
        addElementMedicalRecordTHL(id);
        addElementMedicalRecordTHR(id);
        addElementMedicalRecordDataTH(null);
        performJqueryTHDate();
    }
}
function addElementMedicalRecordTH(id)
{
    interfaceSwitchTH(true,id);
}
//Giao diện phần Thuốc
function addElementMedicalRecordTHU(id)
{
    var parent                                = $('<div class="div-fhir-gridview div-fhir-gridview-th"/>');
    var child                                 = $('<table class="w-100" />');
    var child_child                           = $('<tr class="c-w c-header-tb"/>');
    var child_child_2                         = $('<tr />');
    var child_child_child                     = $('<td />');
    var child_child_2_child                   = $('<td />');
    var child_child_child_child               = $('<table class="w-100" />');
    var child_child_2_child_child             = $('<div class="w-100 div-h-view- div-h-view-th" style="overflow:auto"/>');
    var child_child_child_child_child         = $('<tr />');
    var child_child_2_child_child_child       = $('<table id="tb-data-th-" class="w-100 tb-dp-data tb-dp-data-th"/>');
    var child_child_child_child_child_child_1 = $('<th class="w-32">Thuốc</th>');
    var child_child_child_child_child_child_2 = $('<th class="w-25">Liều dùng</th>');
    var child_child_child_child_child_child_3 = $('<th class="w-8 t-c">SL</th>');
    var child_child_child_child_child_child_4 = $('<th class="w-15  t-c">Đơn vị</th>');
    var child_child_child_child_child_child_5 = $('<th class="w-20  t-c">Ngày Y lệnh</th>');
    child.append([child_child.append(child_child_child.append(child_child_child_child.append(child_child_child_child_child.append([child_child_child_child_child_child_1,child_child_child_child_child_child_2,child_child_child_child_child_child_3,child_child_child_child_child_child_4,child_child_child_child_child_child_5])))),child_child_2.append(child_child_2_child.append(child_child_2_child_child.append(child_child_2_child_child_child)))])
    $(".div-tab-"+id).append([parent.append(child)])
}
function addElementMedicalRecordTHL(id)
{
    addElementMedicalRecordTHU(id);
    $(".div-fhir-gridview-th").addClass("w-50 f-l").css({"border-right":"solid 2px #D7D7D7","padding":"0 0 0 1%"});
    $(".tb-dp-data-th").attr("id","tb-data-th-l");
}
function addElementMedicalRecordTHR(id)
{
    addElementMedicalRecordTHU(id);
    $(".div-fhir-gridview-th:last-child").addClass("w-50 f-l").css({"padding":"0 1% 0 0"});
    $(".tb-dp-data-th:eq(1)").attr("id","tb-data-th-r");
}
function addElementMedicalRecordDataTH(id)
{
    $("#tb-data-th-l , #tb-data-th-r").empty();
    if(id!=null)
        _indexEncounter = _dtLstEncounter.findIndex(x=>x.EncounterResourceID == id);
    if (_indexEncounter == _dtLstEncounter.length-1 && _dtLstEncounter.length-1 > 0)
    {
        _indexEncounter2 = _indexEncounter;
        _indexEncounter  = _indexEncounter -1;
    }
    else if(_indexEncounter < _dtLstEncounter.length-1 )
        _indexEncounter2 = _indexEncounter +1 ;
    if(_indexEncounter  !=null) addElementMedicalRecordDataTHLR(_indexEncounter,"l");
    if(_indexEncounter2 !=null) addElementMedicalRecordDataTHLR(_indexEncounter2,"r");
}
async function addElementMedicalRecordDataTHLR(index,cls)
{
    var data = await getDataXNCLSBYECT("api/Medication/GetByIdEmr/"+ _dtLstEncounter[index]._Id_emr);
    try
    {
        for(var i=0;i<data.length;i++)
        {
            var parent  = $('<tr />')
            //var child_0 = $('<td class="w-12 text-ellipsis" title="'+data[i].resource.medicationCodeableConcept.coding[0].code+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+data[i].resource.resourceType+"&id="+data[i].resource.id)+'">'+data[i].resource.medicationCodeableConcept.coding[0].code+'</a></td>');
            var child_1 = $('<td class="w-32 text-ellipsis c-p" title="( '+data[i].MaThuoc+' ) '+data[i].TenThuoc+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(data[i].Resource,"TypeName")+"&id="+safeRead(data[i].Resource,"Id"))+'">'+data[i].MaThuoc+'<br/>'+data[i].TenThuoc+'</a></td>');
            //var child_1 = $('<td class="w-23 text-ellipsis" title="'+data[i].TenThuoc+'">'+data[i].TenThuoc+'</a></td>');
            var child_2 = $('<td class="w-25" title="'+data[i].LieuDung+'">'+data[i].LieuDung+'</td>');
            var child_3 = $('<td class="w-8 text-ellipsis t-c">'+data[i].SoLuong+'</td>');
            var child_4 = $('<td class="w-15 text-ellipsis t-c">'+data[i].DonVi+'</td>');
            var child_5 = $('<td class="w-20 text-ellipsis t-c">'+formatDate(data[i].NgayYL)+'</td>');
            parent.append([child_1,child_2,child_3,child_4,child_5]);
            $("#tb-data-th-"+cls).append(parent);
        }
    }
    catch(e){}
    
}
function interfaceSwitchPTTT(action,id)
{
    addElementButtonCompare(id);
    if(action)
    {
        addElementMedicalRecordPTTTT(id);
        addElementMedicalRecordDataPTTTLR(_indexEncounter,"");
    }
    else
    {
        addElementMedicalRecordXNCLSTitle(id);
        addElementSLDataRecordDataXNCLS();
        addElementMedicalRecordPTTTL(id);
        addElementMedicalRecordPTTTR(id);
        addElementMedicalRecordDataPTTT(null);
        performJqueryPTTTDate();
    }
}
function addElementMedicalRecordPTTT(id)
{
    interfaceSwitchPTTT(true,id);
}
function addElementMedicalRecordPTTTT(id)
{
    var parent                                = $('<div class="div-fhir-gridview div-fhir-gridview-pttt"/>');
    var child                                 = $('<table class="w-100" />');
    var child_child                           = $('<tr class="c-w c-header-tb"/>');
    var child_child_2                         = $('<tr />');
    var child_child_child                     = $('<td />');
    var child_child_2_child                   = $('<td />');
    var child_child_child_child               = $('<table class="w-100" />');
    var child_child_2_child_child             = $('<div class="w-100 div-h-view- div-h-view-pttt" style="overflow:auto"/>');
    var child_child_child_child_child         = $('<tr />');
    var child_child_2_child_child_child       = $('<table id="tb-data-pttt-" class="w-100 tb-dp-data tb-dp-data-pttt"/>');
    var child_child_child_child_child_child_1 = $('<th class="w-35 ">Phẩu thuật - Thủ thuật</th>');
    var child_child_child_child_child_child_2 = $('<th class="w-20 ">Vị trí</th>');
    var child_child_child_child_child_child_3 = $('<th class="w-30 ">Chẩn đoán</th>');
    var child_child_child_child_child_child_4 = $('<th class="w-15 ">Ngày thực hiện</th>');
    child.append([child_child.append(child_child_child.append(child_child_child_child.append(child_child_child_child_child.append([child_child_child_child_child_child_1,child_child_child_child_child_child_2,child_child_child_child_child_child_3,child_child_child_child_child_child_4])))),child_child_2.append(child_child_2_child.append(child_child_2_child_child.append(child_child_2_child_child_child)))])
    $(".div-tab-"+id).append([parent.append(child)])
}
//Giao diện phần phẩu thuật thủ thuật
function addElementMedicalRecordPTTTL(id)
{
    addElementMedicalRecordPTTTT(id);
    $(".div-fhir-gridview-pttt").addClass("w-50 f-l").css({"border-right":"solid 2px #D7D7D7","padding":"0 0 0 1%"});
    $(".tb-dp-data-pttt").attr("id","tb-data-pttt-l");
}
//Giao diện phần phẩu thuật thủ thuật
function addElementMedicalRecordPTTTR(id)
{
    addElementMedicalRecordPTTTT(id);
    $(".div-fhir-gridview-pttt:last-child").addClass("w-50 f-l").css({"padding":"0 1% 0 0"});
    $(".tb-dp-data-pttt:eq(1)").attr("id","tb-data-pttt-r");
}
function addElementMedicalRecordDataPTTT(id)
{
    $("#tb-data-pttt-l , #tb-data-pttt-r").empty();
    if(id!=null)
        _indexEncounter = _dtLstEncounter.findIndex(x=>x.EncounterResourceID == id);
    if (_indexEncounter == _dtLstEncounter.length-1 && _dtLstEncounter.length-1 > 0)
    {
        _indexEncounter2 = _indexEncounter;
        _indexEncounter  = _indexEncounter -1;
    }
    else if(_indexEncounter < _dtLstEncounter.length-1 )
        _indexEncounter2 = _indexEncounter +1 ;
    if(_indexEncounter  !=null) addElementMedicalRecordDataPTTTLR(_indexEncounter,"l");
    if(_indexEncounter2 !=null) addElementMedicalRecordDataPTTTLR(_indexEncounter2,"r");
}
async function addElementMedicalRecordDataPTTTLR(index,cls)
{
    var data = await getDataXNCLSBYECT("api/BenhAn/GetDetais/"+ _dtLstEncounter[index]._Id_emr);
    if(safeRead(data,"DS_PhauThuatThuThuat")) data = data.DS_PhauThuatThuThuat;
    try
    {
        for(var i=0;i<data.length;i++)
        {
            var parent  = $('<tr />')
            //var child_1 = $('<td class="w-35" title="'+data[i].resource.code.text+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+data[i].resource.resourceType+"&id="+data[i].resource.id)+'">'+data[i].resource.code.text+'</a></td>');
            var child_1 = $('<td class="w-35" title="'+data[i].Ma+" - "+data[i].Ten+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(data[i].Resource,"TypeName")+"&id="+safeRead(data[i].Resource,"Id"))+'"><div class="w-100">'+data[i].Ma+'</div><div class="w-100">'+data[i].Ten+'</div></a></td>');
            var child_2 = $('<td class="w-20"></td>');
            var child_3 = $('<td class="w-30" title="'+safeRead(data[i].ChanDoan,"MaBenh")+" - "+safeRead(data[i].ChanDoan,"TenBenh")+'"><div class="w-100">'+safeRead(data[i].ChanDoan,"MaBenh")+'</div><div class="w-100">'+safeRead(data[i].ChanDoan,"TenBenh")+'</div></td>');
            var child_4 = $('<td class="w-15" title="'+formatDateTime(data[i].NgayThucHien)+'">'+formatDateTime(data[i].NgayThucHien)+'</td>');
            if(Array.isArray(data[i].ViTriTrenCoThe))
            {
                for(var x = 0 ; x<data[i].ViTriTrenCoThe.length ;x++)
                {
                    var parentbody = $('<div title="'+data[i].ViTriTrenCoThe[x].Ma+" - "+data[i].ViTriTrenCoThe[x].Ten+'"><span>'+data[i].ViTriTrenCoThe[x].Ma+" - "+data[i].ViTriTrenCoThe[x].Ten+'</span></div>')
                    child_2.append(parentbody);
                }
            }
            parent.append([child_1,child_2,child_3,child_4]);
            $("#tb-data-pttt-"+cls).append(parent);
        }
    }
    catch(ex){}
}
function interfaceSwitchDBB(action,id)
{
    addElementButtonCompare(id);
    if(action)
    {
        addElementMedicalRecordDBBB(id);
        addElementMedicalRecordDataDBBLR(_indexEncounter,"");
    }
    else
    {
        addElementMedicalRecordXNCLSTitle(id);
        addElementSLDataRecordDataXNCLS();
        addElementMedicalRecordDBBL(id);
        addElementMedicalRecordDBBR(id);
        addElementMedicalRecordDataDBB(null);
        performJqueryDBBDate();
    }
}
function addElementMedicalRecordDBB(id)
{
    interfaceSwitchDBB(true,id);
}
function addElementMedicalRecordDBBB(id)
{
    var parent                                = $('<div class="div-fhir-gridview div-fhir-gridview-dbb"/>');
    var child                                 = $('<table class="w-100" />');
    var child_child                           = $('<tr class="c-w c-header-tb"/>');
    var child_child_2                         = $('<tr />');
    var child_child_child                     = $('<td />');
    var child_child_2_child                   = $('<td />');
    var child_child_child_child               = $('<table class="w-100" />');
    var child_child_2_child_child             = $('<div class="w-100 div-h-view- div-h-view-dbb" style="overflow:auto"/>');
    var child_child_child_child_child         = $('<tr />');
    var child_child_2_child_child_child       = $('<table id="tb-data-dbb-" class="w-100 tb-dp-data tb-dp-data-dbb"/>');
    var child_child_child_child_child_child_1 = $('<th class="w-15 ">Ngày</th>');
    var child_child_child_child_child_child_2 = $('<th class="w-20 ">Phẩu thuật</th>');
    var child_child_child_child_child_child_3 = $('<th class="w-25 ">Hội chẩn</th>');
    var child_child_child_child_child_child_4 = $('<th class="w-40 ">Diễn biến</th>');
    child.append([child_child.append(child_child_child.append(child_child_child_child.append(child_child_child_child_child.append([child_child_child_child_child_child_1,child_child_child_child_child_child_2,child_child_child_child_child_child_3,child_child_child_child_child_child_4])))),child_child_2.append(child_child_2_child.append(child_child_2_child_child.append(child_child_2_child_child_child)))])
    $(".div-tab-"+id).append([parent.append(child)])
}
//Giao diện phần diễn biến bệnh
function addElementMedicalRecordDBBL(id)
{
    addElementMedicalRecordDBBB(id);
    $(".div-fhir-gridview-dbb").addClass("w-50 f-l").css({"border-right":"solid 2px #D7D7D7","padding":"0 0 0 1%"});
    $(".tb-dp-data-dbb").attr("id","tb-data-dbb-l");
}
//Giao diện phần diễn biến bệnh
function addElementMedicalRecordDBBR(id)
{
    addElementMedicalRecordDBBB(id);
    $(".div-fhir-gridview-dbb:last-child").addClass("w-50 f-l").css({"padding":"0 1% 0 0"});
    $(".tb-dp-data-dbb:eq(1)").attr("id","tb-data-dbb-r");
}
function addElementMedicalRecordDataDBB(id)
{
    $("#tb-data-dbb-l , #tb-data-dbb-r").empty();
    if(id!=null)
        _indexEncounter = _dtLstEncounter.findIndex(x=>x.EncounterResourceID == id);
    if (_indexEncounter == _dtLstEncounter.length-1 && _dtLstEncounter.length-1 > 0)
    {
        _indexEncounter2 = _indexEncounter;
        _indexEncounter  = _indexEncounter -1;
    }
    else if(_indexEncounter < _dtLstEncounter.length-1 )
        _indexEncounter2 = _indexEncounter +1 ;
    if(_indexEncounter  !=null) addElementMedicalRecordDataDBBLR(_indexEncounter,"l");
    if(_indexEncounter2 !=null) addElementMedicalRecordDataDBBLR(_indexEncounter2,"r");
}
async function addElementMedicalRecordDataDBBLR(index,cls)
{
    var data = await getDataXNCLSBYECT("api/BenhAn/GetDetais/"+ _dtLstEncounter[index]._Id_emr);
    if(safeRead(data,"DS_DienBienBenh")) data = data.DS_DienBienBenh
    try
    {
        for(var i=0;i<data.length;i++)
        {
            var parent  = $('<tr />')
            //var child_1 = $('<td class="w-35" title="'+data[i].resource.code.text+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+data[i].resource.resourceType+"&id="+data[i].resource.id)+'">'+data[i].resource.code.text+'</a></td>');
            var child_1 = $('<td class="w-15" title="'+formatDateTime(data[i].NgayYL)+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(data[i].Resource,"TypeName")+"&id="+safeRead(data[i].Resource,"Id"))+'">'+formatDateTime(data[i].NgayYL)+'</a></td>');
            var child_2 = $('<td class="w-20" title="'+safeRead(data[i],"PhauThuat")+'">'+safeRead(data[i],"PhauThuat")+'</td>');
            var child_3 = $('<td class="w-25" title="'+safeRead(data[i],"HoiChuan")+'">'+safeRead(data[i],"HoiChuan")+'</td>');
            var child_4 = $('<td class="w-40" title="'+safeRead(data[i],"DienBien")+'" />');
            var split = safeRead(data[i],"DienBien")!="" ? safeRead(data[i],"DienBien").split(". ") : [];
            for(var x = 0 ; x < split.length ; x++)
            {
                var child_4_child = $('<p>'+split[x]+'</p>');
                child_4.append(child_4_child);
            }
            parent.append([child_1,child_2,child_3,child_4]);
            $("#tb-data-dbb-"+cls).append(parent);
        }
    }catch(ex){}
    
}
function interfaceSwitchCDHA(action,id)
{
    addElementButtonCompare(id);
    if(action)
    {
        addElementMedicalRecordCDHAA(id);
        addElementMedicalRecordDataCDHALR(_indexEncounter,"");
    }
    else
    {
        addElementMedicalRecordXNCLSTitle(id);
        addElementSLDataRecordDataXNCLS();
        addElementMedicalRecordCDHAL(id);
        addElementMedicalRecordCDHAR(id);
        addElementMedicalRecordDataCDHA(null);
        performJqueryCDHADate();
    }
}
function addElementMedicalRecordCDHA(id)
{
    interfaceSwitchCDHA(true,id);
}
function addElementMedicalRecordCDHAA(id)
{
    var parent                                = $('<div class="div-fhir-gridview div-fhir-gridview-cdha"/>');
    var child                                 = $('<table class="w-100" />');
    var child_child                           = $('<tr class="c-w c-header-tb"/>');
    var child_child_2                         = $('<tr />');
    var child_child_child                     = $('<td />');
    var child_child_2_child                   = $('<td />');
    var child_child_child_child               = $('<table class="w-100" />');
    var child_child_2_child_child             = $('<div class="w-100 div-h-view- div-h-view-cdha" style="overflow:auto"/>');
    var child_child_child_child_child         = $('<tr />');
    var child_child_2_child_child_child       = $('<table id="tb-data-cdha-" class="w-100 tb-dp-data tb-dp-data-cdha"/>');
    var child_child_child_child_child_child_1 = $('<th class="w-25 ">Tên dịch vụ</th>');
    var child_child_child_child_child_child_2 = $('<th class="w-15 ">Bệnh</th>');
    var child_child_child_child_child_child_3 = $('<th class="w-25 ">Mô tả</th>');
    var child_child_child_child_child_child_4 = $('<th class="w-20 ">Kết luận</th>');
    var child_child_child_child_child_child_5 = $('<th class="w-15  t-r">Ngày TH</th>');
    child.append([child_child.append(child_child_child.append(child_child_child_child.append(child_child_child_child_child.append([child_child_child_child_child_child_1,child_child_child_child_child_child_2,child_child_child_child_child_child_3,child_child_child_child_child_child_4,child_child_child_child_child_child_5])))),child_child_2.append(child_child_2_child.append(child_child_2_child_child.append(child_child_2_child_child_child)))])
    $(".div-tab-"+id).append([parent.append(child)])
}
//Giao diện phần chẩn đoán hình ảnh
function addElementMedicalRecordCDHAL(id)
{
    addElementMedicalRecordCDHAA(id);
    $(".div-fhir-gridview-cdha").addClass("w-50 f-l").css({"border-right":"solid 1px #D7D7D7","padding":"0 0 0 1%"});
    $(".tb-dp-data-cdha").attr("id","tb-data-cdha-l");
}
//Giao diện phần chẩn đoán hình ảnh
function addElementMedicalRecordCDHAR(id)
{
    addElementMedicalRecordCDHAA(id);
    $(".div-fhir-gridview-cdha:last-child").addClass("w-50 f-l").css({"padding":"0 1% 0 0"});
    $(".tb-dp-data-cdha:eq(1)").attr("id","tb-data-cdha-r");
}
function addElementMedicalRecordDataCDHA(id)
{
    $("#tb-data-cdha-l , #tb-data-cdha-r").empty();
    if(id!=null)
        _indexEncounter = _dtLstEncounter.findIndex(x=>x.EncounterResourceID == id);
    if (_indexEncounter == _dtLstEncounter.length-1 && _dtLstEncounter.length-1 > 0)
    {
        _indexEncounter2 = _indexEncounter;
        _indexEncounter  = _indexEncounter -1;
    }
    else if(_indexEncounter < _dtLstEncounter.length-1 )
        _indexEncounter2 = _indexEncounter +1 ;
    if(_indexEncounter  !=null) addElementMedicalRecordDataCDHALR(_indexEncounter,"l");
    if(_indexEncounter2 !=null) addElementMedicalRecordDataCDHALR(_indexEncounter2,"r");
}
async function addElementMedicalRecordDataCDHALR(index,cls)
{
    var data = await getDataXNCLSBYECT("api/BenhAn/GetDetais/"+ _dtLstEncounter[index]._Id_emr);
    if(safeRead(data,"DS_ChanDoanHinhAnh")) data = data.DS_ChanDoanHinhAnh;
    try
    {
        for(var i=0;i<data.length;i++)
        {
            var parent  = $('<tr />')
            var child_1 = $('<td class="w-25" title="'+data[i].Ma+" - "+data[i].Ten+'"><a target="_blank" rel="noopener noreferrer" href="'+(process.env.PUBLIC_URL+"/FhirLV/fhir?resourcetype="+safeRead(data[i].Resource,"TypeName")+"&id="+safeRead(data[i].Resource,"Id"))+'"><div class="w-100">'+data[i].Ma+'</div><div class="w-100">'+data[i].Ten+'</div></a></td>');
            var child_2 = $('<td class="w-15" title="'+(safeRead(data[i].ChanDoan,"MaBenh")+" - "+safeRead(data[i].ChanDoan,"TenBenh"))+'"><div class="w-100">'+safeRead(data[i].ChanDoan,"MaBenh")+'</div><div class="w-100">'+safeRead(data[i].ChanDoan,"TenBenh")+'</div></td>');
            var child_3 = $('<td class="w-25" title="'+safeRead(data[i],"MoTa")+'"><span>'+safeRead(data[i],"MoTa")+'</span></td>');
            var child_4 = $('<td class="w-20" title="'+safeRead(data[i],"KetLuan")+'">'+safeRead(data[i],"KetLuan")+'</td>');
            var child_5 = $('<td class="w-15 t-r" title="'+formatDateTime(data[i].NgayThucHien)+'">'+formatDateTime(data[i].NgayThucHien)+'</td>');
            parent.append([child_1,child_2,child_3,child_4,child_5]);
            $("#tb-data-cdha-"+cls).append(parent);
        }
    }
    catch(ex){}
}
//Giao diện phần tiên lượng
function addElementMedicalRecordTL()
{
    var parent  = $('<div />');
    var child = $('<div><div class="w-12 f-l"><span class="f-w-400">Tiên lượng: </span></div><div class="w-88 f-l"><span class="f-w-500">'+safeRead(_dtDTPT,"TienLuong")+'</span></div></div>');
    parent.append([child]);
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần Hướng điều trị
function addElementMedicalRecordHDT()
{
    var parent  = $('<div />');
    var child = $('<div><div class="w-12 f-l"><span class="f-w-400">Hướng điều trị: </span></div><div class="w-88 f-l"><span class="f-w-500">'+safeRead(_dtDTPT,"HuongDieuTri")+'</span></div></div>');
    parent.append([child]);
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần phẩu thuật
function addElementMedicalRecordPT()
{
    var parent                = $('<div class="div-pt-tt"/>');
    var child_1               = $('<div><span>Phẩu thuật - Thủ thuật</span></div>');
    var child_2               = $('<div style="display:grid"/>');
    var child_2_child         = $('<div class="w-100"></div>');
    var child_2_child_child_1 = $('<div class="w-15 f-l">Ngày , giờ</div>');
    var child_2_child_child_2 = $('<div class="w-20 f-l">Tên</div>');
    var child_2_child_child_3 = $('<div class="w-35 f-l">Phương pháp</div>');
    var child_2_child_child_4 = $('<div class="w-15 f-l">Bác sĩ phẩu thuật</div>');
    var child_2_child_child_5 = $('<div class="w-15 f-l">Bác sĩ gây mê</div>');
    child_2_child.append([
        child_2_child_child_1,
        child_2_child_child_2,
        child_2_child_child_3,
        child_2_child_child_4,
        child_2_child_child_5
    ])
    child_2.append(child_2_child);
    for(var i=0;i<_dtDTPT.DS_PhauThuatThuThuat.length;i++)
    {
        var child_2_child         = $('<div class="w-100"></div>');
        var child_2_child_child_1 = $('<div class="w-15 text-at-break-line f-l">'+formatDateTime(_dtDTPT.DS_PhauThuatThuThuat[i].NgayThucHien)+'</div>');
        var child_2_child_child_2 = $('<div class="w-20 text-at-break-line f-l">'+safeRead(_dtDTPT.DS_PhauThuatThuThuat[i],"Ten")+'</div>');
        var child_2_child_child_3 = $('<div class="w-35 text-at-break-line f-l">'+safeRead(_dtDTPT.DS_PhauThuatThuThuat[i],"PhuongPhap")+'</div>');
        var child_2_child_child_4 = $('<div class="w-15 text-at-break-line f-l">'+safeRead(_dtDTPT.DS_PhauThuatThuThuat[i],"BsPhauThuat")+'</div>');
        var child_2_child_child_5 = $('<div class="w-15 text-at-break-line f-l">'+safeRead(_dtDTPT.DS_PhauThuatThuThuat[i],"BsGayMe")+'</div>');
        child_2_child.append([
            child_2_child_child_1,
            child_2_child_child_2,
            child_2_child_child_3,
            child_2_child_child_4
        ])
        child_2.append(child_2_child);
    }
    parent.append([child_1,child_2]);
    parent.appendTo($(".div-mc-rc"));
}
//Giao diện phần thủ thuật
function addElementMedicalRecordTT()
{
    var parent         = $('<div class="div-pt-tt"/>');
    var child_1        = $('<div><span>Thủ thuật</span></div>');
    var child_2        = $('<div style="display:grid"/>');
    var child_2_child  = $('<div class="w-100"></div>');
    var child_2_child_child_1 = $('<div class="w-15 f-l">Ngày , giờ</div>');
    var child_2_child_child_2 = $('<div class="w-35 f-l">Phương pháp</div>');
    var child_2_child_child_3 = $('<div class="w-50 f-l">Lý do</div>');
    child_2_child.append([
        child_2_child_child_1,
        child_2_child_child_2,
        child_2_child_child_3
    ])
    child_2.append(child_2_child);
    for(var i=0;i<_dtDTPT.DS_PhauThuat.length;i++)
    {
        var child_2_child         = $('<div class="w-100"></div>');
        var child_2_child_child_1 = $('<div class="w-15 f-l">'+formatDateTime(_dtDTPT.DS_PhauThuat[i].NgayThucHien)+'</div>');
        var child_2_child_child_2 = $('<div class="w-35 f-l">'+_dtDTPT.DS_PhauThuat[i].PhuongPhap+'</div>');
        var child_2_child_child_3 = $('<div class="w-50 f-l">'+_dtDTPT.DS_PhauThuat[i].LyDo+'</div>');
        child_2_child.append([
            child_2_child_child_1,
            child_2_child_child_2,
            child_2_child_child_3
        ])
        child_2.append(child_2_child);
    }
    parent.append([child_1,child_2]);
    parent.appendTo($(".div-mc-rc"));
}

//Giao diện phần bác sĩ điều trị
function addElementMedicalRecordBS()
{
    var parent                = $('<div />');
    var child_1               = $('<div><span>Bác sĩ</span></div>');
    var child_2               = $('<div style="display:grid"/>');
    var child_2_child_1       = $('<div><div class="w-18 f-l"><span>1. Giám đốc: </span></div><div class="w-82 f-l"><span class="f-w-500">'+safeRead(_dtDTPT,"GiamDoc.Ten")+'</span></div></div>');
    var child_2_child_2       = $('<div><div class="w-18 f-l"><span>2. Trưởng khoa: </span></div><div class="w-82 f-l"><span class="f-w-500">'+safeRead(_dtDTPT,"TruongKhoa.Ten")+'</span></div></div>');
    var child_2_child_3       = $('<div><div class="w-18 f-l"><span>3. Bác sĩ điều trị: </span></div><div class="w-82 f-l"><span class="f-w-500">'+safeRead(_dtDTPT,"BsDieuTri.Ten")+'</span></div></div>');
    var child_2_child_4       = $('<div><div class="w-18 f-l"><span>4. Bác sĩ làm bệnh án: </span></div><div class="w-82 f-l"><span class="f-w-500">'+safeRead(_dtDTPT,"BsLamBenhAn.Ten")+'</span></div></div>');
    parent.append([child_1,child_2.append([
        child_2_child_1,
        child_2_child_2,
        child_2_child_3,
        child_2_child_4
    ])]);
    parent.appendTo($(".div-mc-rc"));
}
function convertVN(id,param)
{
    try
    {
        var result = EnumVN[id][param];
        if(id == "GioiTinh")
        {
            var gt = result;
            if(gt==undefined) return "Không xác định";
            return gt;
        }
        else if(param == undefined || param == null || result == undefined) return ""
        return result;
    }
    catch(ex)
    {
        return ""
    }
}
function getIDResource(typeName,category)
{
    var data = [];
    if(category == null || category == "") data =  _dtDTPT.KhamBenh.DauHieuSinhTon.Resource.filter(x=>x.TypeName==typeName&&(x.Category == null || x.Category == ""));
    else data = _dtDTPT.KhamBenh.DauHieuSinhTon.Resource.filter(x=>x.TypeName==typeName&&x.Category!=null&&x.Category.includes(category));
    return data
}
function convertHasValueToVN(val)
{
    switch(val)
    {
        case "ClinicalImpression" :
        {
            return "Diễn biến bệnh";
            break;
        }
        case "DiagnosticReport" :
        {
            return "Chẩn đoán hình ảnh";
            break;
        }
        case "Observation_LabResult" :
        {
            return "Xét nghiệm";
            break;
        }
        case "Medication" :
        {
            return "Thuốc";
            break;
        }
        case "Procedure" :
        {
            return "Phẩu thuật - Thủ thuật";
            break;
        }
    }
}
function addElementHasValueTab(action,id)
{
    $(".div-tab-"+id).empty();
    switch(action)
    {
        //Bệnh án
        case "ba" :
        {
            addElementMedicalRecord(0,id);
            break;
        }
        //Xét nghiệm
        case 'Observation_LabResult' :
        {
            addElementXNCLS(id);
            break;
        }
         //Thuốc
        case 'Medication' :
        {
            addElementMedicalRecordTH(id);
            break;
        }
        // Phẩu thuật thủ thuật
        case 'Procedure' :
        {
            addElementMedicalRecordPTTT(id);
            break;
        }
        //Diễn biến bệnh
        case 'ClinicalImpression' :
        {
            addElementMedicalRecordDBB(id);
            break;
        }
        //Chẩn đoán hình ảnh
        case 'DiagnosticReport' :
        {
            addElementMedicalRecordCDHA(id);
            break;
        }
    }
}
export { addElementDetailPatientRecords , performJqueryXNCLS ,performJqueryXNCLSDate , performJqueryTHDate , performJqueryPTTTDate ,  addElementMedicalRecordTH  , addElementMedicalRecordPTTT , addElementMedicalRecordDBB , addElementMedicalRecordCDHA , addElementMedicalRecordDataPTTT , addElementXNCLS , setValElementDetailPatientRecords , performPatientRecords , getDataPatientResourceID , getDataPatientID ,  addElementMedicalRecord , convertVN }