export var _phamacy =
{
    cd : new Date(),
    hdiv : 706,
    h_bar  : 23,
    f_size : 12,
    filterD : {},
    color1 : ['#ACCD5C' , '#01B8AA','#058DC7' , '#ED561B', '#50B432', '#9A0034' , '#FFD75E' , '#DDDF00', '#24CBE5', '#64E572','#FF9655', '#FFF263', '#6AF9C4' , '#4267C2' , '#E78035'],
    color2 : ['#4267C2' , '#E78035','#ACCD5C' , '#01B8AA','#058DC7' , '#ED561B', '#50B432' , '#ACCD5C' , '#01B8AA','#058DC7' , '#ED561B', '#50B432', '#9A0034'],
    filterMA : 
    {
        ID : null,
        Find : null,
        RowsPerPage : 10,
        PageNumber : 1,
    },
}