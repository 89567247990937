import $ from 'jquery';
import { shortCutImplemented } from '../../Function/ui';
function closeNotification()
{
    $(".div-notification").css("display","none");
}
function returnForm()
{
    closeNotification();
}
var notification =
{
    notifaction:function(text)
    {
        var btnFooter = 
        $('<div class="w-20 h-100 c-w t-c c-p">Thoát</div>').unbind().on("click",closeNotification);
        $(".div-notification").css("display","block");
        $(".div-footer-notification").empty().append(btnFooter);
        $(".i-notification").addClass("shis-t-alarm c-w");
        $(".sp-title-notification").text("Thông báo");
        $(".div-notification-content").text(text);
        shortCutImplemented("enter",closeNotification);
    },
    notifactionF:function(text,step)
    {
        var btnFooter = 
        $('<div class="w-20 h-100 c-w t-c c-p">Thoát</div>').unbind().on("click",returnForm);
        $(".div-notification").css("display","block");
        $(".div-footer-notification").empty().append(btnFooter);
        $(".i-notification").addClass("shis-t-alarm c-w");
        $(".sp-title-notification").text("Thông báo");
        $(".div-notification-content").text(text);
        shortCutImplemented("enter",returnForm);
    },
    notifactionF2:function(text,_function)
    {
        var btnFooter = 
        $('<div class="w-20 h-100 c-w t-c c-p">Đồng ý</div>').unbind().on("click",_function);
        $(".div-notification").css("display","block");
        $(".div-footer-notification").empty().append(btnFooter);
        $(".i-notification").addClass("shis-t-alarm c-w");
        $(".sp-title-notification").text("Thông báo");
        $(".div-notification-content").text(text);
        shortCutImplemented("enter",returnForm);
    },
    requestconfirm:function(text,_function)
    {
       
        var btnFooter  = $('<div class="w-20 h-100 c-w t-c c-p">Thoát</div>').unbind().on("click",closeNotification);
        var btnFooter2 = $('<div class="w-20 h-100 c-w t-c c-p">Đồng ý</div>').unbind().on("click",_function);
        $(".div-notification").css("display","block");
        $(".div-footer-notification").empty().append([btnFooter,btnFooter2]);
        $(".i-notification").addClass("shis-t-alarm c-w");
        $(".sp-title-notification").text("Yêu cầu xác nhận");
        $(".div-notification-content").text(text);
        //shortCutImplemented("enter",returnForm);
    }
}
export {notification}
