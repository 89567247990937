import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home  from './Components/UI/Home/ui';
import Login from "./Components/UI/Login/ui/ui";
import DataJson from './Components/UI/PatientRecords/DetailPatientRecords/DataJson/ui';
import Demo from './Components/Demo/demo';
import ConfirmEmail from './Components/UI/Login/ConfirmEmail/ui';
import ResetPassword from './Components/UI/Login/RestPassword/ui';
import ChangePassword from './Components/UI/Login/ChangePassword/ui';
import LandingPage from './Components/UI/DefaultInterface/LandingPage/ui';
import AccountManagement from './Components/UI/DefaultInterface/Account/Management/ui';
import Share from './Components/UI/Share/ui';
function App() {
  return (
    <Router>
        <Switch>
            <Route path={`${process.env.PUBLIC_URL}/`} component={LandingPage} exact/>
            <Route path={`${process.env.PUBLIC_URL}/trang-chu`} component={Home}/>
            <Route path={`${process.env.PUBLIC_URL}/dang-nhap`} component={Login}  />
            <Route path={`${process.env.PUBLIC_URL}/demo`} component={Demo}  />
            <Route path={`${process.env.PUBLIC_URL}/FhirLV/fhir`} component={DataJson}  />
            <Route path={`${process.env.PUBLIC_URL}/account/management`} component={AccountManagement}  />
            <Route path={`${process.env.PUBLIC_URL}/account/confirm-email`} component={ConfirmEmail}  />
            <Route path={`${process.env.PUBLIC_URL}/account/resetPassword`} component={ResetPassword}  />
            <Route path={`${process.env.PUBLIC_URL}/account/change-password`} component={ChangePassword}  />
            <Route path={`${process.env.PUBLIC_URL}/share`} component={Share}  />
        </Switch>
    </Router>
  );
}

export default App;
