import React from 'react'
import logo from '../../../Images/fhir-services.png';
import { addElementChangePassword , performJQChangePassword } from './ui_fc'
export default class ChangePassword extends React.Component {
    componentWillMount()
    {
    }
    componentDidMount()
    {
        addElementChangePassword();
        performJQChangePassword();
    }
    render() {
        return (
            <div className="div-f-login-df row">
                <div className="div-f-login-l col-lg-6  f-l">
                    <div className="w-100 h-100 t-c">
                        <div>
                            <div>
                                <span>Cổng thông tin bệnh án điện tử</span>
                            </div>
                            <div>
                                <img className="w-100" src={logo}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-f-login-r col-lg-6 f-l div-content-login" />
                <div className="div-notification w-100 h-100">
                    <div>
                        <div className="w-100 h-18 color-w">
                            <div className="h-100">
                                <i className="i-notification"></i><span className="sp-title-notification c-w"/>
                            </div>
                        </div>
                        <div className="w-100 div-notification-content t-c" />
                        <div className="w-100 div-footer-notification" />
                    </div>
                </div>
            </div>
        )
    }
}
