import $ from 'jquery'
import { SendData } from '../../../Function/ui';
import { _height_window } from '../../../Variable/defaultvar';
import { notification } from '../../Notification/ui_fc';
//Giao diện trang login
function addElementConfirmEmail()
{
    var parent = $('<div class="w-100 h-100"/>');
    var child_1 = $('<div class="div-login w-100 h-100" />');
    var child_2 = $('<div class="div-notification w-100 h-100">');
    var child_1_child_1 = $('<div class="div-logo-login t-c" />');
    var child_1_child_2 = $('<div class="w-100 div-title-login t-c" />');
    var child_1_child_5 = $('<div class="w-100" />');
    var child_2_child_1 = $('<div/>');
    var child_1_child_1_child_1 = $('<div class="inline"/>');
    var child_1_child_1_child_2 = $('<div class="inline c-w"><p>FHIR</p></div>');
    var child_1_child_2_child_1 = $('<p>Xác thực tài khoản</p>');
    var child_1_child_2_child_2 = $('<p>Xác thực tài khoản để đăng nhập</p>');
    var child_1_child_5_child_1 = $('<div class="t-c c-w div-btn-login c-p"><p>Xác thực</p></div>');
    var child_1_child_5_child_2 = $('<div class="f-l c-p f-w-600 div-rt-login"><span>Quay lại</span></div>');
    var child_2_child_1_child_1 = $('<div class="w-100 color-w"><div class="h-100"><i class="i-notification"></i><span class="sp-title-notification c-w"/></div></div>');
    var child_2_child_1_child_2 = $('<div class="w-100 div-notification-content t-c"/>');
    var child_2_child_1_child_3 = $('<div class="w-100 h-18 div-footer-notification"/>');
    parent.append([
        child_1.append([
            child_1_child_1.append([
                child_1_child_1_child_1,
                child_1_child_1_child_2
            ]),
            child_1_child_2.append([
                child_1_child_2_child_1,
                child_1_child_2_child_2
            ]),
            child_1_child_5.append([
                child_1_child_5_child_1,
                child_1_child_5_child_2
            ])
        ]),
        child_2.append([
            child_2_child_1.append([
                child_2_child_1_child_1,
                child_2_child_1_child_2,
                child_2_child_1_child_3
            ])
        ])
    ]);
    parent.appendTo($(".div-content-login"));
}
function performJQConfirmEmail() {
    $(".div-content-login").css({height:(_height_window / 3),"overflow":"auto","margin-top":"12%"}); 
    $(".div-rt-login").unbind().on("click",returnLogin) ;
    $(".div-btn-login").unbind().on("click",confirmEmail);
}
function returnLogin()
{
    window.location.replace(process.env.PUBLIC_URL+"/dang-nhap");
}
function confirmEmail()
{
    var url = new URL(window.location.href);
    var obj = {};
    obj.userId = url.searchParams.get("userId");
    obj.Code   = url.searchParams.get("code");
    if(SendData("api/Account/confirm-email","get",obj))
        notification.notifactionF2("Thành công. Thực hiện lại đăng nhập.",returnLogin);
    else
        notification.notifaction("Có lỗi xảy ra.")
}
export { addElementConfirmEmail , performJQConfirmEmail , returnLogin}