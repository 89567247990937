var variable = 
{
    errMessenger :
    [
        "* Vui lòng nhập tài khoản .",
        "* Vui lòng nhập mật khẩu .",
        "* Email không đúng .",
        "Tài khoản hoặc mật khẩu không đúng .",
        "Tài khoản của bạn đã bị khóa .",
        "Chưa xác nhận tài khoản của bạn .",
        "* Vui lòng nhập mã bệnh viện .",
        "* Vui lòng nhập tên bệnh viện .",
        "* Mật khẩu chứa ít nhất 6 kí tự .",
        "* Mật khẩu không khớp .",
        "* Vui lòng nhập cơ quan chủ quản .",
        "* Không được trùng mật khẩu cũ ."
    ],
}
export {variable}