import { data } from 'jquery';
import React  from 'react';
import { formatDate, formatDateTime, formatTime, formatTimeDate, safeRead, SendData } from '../../Function/ui';
import Header from '../DefaultInterface/Header/ui';
import { convertVN } from '../PatientRecords/DetailPatientRecords/ui/ui_fc';
import './ui.css';
import { performShare } from './ui_fc';
export default class Share extends React.Component {
    constructor() {
        super();
        this.state = {
          data: {}
        }
      }
    componentWillMount()
    {
        var url = new URL(window.location.href);
        var id  = url.searchParams.get("id");
        var token = url.searchParams.get("token");
        document.cookie = 'access_Token='+ token +'; Path=/;';
        var data = SendData("api/BenhAn/GetDetais/"+id,"get",null)
        this.setState({data : data})
    }
    componentDidMount()
    {
        performShare();
    }
    render() {
        return (
            safeRead(this.state.data,"_id") != "" ?
            <div className="h-100">
                    <div className="div-content">
                        <div className="div-down-pdf" hidden>
                            <i className="fal fa-download fa-lg"></i>
                        </div>
                        <div className="container" style={{maxWidth: "100%",padding:"0 2%"}}>
                            <div className="div-m-r-d t-c" style={{margin:0,borderTop:"none",borderLeft:"none",borderRight:"none"}}>
                                <span>Bệnh án</span>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Quản lý người bệnh</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                    <div className="col">
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Họ và tên:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{safeRead(this.state.data.HanhChinh,"HoTen")==""?"*****":safeRead(this.state.data.HanhChinh,"HoTen")}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Ngày sinh:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{safeRead(this.state.data.HanhChinh,"NgaySinh")==""?"*****":formatDate(safeRead(this.state.data.HanhChinh,"NgaySinh"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Giới tính:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{convertVN("GioiTinh",safeRead(this.state.data.HanhChinh,"GioiTinh"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Vào viện:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{safeRead(this.state.data.QuanLyNguoiBenh,"NgayVaoVien")!=""?formatTimeDate(this.state.data.QuanLyNguoiBenh.NgayVaoVien):""}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Trực tiếp vào:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{convertVN("TrucTiepVao",safeRead(this.state.data.QuanLyNguoiBenh,"TrucTiepVao"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1">
                                            <div className="col">
                                                <span className="f-w-600">Khoa điều trị:</span>
                                            </div>
                                            <div className="col">
                                                {safeRead(this.state.data,"QuanLyNguoiBenh.DS_DieuTriTaiKhoa")!=""?
                                                    this.state.data.QuanLyNguoiBenh.DS_DieuTriTaiKhoa.map((item,v)=>(
                                                        <div className="row row-cols-2" key={v}>
                                                            <div className="col-sm-8 col-lg-9 text-ellipsis-n-w">{item.TenKhoa}</div>
                                                            <div className="col-sm-4 col-lg-3 text-ellipsis-n-w">{formatTimeDate(item.NgayDieuTri)}</div>
                                                        </div>
                                                    )):null
                                                }
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div className="col">
                                    <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Nơi giới thiệu:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{convertVN("NoiGioiThieu",safeRead(this.state.data.QuanLyNguoiBenh,"NoiGioiThieu"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Vào viện lần thứ:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{safeRead(this.state.data.QuanLyNguoiBenh,"LanVaoVienThu")}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Chuyển viện:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{convertVN("ChuyenVien",safeRead(this.state.data.QuanLyNguoiBenh,"TuyenChuyenDi"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Chuyển đến:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{safeRead(this.state.data.QuanLyNguoiBenh,"NoiChuyenDi")}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Ra viện:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{safeRead(this.state.data.QuanLyNguoiBenh,"NgayRaVien")!= "" ? formatTimeDate(this.state.data.QuanLyNguoiBenh.NgayRaVien):""}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 bdbt">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Hình thức ra viện:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{convertVN("HinhThucRaVien",safeRead(this.state.data.QuanLyNguoiBenh,"HinhThucRaVien"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-2">
                                            <div className="col-sm-4 col-lg-3">
                                                <span className="f-w-600">Tổng số ngày điều trị:</span>
                                            </div>
                                            <div className="col-sm-8 col-lg-9">
                                                <span>{safeRead(this.state.data.QuanLyNguoiBenh,"TongSoNgayDieuTri")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Chẩn đoán</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                    <div className="col">
                                        {safeRead(this.state.data,"ChanDoan.VaoKhoa")!=""?
                                            this.state.data.ChanDoan.VaoKhoa.map((item,v)=>(
                                                <div className="row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbtchild">
                                                    <div className="col-sm-4 col-lg-3">
                                                        <span className="f-w-600">+ {item.MoTa}:</span>
                                                    </div>
                                                    <div className="col-sm-8 col-lg-9 p-d-l-6">
                                                        <span>{item.TenBenh}</span>
                                                    </div>
                                                </div>
                                            )):null
                                        }
                                        <div className="row">
                                            <div className="row row-cols-2">
                                                <div className="col-sm-4 col-lg-3">
                                                    <span className="f-w-600">Nguyên nhân:</span>
                                                </div>
                                                <div className="col-sm-8 col-lg-9 text-ellipsis-n-w">
                                                    <span>{safeRead(data,"ChanDoan.TaiBienBienChung.NguyenNhan")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row col-12">
                                            <span className="f-w-600">Ra viện:</span>
                                        </div>
                                        {safeRead(this.state.data,"ChanDoan.RaVien")!=""?
                                            this.state.data.ChanDoan.RaVien.map((item,v)=>(
                                                <div className="row row-cols-xs-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbtchild">
                                                    <div className="col-sm-4 col-lg-3">
                                                        <span className="f-w-600">+ {item.MoTa}:</span>
                                                    </div>
                                                    <div className="col-sm-8 col-lg-9 p-d-l-6">
                                                        <span>{item.TenBenh}</span>
                                                    </div>
                                                </div>
                                            )):null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Tình trạng ra viện</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                    <div className="col">
                                        <div className="row row-cols-2">
                                            <div className="col-sm-5 col-lg-6">
                                                <span className="f-w-600">Kết quả điều trị:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                <span>{convertVN("KetQuaDieuTri",safeRead(this.state.data.TinhTrangRaVien,"KetQuaDieuTri"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                            <div className="col-sm-5 col-lg-6">
                                                <span className="f-w-600">Giải phẩu bệnh (khi có sinh thiết):</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                <span>{convertVN("GiaiPhauBenh",safeRead(this.state.data.TinhTrangRaVien,"GiaiPhauBenh"))}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="row row-cols-2">
                                            <div className="col-sm-5 col-lg-6">
                                                <span className="f-w-600">Tình hình tử vong:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                <span>{convertVN("TinhHinhTuVong",safeRead(this.state.data.TinhTrangRaVien,"TinhHinhTuVong.NguyenNhanTuVong"))}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                            <div className="col-sm-5 col-lg-6">
                                                <span className="f-w-600">Nguyên nhân chính tử vong:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                <span>{safeRead(this.state.data.TinhTrangRaVien,"NguyenNhanChinhTuVong.TenBenh")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div style={{padding:0}}>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                            <div className="col-sm-5 col-lg-2">
                                                <span className="f-w-600">Lý do vào viện:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-10 p-d-l-4">
                                                <span>{this.state.data.LyDoVaoVien}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Hỏi bệnh</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1" >
                                    <div className="col">
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                            <div className="col-12">
                                                <span className="f-w-600">Quá trình bệnh lý:</span>
                                            </div>
                                            <div className="col-12 p-d-l-4">
                                                <span>{safeRead(this.state.data.HoiBenh,"QuaTrinhBenhLy")}</span>
                                            </div>
                                        </div>
                                        <div className="row col-12">
                                            <span className="f-w-600">Tiền sử bệnh:</span>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                            <div className="col-sm-5 col-lg-2">
                                                <span className="f-w-600">+ Bản thân:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-10 p-d-l-4">
                                                <span>{safeRead(this.state.data.HoiBenh,"TienSuBenh.BanThan")}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2">
                                            <div className="col-sm-5 col-lg-2">
                                                <span className="f-w-600">+ Gia đình:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-10 p-d-l-4">
                                                <span>{safeRead(this.state.data.HoiBenh,"TienSuBenh.GiaDinh")}</span>
                                            </div>
                                        </div>
                                        <div className="row col-12">
                                            <span className="f-w-600">Đặc điểm liên quan:</span>
                                        </div>
                                        {safeRead(this.state.data.HoiBenh,"TienSuBenh.DacDiemLienQuan")!=""?
                                            this.state.data.HoiBenh.TienSuBenh.DacDiemLienQuan.map((i,v)=>(
                                                <div className="row p-d-l-4" key={v}>
                                                    <div className="col-sm-5 col-lg-4">
                                                        <span>{formatDateTime(i.ThoiGian)}</span>
                                                    </div>
                                                    <div className="col-sm-7 col-lg-6">
                                                        <span>{i.Ten}</span>
                                                    </div>
                                                </div>
                                            )):null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Khám bệnh</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                    <div className="col">
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 bdbt">
                                            <div className="col-12">
                                                <span className="f-w-600">Toàn thân:</span>
                                            </div>
                                            <div className="col-12">
                                                {safeRead(this.state.data.KhamBenh,"ToanThan")!=""?
                                                    safeRead(this.state.data.KhamBenh.ToanThan,"BoPhanCoThe")=="" && safeRead(this.state.data.KhamBenh.ToanThan,"DacDiem")=="" ?
                                                        <div className="col-12">
                                                            <span  className="f-w-600">- {this.state.data.KhamBenh.ToanThan.MoTa}</span>
                                                        </div>:
                                                        safeRead(this.state.data.KhamBenh.ToanThan,"BoPhanCoThe")!="" && safeRead(this.state.data.KhamBenh.ToanThan,"DacDiem")!=""?
                                                        <div className="row bdbt">
                                                            <div className="col-12">
                                                                <span className="f-w-600">- {this.state.data.KhamBenh.ToanThan.BoPhanCoThe}</span>
                                                            </div>
                                                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-d-l-4">
                                                                <div className="col-sm-5 col-lg-6">
                                                                    <span className="f-w-600">{this.state.data.KhamBenh.ToanThan.DacDiem}:</span>
                                                                </div>
                                                                <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                                    <span>+ {this.state.data.KhamBenh.ToanThan.MoTa}</span>
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                                            <div className="col-sm-5 col-lg-6">
                                                                <span className="f-w-600">- {safeRead(this.state.data.KhamBenh.ToanThan,"BoPhanCoThe")!=""?this.state.data.KhamBenh.ToanThan.BoPhanCoThe:this.state.data.KhamBenh.ToanThan.DacDiem}</span>
                                                            </div>
                                                            <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                                <span>+ {this.state.data.KhamBenh.ToanThan.MoTa}</span>
                                                            </div>
                                                        </div>
                                                    :null
                                                }
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 bdbt">
                                            <div className="col-12 ">
                                                <span className="f-w-600">Các cơ quan:</span>
                                            </div>
                                            <div className="col-12">
                                                {safeRead(this.state.data.KhamBenh,"CacCoQuan")!=""?
                                                    safeRead(this.state.data.KhamBenh.CacCoQuan,"BoPhanCoThe")=="" && safeRead(this.state.data.KhamBenh.CacCoQuan,"DacDiem")=="" ?
                                                        <div className="col-12">
                                                            <span>- {this.state.data.KhamBenh.CacCoQuan.MoTa}</span>
                                                        </div>:
                                                        safeRead(this.state.data.KhamBenh.CacCoQuan,"BoPhanCoThe")!="" && safeRead(this.state.data.KhamBenh.CacCoQuan,"DacDiem")!=""?
                                                        <div className="row bdbt">
                                                            <div className="col-12">
                                                                <span className="f-w-600">- {this.state.data.KhamBenh.CacCoQuan.BoPhanCoThe}</span>
                                                            </div>
                                                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-d-l-4">
                                                                <div className="col-sm-5 col-lg-6">
                                                                    <span className="f-w-600">{this.state.data.KhamBenh.CacCoQuan.DacDiem}:</span>
                                                                </div>
                                                                <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                                    <span>+ {this.state.data.KhamBenh.CacCoQuan.MoTa}</span>
                                                                </div>
                                                            </div>
                                                        </div>:
                                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                                            <div className="col-sm-5 col-lg-6">
                                                                <span className="f-w-600">- {safeRead(this.state.data.KhamBenh.CacCoQuan,"BoPhanCoThe")!=""?this.state.data.KhamBenh.CacCoQuan.BoPhanCoThe:this.state.data.KhamBenh.CacCoQuan.DacDiem}</span>
                                                            </div>
                                                            <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                                <span>+ {this.state.data.KhamBenh.CacCoQuan.MoTa}</span>
                                                            </div>
                                                        </div>
                                                    :null
                                                }
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 bdbt">
                                            <div className="col-12">
                                                <span className="f-w-600">Khám chuyên khoa:</span>
                                            </div>
                                            <div className="col-12">
                                                {safeRead(this.state.data.KhamBenh,"KhamChuyenKhoa")!=""?
                                                    safeRead(this.state.data.KhamBenh.KhamChuyenKhoa,"BoPhanCoThe")=="" && safeRead(this.state.data.KhamBenh.KhamChuyenKhoa,"DacDiem")=="" ?
                                                        <div className="col-12">
                                                            <span>- {this.state.data.KhamBenh.KhamChuyenKhoa.MoTa}</span>
                                                        </div>:
                                                        safeRead(this.state.data.KhamBenh.KhamChuyenKhoa,"BoPhanCoThe")!="" && safeRead(this.state.data.KhamBenh.KhamChuyenKhoa,"DacDiem")!=""?
                                                        <div className="row bdbt">
                                                            <div className="col-12">
                                                                <span className="f-w-600">- {this.state.data.KhamBenh.KhamChuyenKhoa.BoPhanCoThe}</span>
                                                            </div>
                                                            <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 p-d-l-4">
                                                                <div className="col-sm-5 col-lg-6">
                                                                    <span className="f-w-600">{this.state.data.KhamBenh.KhamChuyenKhoa.DacDiem}:</span>
                                                                </div>
                                                                <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                                    <span>+ {this.state.data.KhamBenh.KhamChuyenKhoa.MoTa}</span>
                                                                </div>
                                                            </div>
                                                        </div>:
                                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                                            <div className="col-sm-5 col-lg-6">
                                                                <span className="f-w-600">- {safeRead(this.state.data.KhamBenh.KhamChuyenKhoa,"BoPhanCoThe")!=""?this.state.data.KhamBenh.KhamChuyenKhoa.BoPhanCoThe:this.state.data.KhamBenh.KhamChuyenKhoa.DacDiem}</span>
                                                            </div>
                                                            <div className="col-sm-7 col-lg-6 p-d-l-4">
                                                                <span>+ {this.state.data.KhamBenh.KhamChuyenKhoa.MoTa}</span>
                                                            </div>
                                                        </div>
                                                    :null
                                                }
                                                
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                            <div className="col-12">
                                                <span className="f-w-600">Tóm tắt bệnh án:</span>
                                            </div>
                                            <div className="col-12">
                                                <span>{safeRead(this.state.data.KhamBenh,"TomTatBenhAn")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Chẩn đoán khi vào khoa điều trị</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                    <div className="col">
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                            <div className="col-sm-5 col-lg-3">
                                                <span className="f-w-600">Bệnh chính:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-9 p-d-l-4">
                                                <span>{safeRead(this.state.data.ChanDoanKhiVaoNoiDieuTri,"BenhChinh.TenBenh")}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                            <div className="col-sm-5 col-lg-3">
                                                <span className="f-w-600">Bệnh kèm theo:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-9 p-d-l-4">
                                                <span>{safeRead(this.state.data.ChanDoanKhiVaoNoiDieuTri,"BenhKemTheo.TenBenh")}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                            <div className="col-12">
                                                <span className="f-w-600">Phân biệt:</span>
                                            </div>
                                            <div className="col-12 p-d-l-4">
                                                <span>{safeRead(this.state.data.ChanDoanKhiVaoNoiDieuTri,"PhanBiet")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Xét Nghiệm</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                {this.state.data.DS_XetNghiem.length > 0 &&
                                    <div class="table-responsive-sm">
                                        <table class="table text-nowrap">
                                            <caption>Danh sách xét nghiệm</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Mã XN</th>
                                                    <th scope="col">Tên XN</th>
                                                    <th scope="col">Đơn vị</th>
                                                    <th scope="col">Kết quả</th>
                                                    <th scope="col">Ngày Y Lệnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.DS_XetNghiem.map((i,v)=>(
                                                        <tr key={v}>
                                                            <th scope="row">{v+1}</th>
                                                            <td>{i.MaXN}</td>
                                                            <td>{i.TenXN}</td>
                                                            <td>{i.DonVi}</td>
                                                            <td>{i.KetQua}</td>
                                                            <td>{formatTimeDate(i.NgayYL)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Phẩu thuật - Thủ thuật</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                {this.state.data.DS_PhauThuatThuThuat.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table text-nowrap">
                                            <caption>Danh sách phẩu thuật - thủ thuật</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Mã</th>
                                                    <th scope="col">Tên</th>
                                                    <th scope="col">Vị trí</th>
                                                    <th scope="col">Chẩn đoán</th>
                                                    <th scope="col">Lý do</th>
                                                    <th scope="col">Ngày TH</th>
                                                    <th scope="col">Phương pháp</th>
                                                    <th scope="col">Số ngày điều trị sau phẩu thuật</th>
                                                    <th scope="col">BS phẩu thuật</th>
                                                    <th scope="col">BS gây mê</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.DS_PhauThuatThuThuat.map((i,v)=>(
                                                        <tr key={v}>
                                                            <th scope="row">{v + 1}</th>
                                                            <td>{i.Ma}</td>
                                                            <td>{i.Ten}</td>
                                                            <td>
                                                                {i.ViTriTrenCoThe != null && i.ViTriTrenCoThe.length > 0 &&
                                                                    i.ViTriTrenCoThe.map((x,y)=>(
                                                                        <div key={"a"+y}>
                                                                            <span>{x.Ma} - {x.Ten}Ư</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </td>
                                                            <td>{safeRead(i.ChanDoan,"TenBenh")}</td>
                                                            <td>{i.LyDo}</td>
                                                            <td>{formatTimeDate(i.NgayThucHien)}</td>
                                                            <td>{i.PhuongPhap}</td>
                                                            <td>{i.SoNgayDieuTriSauPhauThuat}</td>
                                                            <td>{safeRead(i.BsPhauThuat,"Ten")}</td>
                                                            <td>{safeRead(i.BsGayMe,"Ten")}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Chẩn đoán hình ảnh</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                {this.state.data.DS_ChanDoanHinhAnh.length > 0 &&
                                    <div class="table-responsive-sm">
                                        <table class="table text-nowrap">
                                            <caption>Danh sách chẩn đoán hình ảnh</caption>
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Mã</th>
                                                    <th scope="col">Tên</th>
                                                    <th scope="col">Vị trí trên cơ thể</th>
                                                    <th scope="col">Lý do</th>
                                                    <th scope="col">Mô tả</th>
                                                    <th scope="col">Kết luận</th>
                                                    <th scope="col">Ngày thực hiện</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.data.DS_ChanDoanHinhAnh.map((i,v)=>(
                                                        <tr key={v}>
                                                            <th scope="row">{v + 1}</th>
                                                            <td>{i.Ma}</td>
                                                            <td>{i.Ten}</td>
                                                            <td>
                                                                {i.ViTriTrenCoThe != null && i.ViTriTrenCoThe.length > 0 &&
                                                                    i.ViTriTrenCoThe.map((x,y)=>(
                                                                        <div key={"a"+y}>
                                                                            <span>{x.Ma} - {x.Ten}Ư</span>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </td>
                                                            <td>{i.LyDo}</td>
                                                            <td>{i.MoTa}</td>
                                                            <td>{i.KetLuan}</td>
                                                            <td>{formatTimeDate(i.NgayThucHien)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div style={{padding:0}}>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                            <div className="col-sm-5 col-lg-2">
                                                <span className="f-w-600">Tiên lượng:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-10 p-d-l-4">
                                                <span>{this.state.data.TienLuong}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                            <div className="col-sm-5 col-lg-2">
                                                <span className="f-w-600">Hướng điều trị:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-10 p-d-l-4">
                                                <span>{this.state.data.HuongDieuTri}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-2 bdbt">
                                            <div className="col-sm-5 col-lg-2">
                                                <span className="f-w-600">Quá trình diễn biến:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-10 p-d-l-4">
                                                <span>{this.state.data.QuaTrinhDienBien}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="div-share-view">
                                <div>
                                    <span>Bác sĩ điều trị</span>
                                </div>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                    <div className="col">
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 bdbt">
                                            <div className="col-sm-5 col-lg-3">
                                                <span className="f-w-600">Giám đốc:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-9 p-d-l-4">
                                                <span>{this.state.data.GiamDoc}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 bdbt">
                                            <div className="col-sm-5 col-lg-3">
                                                <span className="f-w-600">Trưởng khoa:</span>
                                            </div>
                                            <div className="col-sm-7 col-lg-9 p-d-l-4">
                                                <span>{this.state.data.TruongKhoa}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                            <div className="col-12">
                                                <span className="f-w-600">Bác sĩ điều trị:</span>
                                            </div>
                                            <div className="col-12 p-d-l-4">
                                                <span>{this.state.data.BsDieuTri}</span>
                                            </div>
                                        </div>
                                        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
                                            <div className="col-12">
                                                <span className="f-w-600">Bác sĩ làm bệnh án:</span>
                                            </div>
                                            <div className="col-12 p-d-l-4">
                                                <span>{this.state.data.BsLamBenhAn}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                    {/*<Footer />*/}
            </div>:
            <div className="t-c">
                <span>Không xem được bệnh án. Vui lòng kiểm tra lại</span>
            </div>
        )
    }
}