import React  from 'react';
import Header from '../Header/ui';
import Footer from '../Footer/ui';
import './ui.css'
import { removeCookie } from '../Header/ui_fc';
import { performLandingPage } from './ui_fc';
import slideshow1 from '../../../Images/slideshow1.png';
import slideshow2 from '../../../Images/slideshow2.png';
import slideshow3 from '../../../Images/slideshow3.png';
import shape1 from '../../../Images/shape1.png';
import shape2 from '../../../Images/shape2.png';
import shape3 from '../../../Images/shape3.png';
import hl7interface from '../../../Images/hl7interface.png';
export default class LandingPage extends React.Component {
    componentWillMount()
    {
      removeCookie();
    }
    componentDidMount()
    {
       performLandingPage();
    }
    render() {
        return (
          <div className="h-100">
                <Header action="lading"/>
                <div className="div-content">
                  <div id="slide" className="w-100">
                    <div className="slideshow-container w-100 h-100">
                      <div className="mySlides h-100"><img className="w-100 h-100" src={slideshow1}  /> </div>
                      <div className="mySlides h-100"><img className="w-100 h-100" src={slideshow2} /> </div>
                      <div className="mySlides h-100"><img className="w-100 h-100" src={slideshow3} /> </div>
                      <span className="prevBanner" >&#10094;</span> <span className="nextBanner">&#10095;</span> 
                      <div className="div-bdn c-p c-w t-c">
                        <span>Bắt đầu ngay</span>
                      </div>
                    </div>
                  </div>
                  <div className="div-introduce">
                    <div className="w-100 t-c">
                      <span>Cổng thông tin bệnh án điện tử</span>
                    </div>
                    <div className="w-100 t-c">
                      <span>Cổng thông tin bệnh án điện tử, có thể chuẩn hoá dữ liệu bệnh án điện tử các lần khám chữa bệnh của người dân cần phải báo cáo tập trung, chuyển đổi về dạng FHIR Resource, sẽ giúp cho ngành y tế, các cơ sở khám chữa bệnh và người dân.</span>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 f-l">
                        <img className="w-100 h-100" src={hl7interface}/>
                      </div>
                      <div className="col-lg-6 f-l">
                        <div>
                          <span><strong>Cổng thông tin bệnh án điện tử</strong></span>
                          <div className="w-100" />
                        </div>
                        <div>
                          <ul>
                            <li>
                              <span>Xây dựng cổng FHIR Gateway để các cơ sở khám chữa bệnh chuyển đổi dữ liệu bệnh án điện tử về tập trung tại trung tâm dữ liệu</span>
                            </li> 
                            <li>
                              <span>Tạo lập các báo cáo tổng hợp cho cấp quản lý</span>
                            </li>
                            <li>
                              <span>Chuẩn hoá định dạng XML cho bệnh án điện tử (tận dụng kết quả đã có của chuẩn XML 4210 của bảo hiểm y tế) gọi là “EHR pro-HL7 XML”</span>
                            </li>
                          </ul>
                        </div>
                    </div>
                  </div>
                  </div>
                  <div className="div-benefit">
                    <div className="t-c">
                      <span>Lợi ích của cổng thông tin </span>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-3">
                        <div className="w-100"/>
                        <div className="w-100 t-c">
                          <span>Hồ sơ sức khoẻ dạng chuẩn HL7</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="w-100"/>
                        <div className="w-100 t-c">
                          <span>Trao đổi hồ sơ sức khỏe với các cở sở y tế khác</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="w-100"/>
                        <div className="w-100 t-c">
                          <span>Giảm chi phí khám chữa bệnh nhờ liên thông HSSK</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="w-100"/>
                        <div className="w-100 t-c">
                          <span>Quản lý thống kê chất lượng KCB , thuốc</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-to-top c-w c-p">
                    <i className="fal fa-angle-up"></i>
                  </div>
                  <Footer />
              </div>
          </div>
        )
    }
}