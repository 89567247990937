import $ from 'jquery';
import { login } from '../Header/ui_fc';
var slideIndex = 0;
var slides,dots,slidetime;
function performLandingPage()
{
    slideIndex = 0;
    clearTimeout(slidetime);
    showSlides();
    $(".div-content").height($(window).height()-60);
    $(".prevBanner").unbind().on("click",function(){
        plusSlides(-1);
    })
    $(".nextBanner").unbind().on("click",function(){
        plusSlides(1);
    })
    $(".div-content").scroll(function() {
        if ($(this).scrollTop()) $('.div-to-top').fadeIn();
        else $('.div-to-top').fadeOut();
    });
    $(".div-to-top").unbind().on("click",function() {
        $(".div-content").animate({scrollTop: 0}, 500);
     });
    $(".div-bdn").unbind().on("click",login);
}
function showSlides() {
    var i;
    slides = document.getElementsByClassName("mySlides");
    for (var i = 0; i < slides.length; i++) {
       slides[i].style.display = "none";  
    }
    slideIndex++;
    if (slideIndex> slides.length) {slideIndex = 1}    
    slides[slideIndex-1].style.display = "block";  
    slidetime = setTimeout(showSlides, 10000); // Change image every 8 seconds
}

function plusSlides(position) {
    slideIndex +=position;
    if (slideIndex> slides.length) {slideIndex = 1}
    else if(slideIndex<1){slideIndex = slides.length}
    for (var i = 0; i < slides.length; i++) {
       slides[i].style.display = "none";  
    }
    slides[slideIndex-1].style.display = "block";  
}
function currentSlide(index) {
    if (index> slides.length) {index = 1}
    else if(index<1){index = slides.length}
    for (var i = 0; i < slides.length; i++) {
       slides[i].style.display = "none";  
    }
    slides[index-1].style.display = "block";  
}
export {performLandingPage}