import $ from 'jquery';
import './ui.css';
import Exporting from 'highcharts/modules/exporting';
import { _cookies, _height_header, _height_window } from '../../../Variable/defaultvar';
import { daysInMonth, dragElement, formatDate, formatDay, getManagementAgency, nFormatter, safeRead, SendData, SetChecked } from '../../../Function/ui';
import { lstMonth } from '../NumberVisitByDisease/ui_var';
import { getYear, performReportNumberVisitByDiseaseDefault } from '../NumberVisitByDisease/ui_fc';
var Highcharts = require('highcharts/highstock');
var _filterMA = {};
_filterMA.ID = null;
_filterMA.Find = null;
_filterMA.RowsPerPage = 10;
_filterMA.PageNumber = 1;
var chartMAC;
var _dt_managementAgency = getManagementAgency(_filterMA);
Exporting(Highcharts);
var cd = new Date();
var _currentMonth = new Date().getMonth() + 1;
var _currentDay = new Date().getDate();
var _filterYMD = {};

var _dtChartDG,
  _hdiv = 400,
  _hDNDiv = 340,
  _h_bar = 20,
  _f_size = 13,
  _c_bar = _height_window / 35;
var color1 = ['#FD625E', '#01B8AA', '#058DC7', '#ED561B', '#50B432', '#9A0034', '#FFD75E', '#DDDF00', '#24CBE5', '#64E572',
  '#FF9655', '#FFF263', '#6AF9C4', '#4267C2', '#E78035']
var color2 = ['#E78035', '#058DC7', '#ED561B', '#50B432', '#9A0034']
function resetFilterYMD() {
  _filterYMD.year = new Date().getFullYear();
  _filterYMD.month = _currentMonth;
  _filterYMD.day = _currentDay;
  _filterYMD.HospitalId = null;
  _filterMA.PageNumber = 1;
}
function createChartColumBAByMonth(id, obj, cls, action) {
  $("#" + id).empty();
  if (obj.categories.length != 0) {
    try {
      chartMAC = Highcharts.chart(id, {
        chart: {
          type: 'column'
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: obj.categories,
          labels:
          {
            useHTML: true,
            formatter: function () {
              let val = this.value;
              //width:235px;margin-left:-80px;
              return '<div class="div-lb-ttk-' + cls + ' c-p" id="' + val + '" style="max-width:300px ; white-space: nowrap; overflow:hidden; text-overflow:ellipsis;" title="' + val + '" >' + val + '</div>';
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          },
          stackLabels: {
            enabled: true,
            style: {
              enabled: true,
              style: {
                color: 'black',
                textOutline: false,
              },
            },
            formatter: function (e) {
              var xValue = this.x,
                xAxis = this.axis.chart.xAxis[0];
              return '<span id="' + xAxis.categories[xValue] + '" class="sp-total-' + cls + ' c-p">' + nFormatter(this.total, 2) + '</span>';
            }
          }
        },
        legend: {
          enabled: action,
          align: "center",
          x: -5,
          alignColumns: false,
          y: 15,
          verticalAlign: "bottom",
          floating: false,
          backgroundColor: "white",
          shadow: false,
          layout: 'horizontal',
          itemStyle: {
            fontWeight: '400',
            fontFamily: "Segoe UI"
          }
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.point.series.name + '</b> : <b>' + nFormatter(this.y, 2) + '</b>';
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels:
            {
              enabled: false,
              style:
              {
                fontFamily: "Seogoe UI",
                color: "black",
                textOutline: false,
                fontSize: 12
              },
              formatter: function () {
                let val = this.y;
                //width:235px;margin-left:-80px;
                return '<div>' + nFormatter(val, 2) + '</div>';
              },
            },
          },
          series: {
            point: {
              events: {
                click: function (event) {
                  addTextLenghChart(event.point.category, cls, obj, true);
                }
              }
            }
          },
        },
        series: obj.value.slice(2),
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
      });
    }
    catch (ex) {

    }
  }
}
function addTextLenghChart(param, cls, obj, action) {
  //chartMAC.reflow();
  var index = obj.categories.indexOf(param);
  var total = 0;
  for (var i = 2; i < 7; i++) {
    total += obj.value[i].data[index];
  }
  $(".sp-" + cls + "-note-title").text(param);
  $("." + cls + "-T-arrow").text(nFormatter(obj.value[0].data[index], 2));
  $("." + cls + "-VTYT-arrow").text(nFormatter(obj.value[1].data[index], 2));
  $("." + cls + "-BNTT-arrow").text(nFormatter(obj.value[2].data[index], 2));
  $("." + cls + "-BNCCT-arrow").text(nFormatter(obj.value[3].data[index], 2));
  $("." + cls + "-BHTT-arrow").text(nFormatter(obj.value[4].data[index], 2));
  $("." + cls + "-NK-arrow").text(nFormatter(obj.value[5].data[index], 2));
  $("." + cls + "-CPNĐS-arrow").text(nFormatter(obj.value[6].data[index], 2));
  $("." + cls + "-Total-arrow").text(nFormatter(total, 2));
  dragElement(document.getElementById("div-costbym-data"));
  dragElement(document.getElementById("div-costbyd-data"));
  if ($(".div-costby" + cls + "-data").hasClass("h")) {
    //$("#chartCostBy"+cls).addClass("w-80").removeClass("w-100");
    $(".div-costby" + cls + "-data").removeClass("h");
    //chartMAC.reflow();
    //action && cls=="m"?addElementChartCostByMonth(_filterYMD):addElementChartCostByDay(_filterYMD);
    $(".i-close-note-" + cls).unbind().on("click", function () {
      $(".div-costby" + cls + "-data").addClass("h");
      /*if($(".div-costby"+cls+"-data").hasClass("h")) $("#chartCostBy"+cls).removeClass("w-80").addClass("w-100");
      else $("#chartCostBy"+cls).addClass("w-80").removeClass("w-100");
      cls=="m"?addElementChartCostByMonth(_filterYMD):addElementChartCostByDay(_filterYMD);*/
      //chartMAC.reflow();
    })
  }
}
function handlingDataByYear(year) {
  var currentMonth = cd.getMonth() + 1;
  _filterYMD.year = year;
  if (year < cd.getFullYear()) {
    $(".div-check-month-cb").removeClass("div-dis").addClass("div-sp-ttk");
    $(".div-check-month-ip").prop("disabled", false);
    performJqueryTTK();
  }
  else {
    for (var i = 1; i < 12; i++) {
      if (i > currentMonth) {
        $(".div-check-month-cb-" + i).addClass("div-dis").removeClass("div-sp-ttk").unbind("click");
        $(".div-check-month-ip-" + i).prop("checked", false).prop("disabled", true);
      }
    }
    _filterYMD.month = _currentMonth;
    SetChecked(".ip-cb-ttk", "", false);
    SetChecked(".ip-cb-ttk-", _currentMonth, true);
    $(".dropdown-nvbd-label-month").text("Tháng " + _currentMonth);
  }
  resetSpanDay();
  addElementChartCostByMonth(_filterYMD);
  addElementChartCostByDay(_filterYMD);
}
function addElementReportMedicalRecordsAndCosts() {
  addElementReportMedicalRecordsAndCostsDefault();
  addElementReportMedicalRecordsAndCostsTitle();
  addElementReportMedicalRecordsAndCostsContent();
  addELementReportCSKCB(getManagementAgency(_filterMA));
  ElementChart();
}
function ElementChart() {
  _filterYMD.year = new Date().getFullYear();
  _filterYMD.month = _currentMonth;
  _filterYMD.day = _currentDay;
  var day = getDataChart(4, _filterYMD);
  addElementChartCostByMonth(_filterYMD);
  _filterYMD.month = Array.isArray(day) && day.length > 0 ? day[day.length - 1].Month : _currentMonth;
  //--------Phần giao diện biểu đồ-------------//
  $(".sp-ba-day").text(_filterYMD.month + "/" + _filterYMD.year);
  addElementChartCostByDay(_filterYMD);
}
function performReportMedicalRecordsAndCosts() {
  performReportNumberVisitByDiseaseDefault();
  performReportMedicalRecordsAndCostsSelectDate();
  performReportMedicalRecordsAndCostsContent();
  performReportMedicalRecordsAndCostsContentDay();
  SetChecked(".ip-cb-ttk-", (_filterYMD.month), true);
  $(".dropdown-nvbd-label-month").text("Tháng " + _filterYMD.month);
}
function addElementNoData(id) {
  var parent = $('<div class="div-null-data"></div>');
  $("#" + id).empty().append(parent);
}
function addElementReportMedicalRecordsAndCostsDefault() {
  var parent = $('<div class="div-rp-pmcy"/>');
  parent.appendTo($(".div-content-function"));
}
function addElementReportMedicalRecordsAndCostsTitle() {
  var year = getYear();
  var parent = $('<div class="div-rp-pmcy-title"/>');
  var child_1 = $('<div class="w-40 f-l"><p>Thống kê chi phí KCB</p><p>Thống kê chi phí khám chữa bệnh</p></div>');
  var child_2 = $('<div class="w-60 f-l"></div>');
  var child_2_child_0 = _dt_managementAgency.length > 1 ? $('<div class="w-50 f-l p-d-l-r-1"/>') : null;
  var child_2_child_1 = $('<div class="' + (_dt_managementAgency.length > 1 ? "w-25" : "w-50") + ' f-l p-d-l-r-1"/>');
  var child_2_child_2 = $('<div class="' + (_dt_managementAgency.length > 1 ? "w-25" : "w-50") + ' f-l p-d-l-r-1"/>');
  var child_2_child_0_child_1 = $('<div class="w-100"><span>Cơ sở KCB</span></div>');
  var child_2_child_0_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_1_child_1 = $('<div class="w-100"><span>Năm thống kê</span></div>');
  var child_2_child_1_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_2_child_1 = $('<div class="w-100"><span>Tháng thống kê</span></div>');
  var child_2_child_2_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_0_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-cskcb text-ellipsis-n-w">Tất cả</label>');
  var child_2_child_0_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-cskcb">');
  var child_2_child_1_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-year">' + year[year.length - 1] + '</label>');
  var child_2_child_1_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-ntk">');
  var child_2_child_2_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-month dropdown-nvbd-list-ttk"></label>');
  var child_2_child_2_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-ttk">');
  var child_2_child_0_child_2_child_2_child_1 = $('<div class="div-box-search"><input class="ip-box-search w-100" type="text" placeholder="Tìm kiếm"/></div>');
  var child_2_child_0_child_2_child_2_child_2 = $('<div class="div-lst-cskcb" />')
  for (var i = 0; i < year.length; i++) {
    var a = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ntk ip-cb-ntk-' + year[i] + '" type="checkbox" name="dropdown-nvbd-group" value="' + year[i] + '"/></div><div class="w-94 f-l div-sp-ntk" name="' + year[i] + '"><span class="c-p">' + year[i] + '</span></div></lable>')
    child_2_child_1_child_2_child_2.append(a);
  }
  for (var i = 0; i < lstMonth.length; i++) {
    var child_month = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ttk ip-cb-ttk-' + (i + 1) + ' div-check-month-ip div-check-month-ip-' + lstMonth[i].value + '" type="radio" name="dropdown-nvbd-group" disabled value="' + lstMonth[i].value + '"/></div><div class="w-94 f-l div-dis div-check-month-cb div-check-month-cb-' + lstMonth[i].value + '" name="' + lstMonth[i].value + '"><span >' + lstMonth[i].name + '</span></div></lable>');
    if (i < cd.getMonth() + 1)
      child_month = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ttk ip-cb-ttk-' + (i + 1) + ' div-check-month-ip div-check-month-ip-' + lstMonth[i].value + '" type="radio" name="dropdown-nvbd-group" value="' + lstMonth[i].value + '" /></div><div class="w-94 f-l div-sp-ttk div-check-month-cb div-check-month-cb-' + lstMonth[i].value + '" name="' + lstMonth[i].value + '"><span class="c-p">' + lstMonth[i].name + '</span></div></lable>')
    child_2_child_2_child_2_child_2.append(child_month);
  }
  if (_dt_managementAgency.length > 1) {
    child_2_child_0.append([
      child_2_child_0_child_1,
      child_2_child_0_child_2.append([
        child_2_child_0_child_2_child_1,
        child_2_child_0_child_2_child_2.append([
          child_2_child_0_child_2_child_2_child_1,
          child_2_child_0_child_2_child_2_child_2
        ])
      ])
    ])
  }
  parent.append([child_1, child_2.append([
    child_2_child_0,
    child_2_child_1.append([
      child_2_child_1_child_1,
      child_2_child_1_child_2.append([
        child_2_child_1_child_2_child_1,
        child_2_child_1_child_2_child_2
      ])
    ]),
    child_2_child_2.append([
      child_2_child_2_child_1,
      child_2_child_2_child_2.append([
        child_2_child_2_child_2_child_1,
        child_2_child_2_child_2_child_2
      ])
    ])
  ])]);
  parent.appendTo($(".div-rp-pmcy"));
}
function addElementReportMedicalRecordsAndCostsContent() {
  var parent = $('<div class="div-rp-pmcy-content div-rp-mac-content"/>');
  var child_2 = $('<div class="w-100 f-l" />');
  var child_2_child_1 = $('<div class="w-100" style="border-bottom:none;padding:0"/>');
  var child_2_child_1_child_1 = $('<div class="w-100" style="border-bottom:none"><div><span>Chi phí khám chữa bệnh năm </span><span class="sp-ba-month">' + _filterYMD.year + '</span></div></div>');
  var child_2_child_1_child_1_child_1 = $('<div class="w-100 f-l" id="chartCostBym" style="max-height:' + _hdiv + 'px;margin-bottom:20px"></div>');
  var child_2_child_1_child_1_child_2 = $('<div id="div-costbym-data" class="w-20 f-l div-costbym-data h" style="height:' + _hdiv + 'px"></div>');
  var child_2_child_1_child_2 = $('<div class="w-100"><div><span>Chi phí khám chữa bệnh tháng </span><span class="sp-ba-day">' + _filterYMD.month + "/" + _filterYMD.year + '</span></div></div>');
  var child_2_child_1_child_2_child_1 = $('<div class="w-100 f-l" id="chartCostByd" style="height:' + _hdiv + 'px"></div>');
  var child_2_child_1_child_2_child_2 = $('<div id="div-costbyd-data" class="w-20 f-l div-costbyd-data h" style="height:' + _hdiv + 'px"></div>');
  var child_2_child_1_child_1_child_2_child_1 = $('<div><div><i class="fal fa-times f-r c-p i-close-note-m"></i></div><div><span class="f-l f-w-600">Chi phí</span><span class="sp-m-note-title f-r"></span></div></div>');
  var child_2_child_1_child_1_child_2_child_2 = $('<div></div>');
  var child_2_child_1_child_1_child_2_child_2_child_1 = $('<div><span class="f-l">Thuốc</span><span class="m-T-arrow f-r"></span></div>');
  var child_2_child_1_child_1_child_2_child_2_child_2 = $('<div style="border-bottom:solid 1px #999999"><span class="f-l">Vật tư y tế</span><span class="m-VTYT-arrow f-r"></span></div>')
  var child_2_child_1_child_1_child_2_child_2_child_3 = $('<div><span class="f-l">BN tự trả</span><span class="m-BNTT-arrow f-r"></span></div>')
  var child_2_child_1_child_1_child_2_child_2_child_4 = $('<div><span class="f-l">BN cùng chi trả</span><span class="m-BNCCT-arrow f-r"></span></div>')
  var child_2_child_1_child_1_child_2_child_2_child_5 = $('<div><span class="f-l">Bảo hiểm thanh toán</span><span class="m-BHTT-arrow f-r"></span></div>')
  var child_2_child_1_child_1_child_2_child_2_child_6 = $('<div><span class="f-l">Nguồn khác</span><span class="m-NK-arrow f-r"></span></div>')
  var child_2_child_1_child_1_child_2_child_2_child_7 = $('<div><span class="f-l">Chi phí ngoài định suất</span><span class="m-CPNĐS-arrow f-r"></span></div>')
  var child_2_child_1_child_1_child_2_child_2_child_8 = $('<div><span class="f-l" style="color:#c00000">Tổng</span><span class="m-Total-arrow f-r"></span></div>')
  var child_2_child_1_child_2_child_2_child_1 = $('<div><div><i class="fal fa-times f-r c-p i-close-note-d"></i></div><div><span class="f-l f-w-600">Chi phí ngày</span><span class="sp-d-note-title f-r"></span></div></div>');
  var child_2_child_1_child_2_child_2_child_2 = $('<div></div>');
  var child_2_child_1_child_2_child_2_child_2_child_1 = $('<div><span class="f-l">Thuốc</span><span class="d-T-arrow f-r"></span></div>');
  var child_2_child_1_child_2_child_2_child_2_child_2 = $('<div style="border-bottom:solid 1px #999999"><span class="f-l">Vật tư y tế</span><span class="d-VTYT-arrow f-r"></span></div>')
  var child_2_child_1_child_2_child_2_child_2_child_3 = $('<div><span class="f-l">BN tự trả</span><span class="d-BNTT-arrow f-r"></span></div>')
  var child_2_child_1_child_2_child_2_child_2_child_4 = $('<div><span class="f-l">BN cùng chi trả</span><span class="d-BNCCT-arrow f-r"></span></div>')
  var child_2_child_1_child_2_child_2_child_2_child_5 = $('<div><span class="f-l">Bảo hiểm thanh toán</span><span class="d-BHTT-arrow f-r"></span></div>')
  var child_2_child_1_child_2_child_2_child_2_child_6 = $('<div><span class="f-l">Nguồn khác</span><span class="d-NK-arrow f-r"></span></div>')
  var child_2_child_1_child_2_child_2_child_2_child_7 = $('<div><span class="f-l">Chi phí ngoài định suất</span><span class="d-CPNĐS-arrow f-r"></span></div>')
  var child_2_child_1_child_2_child_2_child_2_child_8 = $('<div><span class="f-l" style="color:#c00000">Tổng</span><span class="d-Total-arrow f-r"></span></div>')
  parent.append([
    child_2.append(
      child_2_child_1.append([
        child_2_child_1_child_1.append([
          child_2_child_1_child_1_child_1,
          child_2_child_1_child_1_child_2.append([
            child_2_child_1_child_1_child_2_child_1,
            child_2_child_1_child_1_child_2_child_2.append([
              child_2_child_1_child_1_child_2_child_2_child_1,
              child_2_child_1_child_1_child_2_child_2_child_2,
              child_2_child_1_child_1_child_2_child_2_child_3,
              child_2_child_1_child_1_child_2_child_2_child_4,
              child_2_child_1_child_1_child_2_child_2_child_5,
              child_2_child_1_child_1_child_2_child_2_child_6,
              child_2_child_1_child_1_child_2_child_2_child_7,
              child_2_child_1_child_1_child_2_child_2_child_8
            ]),
          ])
        ]),
        child_2_child_1_child_2.append([
          child_2_child_1_child_2_child_1,
          child_2_child_1_child_2_child_2.append([
            child_2_child_1_child_2_child_2_child_1,
            child_2_child_1_child_2_child_2_child_2.append([
              child_2_child_1_child_2_child_2_child_2_child_1,
              child_2_child_1_child_2_child_2_child_2_child_2,
              child_2_child_1_child_2_child_2_child_2_child_3,
              child_2_child_1_child_2_child_2_child_2_child_4,
              child_2_child_1_child_2_child_2_child_2_child_5,
              child_2_child_1_child_2_child_2_child_2_child_6,
              child_2_child_1_child_2_child_2_child_2_child_7,
              child_2_child_1_child_2_child_2_child_2_child_8
            ])
          ])
        ])
      ])
    )
  ]);
  parent.appendTo($(".div-rp-pmcy"));
}
function addELementReportCSKCB(data) {
  for (var i = 0; i < data.length; i++) {
    var lb = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-cskcb ip-cb-cskcb-' + data[i].HospitalId + '" type="checkbox" name="dropdown-nvbd-group" value="' + data[i].HospitalId + '"/></div><div class="w-94 f-l div-sp-cskcb text-ellipsis-n-w" name="' + data[i].HospitalId + '" title="' + data[i].HospitalName + '"><span class="c-p">' + data[i].HospitalName + '</span></div></lable>')
    $(".div-lst-cskcb").append(lb);
  }
}
//Phần giao diện biểu đồ thể hiện chi phí cua từng thang
function addElementChartCostByMonth(dm) {
  var data = handlingDataCostChartBAByMonth(dm);
  createChartColumBAByMonth("chartCostBym", data, "m", true);
  performReportMedicalRecordsAndCostsContent();
}
//Phần giao diện biểu đồ thể hiện chi phí tung ngay cua thang
function addElementChartCostByDay(dm) {
  var data = handlingDataCostChartBAByDay(dm);
  createChartColumBAByMonth("chartCostByd", data, "d", true);
  performReportMedicalRecordsAndCostsContentDay();
}
function performReportMedicalRecordsAndCostsContent() {
  $(".div-lb-ttk-m").unbind().on("click", function () {
    var month = $(this).attr("id").replace("Tháng ", "");
    $(".div-lb-ttk-m").removeClass("div-active");
    $(this).toggleClass("div-active");
    SetChecked(".ip-cb-ttk", "", false);
    $(".ip-cb-ttk-" + month).prop("checked", true);
    $(".dropdown-nvbd-label-month").text("Tháng " + month);
    $(".div-costbyd-data").addClass("h");
    $("#chartCostByd").removeClass("w-80").addClass("w-100");
    $(".div-rp-pmcy-content").animate({ scrollTop: $(".div-rp-pmcy-content").height() }, 1000);
    _filterYMD.month = Number(month);
    resetSpanDay();
    addElementChartCostByDay(_filterYMD);
    //addTextLenghChart($(this).attr("id"),"m",obj);
  })
  $(".sp-total-m").unbind().on("click", function () {
    var data = handlingDataCostChartBAByMonth(_filterYMD);
    addTextLenghChart($(this).attr("id"), "m", data, false);
  })
  //$("#chartDG , .highcharts-container").height($(".div-rp-pmcy-content").height()-60);
}
function performReportMedicalRecordsAndCostsContentDay() {
  $(".sp-total-d").unbind().on("click", function () {
    var data = handlingDataCostChartBAByDay(_filterYMD);
    addTextLenghChart($(this).attr("id"), "d", data, false);
  })
}
function performReportMedicalRecordsAndCostsSelectDate() {
  var CheckboxDropdown = function (el) {
    var _this = this;
    this.isOpen = false;
    this.areAllChecked = false;
    this.$el = $(el);
    this.$label = this.$el.find('.dropdown-nvbd-label');
    this.$label.on('click', function (e) {
      e.preventDefault();
      _this.toggleOpen();
    });

  };
  CheckboxDropdown.prototype.toggleOpen = function (forceOpen) {
    var _this = this;
    if (!this.isOpen || forceOpen) {
      this.isOpen = true;
      this.$el.addClass('on');
      $(document).on('click', function (e) {
        if (!$(e.target).closest('[data-control]').length) {
          _this.toggleOpen();
        }
      });
    }
    else {
      this.isOpen = false;
      this.$el.removeClass('on');
      $(document).off('click');
    }
  };
  var checkboxesDropdowns = document.querySelectorAll('[data-control="checkbox-dropdown"]');
  for (var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
    new CheckboxDropdown(checkboxesDropdowns[i]);
  }
  var year = getYear();
  SetChecked(".ip-cb-ntk-", year[year.length - 1], true);
  $(".div-sp-ntk").unbind().on("click", function () {
    var id = $(this).attr("name");
    if ($(".ip-cb-ntk-" + id).is(":checked")) {
      $(".ip-cb-ntk-" + id).prop("checked", false);
      id = year[year.length - 1];
      SetChecked(".ip-cb-ntk-", id, true);
    }
    else {
      SetChecked(".ip-cb-ntk", "", false);
      $(".ip-cb-ntk-" + id).prop("checked", true);
    }
    $(".dropdown-nvbd-label-year").text(id);
    $(".div-rp-pmcy-content").scrollTop(0);
    handlingDataByYear(id);
  })
  $(".ip-cb-ntk").unbind().on("change", function () {
    var id;
    if ($(this).is(":checked")) {
      id = $(this).val();
      SetChecked(".ip-cb-ntk", "", false);
      $(this).prop("checked", true);
    }
    else {
      id = year[year.length - 1];
      SetChecked(".ip-cb-ntk-", id, true);
    }
    $(".dropdown-nvbd-label-year").text(id);
    $(".div-rp-pmcy-content").scrollTop(0);
    handlingDataByYear(id);
  })
  $(".dropdown-nvbd-list-ntk").animate({ scrollTop: $(".dropdown-nvbd-list-ntk").height() }, 1000);
  $(".ip-box-search").unbind().on("keyup", function (e) {
    if (e.keyCode == 13) {
      _filterMA.Find = $(this).val();
      _filterMA.PageNumber = 1;
      $(".div-lst-cskcb").empty();
      addELementReportCSKCB(getManagementAgency(_filterMA));
      performSearchCSKCB();
      performScrollCSKCB();
      try {
        var id = _dt_managementAgency.filter(x => x.HospitalName == $(".dropdown-nvbd-label-cskcb").text())[0].HospitalId;
        SetChecked(".ip-cb-cskcb-", id, true);
      }
      catch (ex) { }
    }
  })
  performSearchCSKCB();
  performJqueryTTK();
  performScrollCSKCB();
}
function performJqueryTTK() {
  $(".div-sp-ttk").unbind().on("click", function () {
    var id = $(this).attr("name");
    var name = lstMonth.filter(x => x.value == id)[0].name;
    var month = name.replace("Tháng ", "");
    _filterYMD.month = Number(month);
    $(".ip-cb-ttk-" + id).prop("checked", true);
    $(".dropdown-nvbd-label-month").text(name);
    $(".div-costbyd-data").addClass("h");
    $("#chartCostByd").removeClass("w-80").addClass("w-100");
    $(".div-rp-pmcy-content").animate({ scrollTop: $(".div-rp-pmcy-content").height() }, 1000);
    resetSpanDay();
    addElementChartCostByDay(_filterYMD);
  })
  $(".ip-cb-ttk").unbind().on("change", function () {
    var id = $(this).val();
    SetChecked(".ip-cb-ttk", "", false);
    $(this).prop("checked", true);
    var name = lstMonth.filter(x => x.value == id)[0].name;
    var month = name.replace("Tháng ", "");
    _filterYMD.month = Number(month);
    $(".dropdown-nvbd-label-month").text(name);
    $(".div-rp-pmcy-content").animate({ scrollTop: $(".div-rp-pmcy-content").height() }, 1000);
    $(".div-costbyd-data").addClass("h");
    $("#chartCostByd").removeClass("w-80").addClass("w-100");
    resetSpanDay();
    addElementChartCostByDay(_filterYMD);
  })
}
function performSearchCSKCB() {
  $(".div-sp-cskcb").unbind().on("click", function () {
    var name;
    var id = $(this).attr("name");
    if ($(".ip-cb-cskcb-" + id).is(":checked")) {
      id = null;
      name = "Tất cả"
      SetChecked(".ip-cb-cskcb-", $(this).attr("name"), false)
    }
    else {
      SetChecked(".ip-cb-cskcb", "", false);
      name = _dt_managementAgency.filter(x => x.HospitalId == id)[0].HospitalName;
      $(".ip-cb-cskcb-" + id).prop("checked", true);
    }
    $(".dropdown-nvbd-label-cskcb").text(name);
    SetChecked(".ip-cb-cskcb-", id, true);
    handlingDataCSKCB(id);
  })
  $(".ip-cb-cskcb").unbind().on("change", function () {
    var name;
    if ($(this).is(":checked")) {
      var id = $(this).val();
      name = _dt_managementAgency.filter(x => x.HospitalId == id)[0].HospitalName;
      SetChecked(".ip-cb-cskcb", "", false);
      $(this).prop("checked", true);
      handlingDataCSKCB(id);
    }
    else {
      name = "Tất cả";
      $(this).prop("checked", false);
      handlingDataCSKCB(null);
    }
    $(".dropdown-nvbd-label-cskcb").text(name);
  })
  try {
    var id = _dt_managementAgency.filter(x => x.HospitalName == $(".dropdown-nvbd-label-cskcb").text())[0].HospitalId;
    SetChecked(".ip-cb-cskcb-", id, true);
  }
  catch (ex) { }
}
function performScrollCSKCB() {
  $(".div-lst-cskcb").scroll(function () {
    var scrollHeight = $(".div-lst-cskcb").prop("scrollHeight");
    if ($(".div-lst-cskcb").scrollTop() == Math.round(scrollHeight - ($(".div-lst-cskcb").height()))) {
      fetchMoreDataMA();
      var data = getManagementAgency(_filterMA);
      _dt_managementAgency = _dt_managementAgency.concat(data);
      addELementReportCSKCB(data);
      performSearchCSKCB();
    }
  });
}
function resetSpanDay() {
  $(".sp-ba-day").text(_filterYMD.month + "/" + _filterYMD.year);
  $(".sp-ba-month").text(_filterYMD.year)
}
function getDataChart(action, obj) {
  var data = {};
  switch (action) {
    //Lấy danh sách thống kê bệnh án theo ngày
    case 1:
      {
        data = SendData("api/Report/EMRCountHospital/SummaryByDay/" + obj.year + "-" + obj.month + "-" + obj.day, "get", null);
        break;
      }
    // //Lấy danh sách thống kê bệnh án theo tháng
    case 2:
      {
        data = SendData("api/Report/EMRCountHospital/SummaryByMonth/" + obj.month + "/" + obj.month + "/" + obj.year, "get", null);
        break;
      }
    // Lấy danh sách thống kê chi phí tung ngay cua thang
    case 3:
      {
        data = SendData("api/Report/SummaryCostHospital/SummaryByDayInMonth/" + obj.month + "/" + obj.year, "get", { hospitalId: obj.HospitalId });
        break;
      }
    // Lấy danh sách thống kê chi phí tung thang cua nam
    case 4:
      {
        data = SendData("api/Report/SummaryCostHospital/SummaryByMonth/" + 1 + "/" + 12 + "/" + obj.year, "get", { hospitalId: obj.HospitalId });
        break;
      }
    // Lấy danh sách thống kê chi phí từng năm 10 năm gần nhất
    case 5:
      {
        data = SendData("api/Report/SummaryCostHospital/Summary/" +(obj.year - 10)+"/" + obj.year, "get", { hospitalId: obj.HospitalId });
        break;
      }
  }
  return data;
}
function handlingDataCostChartBAByDay(dm) {
  try {
    var dt = {};
    dt.categories = [];
    dt.value =
      [
        {
          name: "Thuốc",
          data: [],
          maxPointWidth: 60,

        },
        {
          name: "Vật tư y tế",
          data: [],
          maxPointWidth: 60,
        },
        {
          name: "Bệnh nhân tự trả",
          data: [],
          maxPointWidth: 60,
        },
        {
          name: "Bệnh nhân cùng chi trả",
          data: [],
          maxPointWidth: 60,
        },
        {
          name: "Bảo hiểm thanh toán",
          data: [],
          maxPointWidth: 60,
        },
        {
          name: "Nguồn khác",
          data: [],
          maxPointWidth: 60,
        },
        {
          name: "Chi phí ngoài định suất",
          data: [],
          maxPointWidth: 60
        }
      ];
    var data = getDataChart(3, dm);
    data.sort((a, b) => (a.Date > b.Date) ? 1 : ((b.Date > a.Date) ? -1 : 0))
    for (var i = 0; i < data.length; i++) {
      dt.categories.push(formatDay(data[i].Date));
      dt.value[0].data.push(data[i].T_Thuoc);
      dt.value[1].data.push(data[i].T_VTYT);
      dt.value[2].data.push(data[i].T_BNTT);
      dt.value[3].data.push(data[i].T_BNCCT);
      dt.value[4].data.push(data[i].T_BHTT);
      dt.value[5].data.push(data[i].T_NguonKhac);
      dt.value[6].data.push(data[i].T_NgoaiDS);
    }
    if (data == null || data == []) return null
    return dt
  }
  catch (ex) { }
}
function handlingDataCostChartBAByMonth(dm) {
  try {
    var dt = {};
    dt.categories = [];
    dt.value =
      [
        {
          name: "Thuốc",
          data: [],
          maxPointWidth: 60
        },
        {
          name: "Vật tư y tế",
          data: [],
          maxPointWidth: 60
        },
        {
          name: "Bệnh nhân tự trả",
          data: [],
          maxPointWidth: 60
        },
        {
          name: "Bệnh nhân cùng chi trả",
          data: [],
          maxPointWidth: 60
        },
        {
          name: "Bảo hiểm thanh toán",
          data: [],
          maxPointWidth: 60
        },
        {
          name: "Nguồn khác",
          data: [],
          maxPointWidth: 60
        },
        {
          name: "Chi phí ngoài định suất",
          data: [],
          maxPointWidth: 60
        }
      ];
    var data = getDataChart(4, dm);
    data.sort((a, b) => (a.Month > b.Month) ? 1 : ((b.Month > a.Month) ? -1 : 0))
    for (var i = 0; i < data.length; i++) {
      dt.categories.push("Tháng " + data[i].Month);
      dt.value[0].data.push(data[i].T_Thuoc);
      dt.value[1].data.push(data[i].T_VTYT);
      dt.value[2].data.push(data[i].T_BNTT);
      dt.value[3].data.push(data[i].T_BNCCT);
      dt.value[4].data.push(data[i].T_BHTT);
      dt.value[5].data.push(data[i].T_NguonKhac);
      dt.value[6].data.push(data[i].T_NgoaiDS);
    }
    if (data == null || data == []) return null
    return dt
  }
  catch (ex) { }
}
function handlingDataCSKCB(cskcb) {
  _filterYMD.HospitalId = cskcb;
  resetSpanDay();
  addElementChartCostByMonth(_filterYMD)
  addElementChartCostByDay(_filterYMD);
}
function fetchMoreDataMA() {
  _filterMA.PageNumber += 1;
}
export { addElementReportMedicalRecordsAndCosts, performReportMedicalRecordsAndCosts, resetFilterYMD, addElementNoData }