import $ from 'jquery' ;
import './ui.css';
import Exporting from 'highcharts/modules/exporting';
import { _cookies, _height_header, _height_window } from '../../../Variable/defaultvar';
import { getManagementAgency, SendData, SetChecked } from '../../../Function/ui';
import { lstMonth } from './ui_var';
import { addElementNoData } from '../MedicalRecordsAndCosts/ui_fc';
var Highcharts = require('highcharts/highstock');  
Exporting(Highcharts);
var _filterMA = {};
    _filterMA.ID = null;
    _filterMA.Find = null;
    _filterMA.RowsPerPage = 10;
    _filterMA.PageNumber = 1;
var _dt_managementAgency = getManagementAgency(_filterMA);
var cd = new Date();
var _filterDCH = {};
var _dtChartDG ;
var _hdiv = 706;
var _hDNDiv = 340;
var _h_bar  = 23;
var _f_size = 12;
var _c_bar = _height_window/35;
function createChart(id,obj)
{
  if(obj!=null)
  {
    Highcharts.chart(id, {
      chart: {
        type: 'bar',
        //spacingLeft: 80
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: obj.name,
        //min:0,
        //max:_c_bar,
        /*scrollbar: {
          enabled: true,
          liveRedraw: false 
        },*/
        labels: 
        { 
          useHTML: true, 
          formatter: function() { 
            let val = this.value;
            //width:235px;margin-left:-80px;
            return '<div class="div-lb-hover-tt c-p" style="max-width:300px ; white-space: nowrap; overflow:hidden; text-overflow:ellipsis;" title="'+val+'">'+val+'</div>'; 
          },
        },
        events: {
          setExtremes: function (e) {
              $('#report').html('<b>Set extremes:</b> e.trigger: ' + e.trigger + ', e.triggerOp: '+e.triggerOp);
          }
        }
      },
      yAxis: {
        title: {
          text: ''
        },
       
        visible: false
      },
      legend: {
        enabled:false,
        reversed: true,
        align: 'right',
        verticalAlign: 'top',
        font:"Segoe UI",
        itemStyle: {
          color: 'rgb(102, 102, 102)',
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          dataLabels: 
          {
            enabled: true,
            style:
            {
              fontFamily:"Seogoe UI",
              color:"black",
              textOutline: false,
              fontSize:_f_size
            },
            align : 'left',
          },
          pointWidth:_h_bar
        }
      },
      series: obj.value,
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    });
  }
  else
  {
    addElementNoData(id);
  }
}
function setFilterDCH()
{
  _filterDCH.HospitalId = null;
  _filterDCH.TakeTop = 40;
  _filterDCH.FromMonth = 1;
  _filterDCH.ToMonth = cd.getMonth() + 1;
  _filterDCH.FromYear = cd.getFullYear();
  _filterDCH.ToYear = cd.getFullYear();
}
function creatChartDougtNut(id,obj)
{
  try
  {
    var colors =  ['#FF2F01','#FF8A00','#0376CF','#EAC558' ],
    data = [
      {
        y: 62.74,
        drilldown: {
          name: obj.name,
          categories: obj.categories,
          data: obj.value
        }
      }
    ],
    browserData = [],
    versionsData = [],
    i,
    j,
    dataLen = data.length,
    drillDataLen,
    brightness;
    // Build the data arrays
    for (i = 0; i < dataLen; i += 1) {  
      // add version data
      drillDataLen = data[i].drilldown.data.length;
      for (j = 0; j < drillDataLen; j += 1) {
        brightness = 0.2 - (j / drillDataLen) / 5;
        versionsData.push({
          name: data[i].drilldown.categories[j],
          y: data[i].drilldown.data[j],
          color: colors[j]
        });
      }
    }
    // Create the chart
    Highcharts.chart(id, {
      chart: {
        type: 'pie',
      },
      title: {
        text :""
        /*text: obj.title,
        style: {
          color: '#666666',
          fontSize: "18px"
        }*/
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ['50%', '50%'],
        },
        series: {
          dataLabels: {
              enabled: true,
              style:{
                fontFamily:"Seogoe UI",
                color: '#666666',
                fontSize : 15
              },
              y:-5,
          }
        }
      },
      tooltip: {
        valueSuffix: '%'
      },
      series: [{
        name: 'Browsers',
        data: browserData,
        size: '33%',
        dataLabels: {
          formatter: function () {
            return this.y > 0 ? this.point.name : null;
          },
          color: '#ffffff',
          distance: -30
        }
      }, {
        name: '',
        data: versionsData,
        size: '60%',
        innerSize: '60%',
        dataLabels: {
          formatter: function () {
            // display only if larger than 1
            return this.y > 0 ? '<b>' + this.point.name + '</b><br/> ' +
              this.y + '%' : null;
          }
        },
        id: 'versions'
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 100
          },
          chartOptions: {
            series: [{
            }, {
              id: 'versions',
              dataLabels: {
                enabled: false
              }
            }]
          }
        }]
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      }
    });
  }
  catch(ex)
  {
    var parent = $('<div class="div-null-data"></div>');
    $("#"+id).append(parent);
    $(".div-"+id+"-content").empty();
  }
  
}
function getYear(ctY)
{
  var d = new Date();
  var year = d.getFullYear();
  var arrY = [];
  for(var i = 2021 ; i <= year ; i++)
  {
    arrY.push(i);
  }
  return arrY;
}
function addElementReportNumberVisitByDisease()
{
    addElementReportNumberVisitByDiseaseDefault();
    addElementReportNumberVisitByDiseaseTitle();
    addElementReportNumberVisitByDiseaseContent();
    addELementReportCSKCB(_dt_managementAgency)
    //--------Phần giao diện biểu đồ-------------//
    addThemeHightChart();
    //Biểu đồ thống kê bệnh theo giới tính
    addElementChartDG();
    //Biểu đồ thống kê số lần khám theo tuổi
    addElementChartPtAge();
     //Biểu đồ thống kê số lần khám theo hình thức kcb
    addElementChartPtKCB();
}
function performReportNumberVisitByDisease()
{
  performReportNumberVisitByDiseaseDefault();
  performReportNumberVisitByDiseaseContent();
  performReportNumberVisitByDiseaseSelectDate();
  SetChecked(".ip-cb-cskcb-",_filterDCH.HospitalId,true);
}
function addThemeHightChart()
{
    Highcharts.theme = {
      colors: ['#FD625E' , '#01B8AA','#058DC7' , '#ED561B', '#50B432', '#9A0034' , '#FFD75E' , '#DDDF00', '#24CBE5', '#64E572',
              '#FF9655', '#FFF263', '#6AF9C4' , '#4267C2' , '#E78035'],
    };
    // Apply the theme
    Highcharts.setOptions(Highcharts.theme);
   
}
function addElementReportNumberVisitByDiseaseDefault()
{
    var parent = $('<div class="div-rp-pmcy"/>');
    parent.appendTo($(".div-content-function"));
}
function addElementReportNumberVisitByDiseaseTitle()
{
  var year = getYear();
  var parent = $('<div class="div-rp-pmcy-title"/>');
  var child_1 = $('<div class="w-40 f-l"><p>Thống kê bệnh nhân</p><p>Thống kê danh sách số bệnh nhân theo bệnh và giới tính</p></div>');
  var child_2 = $('<div class="w-60 f-l"></div>');
  var child_2_child_0 = _dt_managementAgency.length > 1? $('<div class="w-50 f-l p-d-l-r-1"/>') : null;
  var child_2_child_1 = $('<div class="'+(_dt_managementAgency.length > 1 ? "w-25" : "w-50")+' f-l p-d-l-r-1"/>');
  var child_2_child_2 = $('<div class="'+(_dt_managementAgency.length > 1 ? "w-25" : "w-50")+' f-l p-d-l-r-1"/>');
  var child_2_child_0_child_1 = $('<div class="w-100"><span>Cơ sở KCB</span></div>');
  var child_2_child_0_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_1_child_1 = $('<div class="w-100"><span>Năm thống kê</span></div>');
  var child_2_child_1_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_2_child_1 = $('<div class="w-100"><span>Tháng thống kê</span></div>');
  var child_2_child_2_child_2 = $('<div class="dropdown-nvbd w-100" data-control="checkbox-dropdown"/>');
  var child_2_child_0_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-cskcb text-ellipsis-n-w">Tất cả</label>');
  var child_2_child_0_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-cskcb">');
  var child_2_child_1_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-year">'+year[year.length-1]+'</label>');
  var child_2_child_1_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-ntk">');
  var child_2_child_2_child_2_child_1 = $('<label class="dropdown-nvbd-label dropdown-nvbd-label-month dropdown-nvbd-list-ttk">Tất cả</label>');
  var child_2_child_2_child_2_child_2 = $('<div class="dropdown-nvbd-list dropdown-nvbd-list-ttk">');
  var child_2_child_0_child_2_child_2_child_1 = $('<div class="div-box-search"><input class="ip-box-search w-100" type="text" placeholder="Tìm kiếm"/></div>');
  var child_2_child_0_child_2_child_2_child_2 = $('<div class="div-lst-cskcb" />')
  for(var i = 0 ; i< year.length ; i++)
  {
    var a = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ntk ip-cb-ntk-'+year[i]+'" type="checkbox" name="dropdown-nvbd-group" value="'+year[i]+'"/></div><div class="w-94 f-l div-sp-ntk" name="'+year[i]+'"><span class="c-p">'+year[i]+'</span></div></lable>')
    child_2_child_1_child_2_child_2.append(a);
  }
  for(var i = 0 ; i< lstMonth.length ; i++)
  {
    var child_month = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ttk ip-cb-ttk-'+(i+1)+' div-check-month-ip div-check-month-ip-'+lstMonth[i].value+'" type="checkbox" name="dropdown-nvbd-group" disabled value="'+lstMonth[i].value+'"/></div><div class="w-94 f-l div-dis div-check-month-cb div-check-month-cb-'+lstMonth[i].value+'" name="'+lstMonth[i].value+'"><span >'+lstMonth[i].name+'</span></div></lable>');
    if(i < cd.getMonth()+1)
      child_month = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-ttk ip-cb-ttk-'+(i+1)+' div-check-month-ip div-check-month-ip-'+lstMonth[i].value+'" type="checkbox" name="dropdown-nvbd-group" value="'+lstMonth[i].value+'" /></div><div class="w-94 f-l div-sp-ttk div-check-month-cb div-check-month-cb-'+lstMonth[i].value+'" name="'+lstMonth[i].value+'"><span class="c-p">'+lstMonth[i].name+'</span></div></lable>')
    child_2_child_2_child_2_child_2.append(child_month);
  }
  if(_dt_managementAgency.length > 1)
  {
    child_2_child_0.append([
      child_2_child_0_child_1,
      child_2_child_0_child_2.append([
        child_2_child_0_child_2_child_1,
        child_2_child_0_child_2_child_2.append([
          child_2_child_0_child_2_child_2_child_1,
          child_2_child_0_child_2_child_2_child_2
        ])
      ])
    ])
  }
  parent.append([child_1,child_2.append([
    child_2_child_0,
    child_2_child_1.append([
      child_2_child_1_child_1,
      child_2_child_1_child_2.append([
        child_2_child_1_child_2_child_1,
        child_2_child_1_child_2_child_2
      ])
    ]),
    child_2_child_2.append([
      child_2_child_2_child_1,
      child_2_child_2_child_2.append([
        child_2_child_2_child_2_child_1,
        child_2_child_2_child_2_child_2
      ])
    ])
  ])]);
  parent.appendTo($(".div-rp-pmcy"));
}
function addElementReportNumberVisitByDiseaseContent() {
  var parent          = $('<div class="div-rp-pmcy-content"/>');
  var child_1         = $('<div class="w-50 f-l"><div class="w-100"><div class="w-100" style="height:'+_hDNDiv+'px"><div class="w-20 f-l div-chartPtAge-content"></div><div class="w-80 f-l" id="chartPtAge" style="height:'+_hDNDiv+'px"></div></div></div><div class="w-100" style="height:'+_hDNDiv+'px"><div class="w-20 f-l div-chartPtKCB-content"></div><div class="w-80 f-l" id="chartPtKCB" style="height:'+_hDNDiv+'px"></div></div></div>');
  var child_2         = $('<div class="w-50 f-l" />');
  var child_2_child_1 = $('<div class="w-100 t-r"><span>Thống kê bệnh nhân theo bệnh & giới tính</span></div>');
  var child_2_child_2 = $('<div class="w-100"></div>');
  var child_2_child_2_child_1 = $('<div class="w-100"></div>');
  var child_2_child_2_child_2 = $('<div class="w-100" id="chartDG" style="height:'+_hdiv+'px"></div>')
  parent.append([
    child_1,
    child_2.append([
      child_2_child_1,
      child_2_child_2.append([
        child_2_child_2_child_1,
        child_2_child_2_child_2
      ])
      ])
    ]);
  parent.appendTo($(".div-rp-pmcy"));
}
function addELementReportCSKCB(data)
{
  for(var i = 0 ; i< data.length ; i++)
  {
    var lb = $('<lable class="dropdown-nvbd-option c-p"><div class="w-6 f-l"><input class="c-p ip-cb-cskcb ip-cb-cskcb-'+data[i].HospitalId+'" type="checkbox" name="dropdown-nvbd-group" value="'+data[i].HospitalId+'"/></div><div class="w-94 f-l div-sp-cskcb text-ellipsis-n-w" name="'+data[i].HospitalId+'" title="'+data[i].HospitalName+'"><span class="c-p">'+data[i].HospitalName+'</span></div></lable>')
    $(".div-lst-cskcb").append(lb);
  }
}
//Phần giao diện biểu đồ thể hiện thống kê bệnh theo giới tính
function addElementChartDG()
{
  var data = handlingDataChartDG();
  createChart("chartDG",data);
}
//Phần giao diện biểu đồ thống kê số lần khám theo tuổi
function addElementChartPtAge()
{
  var obj = handlingDataChartPtAge();
  creatChartDougtNut("chartPtAge",obj);
  addElementChartPtAgeContent(obj);
}
function addElementChartPtAgeContent(obj)
{
  try
  {
    for(var i=0;i<obj.value2.length;i++)
    {
      var result = (Number(obj.value2[i]).toLocaleString('en')).replaceAll(",",".");
      if(result == "NaN") result = 0;
      var child_1 = $('<div class="w-100 t-c"><p>'+result+'</p><p>'+obj.categories[i]+'</p></div>');
      $(".div-chartPtAge-content").append(child_1);
    }
  }
  catch(ex){}
}
//Phần giao diện biểu đồ thống kê số lần khám theo hình thức kcb
function addElementChartPtKCB()
{
  var obj = handlingDataChartPtKCB();
  creatChartDougtNut("chartPtKCB",obj);
  addElementChartPtKCBContent(obj);
}
function addElementChartPtKCBContent(obj)
{
  try
  {
    for(var i=0;i<obj.value2.length;i++)
    {
      var result = (Number(obj.value2[i]).toLocaleString('en')).replaceAll(",",".");
      if(result == "NaN") result = 0;
      var child_1 = $('<div class="w-100 t-c"><p>'+result+'</p><p>'+obj.categories[i]+'</p></div>');
      $(".div-chartPtKCB-content").append(child_1);
    }
  }
  catch(ex){}
}
function performReportNumberVisitByDiseaseDefault()
{
  var h_title       = $(".div-rp-pmcy-title").height();
  var height_header = $('.div-header').height();
  $(".div-rp-pmcy").height(_height_window-height_header);
  $(".div-content-function").height(_height_window-height_header);
  $(".div-rp-pmcy-content").height(_height_window-height_header-h_title-5);
  if( $(".div-content-function").hasClass("div-content-toggle"))
  {
      $(".div-rp-pmcy-content").css('width', '100%').css('width', '-=100px');
      $(".div-lst-menu-child").css("padding","15px 3px");
  }
  else
  {
      $(".div-rp-pmcy-content").css('width', '100%').css('width', '-=225px');
      $(".div-lst-menu-child").css("padding","15px 3px 15px 20px");
  }
  localStorage.setItem("trActive", "");
}
function performReportNumberVisitByDiseaseContent()
{
  $(".div-lb-hover-tt").unbind().on("click",function(){
    alert($(this).text());
  })
  $(".div-lb-hover-tt").each(function(index){
    $(this).prop("title",$(this).text());
  })
  $(".ip-box-search").unbind().on("keyup",function(e){
    if(e.keyCode == 13) 
    {
      _filterMA.Find = $(this).val();
      _filterMA.PageNumber = 1;
      $(".div-lst-cskcb").empty();
      addELementReportCSKCB(getManagementAgency(_filterMA));
      performSearchCSKCB();
      performScrollCSKCB();
      try
      {
        var id = _dt_managementAgency.filter(x=>x.HospitalName == $(".dropdown-nvbd-label-cskcb").text())[0].HospitalId;
        SetChecked(".ip-cb-cskcb-",id,true);
      }
      catch(ex){}
    }
  })
  performScrollCSKCB();
  //$("#chartDG , .highcharts-container").height($(".div-rp-pmcy-content").height()-60);
}
function performSearchCSKCB()
{
  $(".div-sp-cskcb").unbind().on("click",function(){
    var name ;
    var id = $(this).attr("name");
    if($(".ip-cb-cskcb-"+id).is(":checked"))
    {
      id= null;
      name ="Tất cả"
      SetChecked(".ip-cb-cskcb-",$(this).attr("name"),false)
    }
    else
    {
      SetChecked(".ip-cb-cskcb","",false);
      name = _dt_managementAgency.filter(x=>x.HospitalId==id)[0].HospitalName;
      $(".ip-cb-cskcb-"+id).prop("checked",true);
    }
    $(".dropdown-nvbd-label-cskcb").text(name);
    SetChecked(".ip-cb-cskcb-",id,true);
    handlingDataCSKCB(id);
  })
  $(".ip-cb-cskcb").unbind().on("change",function(){
    var name ;
    if($(this).is(":checked"))
    {
      var id = $(this).val();
      name = _dt_managementAgency.filter(x=>x.HospitalId==id)[0].HospitalName;
      SetChecked(".ip-cb-cskcb","",false);
      $(this).prop("checked",true);
      handlingDataCSKCB(id);
    }
    else
    {
      name = "Tất cả";
      $(this).prop("checked",false);
      handlingDataCSKCB(null);
    }
    $(".dropdown-nvbd-label-cskcb").text(name);
  })
  try
  {
    var id = _dt_managementAgency.filter(x=>x.HospitalName == $(".dropdown-nvbd-label-cskcb").text())[0].HospitalId;
    SetChecked(".ip-cb-cskcb-",id,true);
  }
  catch(ex){}
}
function performScrollCSKCB()
{
  $(".div-lst-cskcb").scroll(function () {
    var scrollHeight = $(".div-lst-cskcb").prop("scrollHeight");
    if ($(".div-lst-cskcb").scrollTop() ==  Math.round(scrollHeight-($(".div-lst-cskcb").height()))) {
      fetchMoreDataMA();
      var data = getManagementAgency(_filterMA);
      _dt_managementAgency = _dt_managementAgency.concat(data);
      addELementReportCSKCB(data);
      performSearchCSKCB();
    }
  });
}
function performReportNumberVisitByDiseaseSelectDate()
{
  var CheckboxDropdown = function(el) 
  {
    var _this = this;
    this.isOpen = false;
    this.areAllChecked = false;
    this.$el = $(el);
    this.$label = this.$el.find('.dropdown-nvbd-label');
    this.$label.on('click', function(e) {
      e.preventDefault();
      _this.toggleOpen();
    });
   
  };
  CheckboxDropdown.prototype.toggleOpen = function(forceOpen) {
    var _this = this;
    if(!this.isOpen || forceOpen) {
       this.isOpen = true;
       this.$el.addClass('on');
      $(document).on('click', function(e) {
        if(!$(e.target).closest('[data-control]').length) {
         _this.toggleOpen();
        }
      });
    }
    else {
      this.isOpen = false;
      this.$el.removeClass('on');
      $(document).off('click');
    }
  };
  var checkboxesDropdowns = document.querySelectorAll('[data-control="checkbox-dropdown"]');
  for(var i = 0, length = checkboxesDropdowns.length; i < length; i++) {
    new CheckboxDropdown(checkboxesDropdowns[i]);
  }
  var year = getYear();
  SetChecked(".ip-cb-ntk-",year[year.length-1],true);
  $(".div-sp-ntk").unbind().on("click",function(){
    var id = $(this).attr("name");
    if($(".ip-cb-ntk-"+id).is(":checked"))
    {
      $(".ip-cb-ntk-"+id).prop("checked",false);
      id = year[year.length-1];
      SetChecked(".ip-cb-ntk-",id,true);
    }
    else
    {
      SetChecked(".ip-cb-ntk","",false);
      $(".ip-cb-ntk-"+id).prop("checked",true);
    }
    $(".dropdown-nvbd-label-year").text(id);
    handlingDataByYear(id);
  })
  $(".ip-cb-ntk").unbind().on("change",function()
  {
    var id ;
    if($(this).is(":checked"))
    {
      id = $(this).val();
      SetChecked(".ip-cb-ntk","",false);
      $(this).prop("checked",true);
    }
    else
    {
      id = year[year.length-1];
      SetChecked(".ip-cb-ntk-",id,true);
    }
    $(".dropdown-nvbd-label-year").text(id);
    handlingDataByYear(id);
  })
  $(".dropdown-nvbd-list-ntk").animate({ scrollTop: $(".dropdown-nvbd-list-ntk").height() }, 1000);
  performJqueryTTK();
  performSearchCSKCB();
  /*var a = parseInt($(".div-content-function").width());
  var c = 43*(a/100) + 250;
  $(".dropdown-nvbd-list-ntk").css({
    "top":($(".div-header").height()+$(".div-rp-pmcy-title").height()-18),
    "width":$(".dropdown-nvbd").width(),
    "left":c+"px" //40.8%
  })
  $(".dropdown-nvbd-list-ttk").css({
    "top":($(".div-header").height()+$(".div-rp-pmcy-title").height()-18),
    "width":$(".dropdown-nvbd").width(),
    "left":"70.2%"
  })*/
}
function performJqueryTTK()
{
  $(".div-sp-ttk").unbind().on("click",function(){
    var id = $(this).attr("name");
    if($(".ip-cb-ttk-"+id).is(":checked"))
    {
      $(".ip-cb-ttk-"+id).prop("checked",false);
      $(".dropdown-nvbd-label-month").text("Tất cả");
      handlingDataByMonth("full");
    }
    else
    {
      SetChecked(".ip-cb-ttk","",false);
      var name = lstMonth.filter(x=>x.value==id)[0].name;
      $(".ip-cb-ttk-"+id).prop("checked",true);
      $(".dropdown-nvbd-label-month").text(name);
      handlingDataByMonth(id);
    }
  })
  $(".ip-cb-ttk").unbind().on("change",function(){
    if($(this).is(":checked"))
    {
      var id = $(this).val();
      SetChecked(".ip-cb-ttk","",false);
      $(this).prop("checked",true);
      var name = lstMonth.filter(x=>x.value==id)[0].name;
      $(".dropdown-nvbd-label-month").text(name);
      handlingDataByMonth(id);
    }
    else
    {
      $(".dropdown-nvbd-label-month").text("Tất cả");
      $(this).prop("checked",false);
      handlingDataByMonth("full");
    }
  })
}
function getDataChart(action)
{
  var data ={};
  switch(action)
  {
      //Diagnostic Count Hospital Lấy danh sách thống kê số lượng bệnh theo giới tính
      case 1 :
      {
        data =  SendData("api/Report/DiagnosticCountHospital/Summary","get",_filterDCH);
        break;
      }
     //Lấy danh sách thống kê số lượng bệnh theo độ tuổi
     case 2 :
      {
        data =  SendData("api/Report/PatienAgeGroupCountHospital/Summary","get",_filterDCH);
        break;
      }
       //Lấy danh sách thống kê số lượng bệnh theo hình thức kcb
     case 3 :
      {
        data =  SendData("api/Report/PatienTypeServiceCountHospital/Summary","get",_filterDCH);
        break;
      }
  }
  return data;
}
function handlingDataCSKCB(cskcb)
{
  _filterDCH.HospitalId = cskcb;
  resetElement();
}
function handlingDataByMonth(month)
{
  if(month=="full")
  {
    _filterDCH.FromMonth = 1;
    _filterDCH.ToMonth   = 12
  }
  else
  {
    _filterDCH.FromMonth = month;
    _filterDCH.ToMonth   = month;
  }
  resetElement();
}
function handlingDataByYear(year)
{
  var currentMonth = cd.getMonth()+1;
  _filterDCH.FromYear = year;
  _filterDCH.ToYear   = year;
  _filterDCH.FromMonth = 1;
  _filterDCH.ToMonth   = 12;
  if(year < cd.getFullYear())
  {
    $(".div-check-month-cb").removeClass("div-dis").addClass("div-sp-ttk");
    $(".div-check-month-ip").prop( "disabled", false );
    performJqueryTTK();
  }
  else
  {
    SetChecked(".ip-cb-ttk","",false);
    for(var i = 1 ; i<= 12;i++)
    {
      if(i > currentMonth)
      {
        $(".div-check-month-cb-"+i).addClass("div-dis").removeClass("div-sp-ttk").unbind("click");
        $(".div-check-month-ip-"+i).prop("checked", false).prop("disabled", true);
      }
    }
  }
  $(".dropdown-nvbd-label-month").text("Tất cả");
  resetElement();
}
function resetElement()
{
  $("#chartDG , #chartPtAge , #chartPtKCB , .div-chartPtAge-content , .div-chartPtKCB-content").empty();
   //Biểu đồ thống kê bệnh theo giới tính
   addElementChartDG();
   //Biểu đồ thống kê số lần khám theo tuổi
   addElementChartPtAge();
    //Biểu đồ thống kê số lần khám theo hình thức kcb
   addElementChartPtKCB();
   performReportNumberVisitByDiseaseContent();
}
function handlingDataChartDG()
{
  var data = getDataChart(1);
  if(data == undefined ||  data.length == 0) return null;
  var dt = {};
  dt.name = [];
  dt.value = 
  [
    {
      "name" : "Nữ",
      data   : []
    },
    {
      "name" : "Nam",
      data   : []
    }
  ];
  //var index = Math.ceil(_height_window / 40);
  var index = 26;
  try
  {
    for(var i=0;i<=index;i++)
    {
      var name = data[i].Diagnostic.ID + "-" +data[i].Diagnostic.Name;
      dt.name.push(name);
      dt.value[0].data.push(data[i].CountFeMale);
      dt.value[1].data.push(data[i].CountMale);
    }
    return dt
  }
  catch(ex)
  {
    return dt
  }
}
function handlingDataChartPtAge()
{
  try
  {
    var dt = {};
    dt.name = "Độ tuổi";
    dt.title = "Thống kê lần khám bệnh theo độ tuổi";
    dt.categories = 
    [
      "Dưới 14",
      "15 - 24",
      "25 - 64",
      "Trên 65"
    ]
    var data = getDataChart(2);
    dt.value = [
      Math.round(((data.CountChildren/data.Total)*100)*100)/100,
      Math.round(((data.CountYouth/data.Total)*100)*100)/100,
      Math.round(((data.CountAdults/data.Total)*100)*100)/100,
      Math.round(((data.CountSeniors/data.Total)*100)*100)/100,
    ];
    dt.value2 = 
    [
      data.CountChildren,
      data.CountYouth,
      data.CountAdults,
      data.CountSeniors
    ]
    return dt
  }
  catch(ex){}
  
}
function handlingDataChartPtKCB()
{
  try
  {
    var dt = {};
    dt.name = "Độ tuổi";
    dt.title = "Thống kê lần khám bệnh theo hình thức KCB";
    dt.categories = 
    [
      "Nội trú",
      "Ngoại trú",
      "Khám bệnh",
    ]
    var data = getDataChart(3);
    dt.value = [
      Math.round(((data.CountInPatient/data.Total)*100)*100)/100,
      Math.round(((data.CountOutPatient/data.Total)*100)*100)/100,
      Math.round(((data.CountConsultation/data.Total)*100)*100)/100,
    ];
    dt.value2 = 
    [
      data.CountInPatient,
      data.CountOutPatient,
      data.CountConsultation,
    ]
    return dt
  }
  catch(ex){}
  
}
function fetchMoreDataMA()
{
  _filterMA.PageNumber += 1;
}
export {addElementReportNumberVisitByDisease , performReportNumberVisitByDisease , getYear , setFilterDCH , performReportNumberVisitByDiseaseDefault}