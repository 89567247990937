import React from 'react';
import { addElementResetPassword, performJQResetPassword } from './ui_fc';
export default class ResetPassword extends React.Component {
    componentWillMount()
    {
    }
    componentDidMount()
    {
        addElementResetPassword();
        performJQResetPassword();
    }
    render() {
        return (
            <div className="div-content-login" >
            </div>
        )
    }
}