import $ from 'jquery' ;
import ReactDOM from 'react-dom';
import { _height_window } from '../../../Variable/defaultvar';
function addElementHospitalPerformance()
{
    var parent = $('<div class="div-rp-pmcy" id="div-rp-pmcy"/>');
    //var child_1 = $('<iframe id="loginiframe" src="https://bidemo.surehis.com/knowage/servlet/AdapterHTTP?PAGE=LoginPage&NEW_SESSION=TRUE&userID=biuser&password=Biu$er123" frameborder="0" width="100%" height="100%"></iframe>');
    var child_2 = $('<iframe id="execiframe" src="" frameborder="0" width="100%" height="'+(_height_window-65)+'px"></iframe>');
    parent.append([child_2])
    parent.appendTo($(".div-content-function"));
}
function performHospitalPerformance(url)
{
    $("#loginiframe").on("load",function(){frameload()});
    $(".div-rp-pmcy").css("padding",0).height(_height_window - 65);
    frameload(url);
}
function frameload(url){
    //document.getElementById('loginiframe').remove(); // iframe loginiframe dùng để đăng nhập, sau khi đăng nhập sẽ xóa đi
    
    //url của bi được cung cấp
    
    //param của bi nếu cần thiết, cấu trúc url param: &CAN_RESET_PARAMETERS=false&PARAMETERS=param1%3Dvalue1%26param2%3Dvalue2...
    //var focus = 1 // chọn trang hiển thị
    //yurl += '&CAN_RESET_PARAMETERS=false&PARAMETERS=focusPage%3D'+focus;
    
    document.getElementById('execiframe').src = url;
    try
    {
        document.getElementById('if_login').contentWindow.location.reload();
    }
    catch(ex){}
}
export {addElementHospitalPerformance,performHospitalPerformance}