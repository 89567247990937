import React, { Component } from 'react';
import './ui.css';
import footerlogo from '../../../Images/footer-logo.png';
export default class Footer extends Component {
    componentWillMount()
    {
    }
    componentDidMount() {
       
    }
    render() {
        return (
            <div className="row div-footer c-w">
                <div className="col-lg-6 f-l">
                    <img src={footerlogo}/>
                    <p>Công ty cổ phần tin học Lạc Việt</p>
                    <p>23 Nguyễn Thị Huỳnh, Phường 8, Quận Phú Nhuận, TP. Hồ Chí Minh</p>
                    <p>028 3844 2477 - 028 3842 2370 lacviet@lacviet.com.vn</p>
                    <p>www.lacviet.vn</p>
                </div>
                <div className="col-lg-6 f-l">
                    <p>CHI NHÁNH HÀ NỘI</p>
                    <p>Tầng 8 - Tòa nhà Sky City, 88 Láng Hạ, P. Láng Hạ, Q. Đống Đa, TP. Hà Nội</p>
                    <p>hanoi@lacviet.com.vn</p>
                    <p>CHI NHÁNH ĐÀ NẴNG</p>
                    <p>36 Hàm Nghi, F. Vĩnh Trung, Q. Thanh Khê, TP. Đà Nẵng</p>
                    <p>danang@lacviet.com.vn</p>
                </div>
            </div>
        );
    }
}