import $ from 'jquery'
import { SendData } from '../../../Function/ui';
import { _height_window } from '../../../Variable/defaultvar';
import { notification } from '../../Notification/ui_fc';
import { returnLogin } from '../ConfirmEmail/ui_fc';
import { variable } from '../ui/ui_var';
import { md5 } from '../../../Function/ui'
//Giao diện trang login
function addElementResetPassword()
{
    var parent = $('<div class="w-100 h-100"/>');
    var child_1 = $('<div class="div-login w-100 h-100" />');
    var child_2 = $('<div class="div-notification w-100 h-100">');
    var child_1_child_1 = $('<div class="div-logo-login t-c" />');
    var child_1_child_2 = $('<div class="w-100 div-title-login t-c" />');
    var child_1_child_3 = $('<div class="div-login-form" />');
    var child_1_child_5 = $('<div class="w-100" />');
    var child_2_child_1 = $('<div/>');
    var child_1_child_1_child_1 = $('<div class="inline"/>');
    var child_1_child_1_child_2 = $('<div class="inline c-w"><p>FHIR</p></div>');
    var child_1_child_2_child_1 = $('<p>Thay đổi mật khẩu</p>');
    var child_1_child_2_child_2 = $('<p>Thay đổi mật khẩu mới để đăng nhập tài khoản</p>');
    var child_1_child_3_child_1 = $('<div class="holder"><div class="input-holder"><input class="input ip-email" type="text" placeholder=" " value="bachung7596@gmail.com" disabled/><div class="placeholder">Email</div></div></div>');
    var child_1_child_3_child_2 = $('<div class="div-err-login ip-email-err"><p /></div>');
    var child_1_child_3_child_3 = $('<div class="holder"><div class="input-holder"><input style="padding:13px 53px 13px 15px" class="input ip-pw" type="password" placeholder=" " /><div class="placeholder">Mật khẩu</div><div class="c-p div-sh-pw"><i class="fal fa-eye"></i></div></div></div>');
    var child_1_child_3_child_4 = $('<div class="div-err-login ip-pw-err"><p /></div>');
    var child_1_child_3_child_5 = $('<div class="holder"><div class="input-holder"><input style="padding:13px 53px 13px 15px" class="input ip-pw-rp" type="password" placeholder=" " /><div class="placeholder">Nhập lại mật khẩu</div><div class="c-p div-sh-pw-rp"><i class="fal fa-eye"></i></div></div></div>');
    var child_1_child_3_child_6 = $('<div class="div-err-login ip-pw-rp-err"><p /></div>');
    var child_1_child_5_child_1 = $('<div class="t-c c-w div-btn-login c-p"><p style="margin:13px 0 0 0 ; color:white">Cập nhật</p></div>');
    var child_2_child_1_child_1 = $('<div class="w-100 color-w"><div class="h-100"><i class="i-notification"></i><span class="sp-title-notification c-w"/></div></div>');
    var child_2_child_1_child_2 = $('<div class="w-100 div-notification-content t-c"/>');
    var child_2_child_1_child_3 = $('<div class="w-100 h-18 div-footer-notification"/>');
    parent.append([
        child_1.append([
            child_1_child_1.append([
                child_1_child_1_child_1,
                child_1_child_1_child_2
            ]),
            child_1_child_2.append([
                child_1_child_2_child_1,
                child_1_child_2_child_2
            ]),
            child_1_child_3.append([
                child_1_child_3_child_1,
                child_1_child_3_child_2,
                child_1_child_3_child_3,
                child_1_child_3_child_4,
                child_1_child_3_child_5,
                child_1_child_3_child_6
            ]),
            child_1_child_5.append([
                child_1_child_5_child_1,
            ])
        ]),
        child_2.append([
            child_2_child_1.append([
                child_2_child_1_child_1,
                child_2_child_1_child_2,
                child_2_child_1_child_3
            ])
        ])
    ]);
    parent.appendTo($(".div-content-login"));
}
function performJQResetPassword() {
    $(".div-sh-pw").unbind().on("click",function()
    {
        if($(".div-sh-pw").children().hasClass("fa-eye"))
        {
            $(".div-sh-pw").children().removeClass("fa-eye").addClass("fa-eye-slash");
            $(".ip-pw").attr("type", 'text');
        }
        else
        {
            $(".div-sh-pw").children().removeClass("fa-eye-slash").addClass("fa-eye");
            $(".ip-pw").attr("type", 'password');
        }
    })
    $(".div-sh-pw-rp").unbind().on("click",function()
    {
        if($(".div-sh-pw-rp").children().hasClass("fa-eye"))
        {
            $(".div-sh-pw-rp").children().removeClass("fa-eye").addClass("fa-eye-slash");
            $(".ip-pw-rp").attr("type", 'text');
        }
        else
        {
            $(".div-sh-pw-rp").children().removeClass("fa-eye-slash").addClass("fa-eye");
            $(".ip-pw-rp").attr("type", 'password');
        }
    })
    $(".div-content-login").css({height:(_height_window),"overflow":"auto"}); 
    $(".div-btn-login").unbind().on("click",resetPassword);
}
function resetPassword()
{
    if(!checkResetPW()) return
    var url = new URL(window.location.href);
    var obj = {};
    obj.Email    = url.searchParams.get("email");
    obj.Code     = url.searchParams.get("code");
    obj.Password = md5(md5($(".ip-pw-rp").val()));
    if(SendData("api/Account/ResetPassword","get",obj))
        notification.notifactionF2("Thành công. Thực hiện lại đăng nhập.",returnLogin);
    else
        notification.notifaction("Có lỗi xảy ra.")
}
function checkResetPW()
{
    var dataField = ["pw","pw-rp"] , action = true;
    for(var i=0;i<dataField.length;i++)
    {
        if(!checkErrorResetPW(dataField[i],$(".ip-"+dataField[i]).val(),dataField[i]))
          action = false;
    }
    return action;
}
function checkErrorResetPW(id,value,action)
{
    var ac     = true;
    var err    = "";
    var clsAdd = "input";
    var clsRm  = "error";
    if(action == "pw")
    {
        if(value == "")
        {
            ac     = false;
            err    = variable.errMessenger[1];
            clsRm  = "input";
            clsAdd = "error";
            ac = false;
        }
        else if(value.length<6)
        {
            ac     = false;
            err    = variable.errMessenger[8];
            clsRm  = "input";
            clsAdd = "error"; 
        }
    }
    else 
    {
        if(value == "")
        {
            ac     = false;
            err    = variable.errMessenger[1];
            clsRm  = "input";
            clsAdd = "error";
            ac = false;
        }
        else if(value.length<6)
        {
            ac     = false;
            err    = variable.errMessenger[8];
            clsRm  = "input";
            clsAdd = "error"; 
        }
        else if(value != $(".ip-pw").val())
        {
            ac     = false;
            err    = variable.errMessenger[9];
            clsRm  = "input";
            clsAdd = "error";
            ac = false;
        }
       
    }
    $(".ip-"+id).addClass(clsAdd).removeClass(clsRm);
    $(".ip-"+id+"-err p").text(err);
    return ac;
}
export { addElementResetPassword, performJQResetPassword }